import React, { Component } from 'react';
import { Row, Col, Card, Button, message, Statistic, PageHeader, Typography, List, Space, Tabs, Empty, Tooltip } from 'antd'
import { IconArrowBack } from '../../Widgets/Iconos';
import { PlusOutlined, CloseOutlined, ClockCircleOutlined, DollarCircleFilled, PaperClipOutlined, EyeOutlined, CopyOutlined } from "@ant-design/icons";
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import Logged from '../../../Hooks/Logged';
import ModalTransaccion from '../Finanzas/Transacciones/ModalTransaccion';
import TransaccionesTab from './Secciones/TransaccionesTab'
import TransaccionesProgramadasTab from './Secciones/TransaccionesProgramadasTab'
import { Content } from 'antd/lib/layout/layout';
import { useParams } from 'react-router-dom';

import usePermissions from '../../../Hooks/usePermissions';
import { ChildButton, FloatingMenu, MainButton } from 'react-floating-button-menu';
import ModalTransaccionProgramada from '../Finanzas/Transacciones/ModalTransaccionProgramada';
import ModalDocumentos from './Modales/ModalDocumentos';

const { Text, Paragraph, Title } = Typography
const { TabPane } = Tabs

const moment = require('moment');
const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


class DetalleAcreedor extends Component {
    volver = () => {
        window.history.back();
    }

    constructor(props) {
        super(props)
        this.state = {
            dataTran: [],
            dataDetalles: [],
            loading: true,

            detalle: true,
            accionModal: 'crear',
            registroId: '',
            transaccion_id: '',

            acreedor: {},

            transacciones: {

                data: [],

                limit: 20,
                page: 1,

                total: 0,
                pages: 0,

                filters: [],
                sort: {},

            },

            modalVisibleTran: false,

            
            acreedor_id: this.props.params.id,
            tab: 'transacciones'
        }
    }

    ModalTranRef = React.createRef();
    transaccionesRef = React.createRef()
    programadosRef = React.createRef()

    componentDidMount() {


        

        //Obtenemos detalles
            this.get();
    }


    /**
     * @method get
     * @description Obtiene la informacion y actualiza los valores locales de la vista.
     */
    get = values => {

        var id = this.state.acreedor_id;

        axios.get(`/acreedores/${id}`, {
            params: {id},
        }).then((acreedor) => {
            let data = acreedor.data;
            var fecha_prestamo = (data.fecha_prestamo !== undefined) ? moment(data.fecha_prestamo).format('YYYY-MM-DD') : moment(data.createdAt).format('YYYY-MM-DD');
            console.log("Recieved Data", data);

            //Actualiza los valores de los states.
            this.setState({
                loading: false,
                acreedor: data,
                
            });
            this.setState({
                datosConsultados: true,
            })
        })
        let idCliente = '';

    }

    /**
     *
     *
     * @memberof Detalle 
     * @method enviarMailRecibo
     * @description Envia por mail el recibo de la transaccion seleccionada
     */
    enviarMailRecibo = (transaccion_id) => {
        const key = 'mensaje';
        message.loading({ content: 'Enviando...', key });
        axios.get('/acreedores/recibo/enviar', {
            params: {
                tran_id: transaccion_id,
                acreedor_id: this.props.match.params.id,
                enviar: true,
            }
        })
            .then(result => {
                message.success({ content: 'Mail enviado correctamente!', key })
                this.get()
            })
            .catch(error => {
                message.success({ content: 'Ocurrio un problema al enviar el mail.', key })
            })
    }

    renderUrl = (item, key) => {
        const urlParams = new URL(axios.defaults.baseURL + `/${key}/logo/` + item._id)
        urlParams.searchParams.set("Authorization", sessionStorage.getItem("token"))
        urlParams.searchParams.set("name", item.logo?.name)
        return urlParams.href
    }

    updateAll = () => {
        this.get()
        if(this.state.tab === 'transacciones'){
            this.transaccionesRef.current?.get()
        }else{
            this.programadosRef.current?.get()
        }
    }



    render() {
        let usuario = this.context;


        return (
            <div>
                <PageHeader
                    onBack={this.volver}
                    title="Deudas por pagar"
                    backIcon={<IconArrowBack />}
                />


                <Content className="admin-content content-bg pd-1">
                    <Row>
                        
                        <Col span={24}>
                            <Card bordered loading={this.state.loading}>
                                <Row gutter={[16, 24]} align={"middle"}>
                                <Col xs={24} lg={12} xl={16}>
                                    <Title level={4} >Información del Acreedor</Title>
                                </Col>
                                <Col xs={24} lg={12} xl={8}>
                                    <Space>
                                        <Text>Fecha de Prestamo:</Text>
                                        <Text strong style={{ fontSize: 16 }}>{moment(this.state.acreedor?.fecha_prestamo).format("YYYY-MM-DD")}</Text>
                                    </Space>
                                </Col>
                                <Col xs={24}>
                                    <Row gutter={[16, 24]}>
                                        <Col xs={24} lg={12} xl={8}>
                                            <Paragraph>Acreedor</Paragraph>
                                            <Paragraph strong style={{ fontSize: 16 }}>{this.state.acreedor?.acreedor}</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={8}>
                                            <Paragraph>Email</Paragraph>
                                            <Paragraph strong style={{ fontSize: 16 }}>{this.state.acreedor?.email}</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={8}>
                                            <Paragraph>Teléfono</Paragraph>
                                            <Paragraph strong style={{ fontSize: 16 }}>{this.state.acreedor?.telefono}</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={8}>
                                            <Paragraph>Proyecto</Paragraph>
                                            <Space size={5}>
                                                <CustomAvatar
                                                    color={this.state.acreedor?.proyecto_id?.color}
                                                    {...(this.state.acreedor?.proyecto_id?.logo ? {
                                                        image: this.renderUrl(this.state.acreedor?.proyecto_id, "proyectos")
                                                    } : {
                                                        name: this.state.acreedor?.proyecto_id?.nombre
                                                    })}
                                                />
                                                <Text style={{ fontSize: 16 }}>{this.state.acreedor?.proyecto_id?.nombre}</Text>
                                            </Space>
                                        </Col>
                                        <Col xs={24} lg={12} xl={8}>
                                            <Paragraph>Contact</Paragraph>
                                            <Space size={5}>
                                                <CustomAvatar
                                                    color={this.state.acreedor?.contacto_id?.color}
                                                    {...(this.state.acreedor?.contacto_id?.logo ? {
                                                        image: this.renderUrl(this.state.acreedor?.contacto_id, "contactos")
                                                    } : {
                                                        name: this.state.acreedor?.contacto_id?.nombre
                                                    })}
                                                />
                                                <Text style={{ fontSize: 16 }}>{this.state.acreedor?.contacto_id?.nombre}</Text>
                                            </Space>
                                        </Col>
                                        <Col xs={24} lg={12} xl={8}>
                                            <div>
                                                <Space align='start'>
                                                    <Paragraph>Referencia Bancaria</Paragraph>
                                                    <Button
                                                        size="small"
                                                        type="link"
                                                        style={{ padding: 0, fontSize: 16, height: 'auto' }}
                                                        icon={<CopyOutlined />}
                                                        disabled={!this.state.acreedor?.referencia}
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(this.state.acreedor?.referencia)
                                                            message.success('¡Referencia copiada!')
                                                        }}

                                                    />
                                                </Space>
                                            </div>
                                            <Paragraph strong style={{ fontSize: 16 }}>{this.state.acreedor?.referencia}</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={8}>
                                            <div>
                                                <Space align='start'>
                                                    <Paragraph>Documentos</Paragraph>
                                                    <Button
                                                        size="small"
                                                        type="link"
                                                        style={{ padding: 0, fontSize: 16, height: 'auto' }}
                                                        icon={<EyeOutlined />}
                                                        onClick={() => this.setState({ modalVisible: true})}

                                                    />
                                                </Space>
                                            </div>

                                            <List
                                                style={{ 
                                                    maxHeight: 300,
                                                    overflowY: 'auto'
                                                }}
                                                size="small"
                                                bordered={false}
                                                dataSource={this.state.acreedor?.documentos || []}
                                                renderItem={(d) => <Tooltip placement='left' title={d.name}>
                                                    <List.Item style={{ padding: '4px 8px' }}>
                                                        <Button size="small" icon={<PaperClipOutlined />} onClick={() => window.open(axios.defaults.baseURL + "/acreedores/" + this.props.params.id + "?documento=" + d.file + "&Authorization=" + sessionStorage.getItem("token"))} type="link">{d.name?.length > 35 ? `${d.name.slice(0, 35)}...` + d.name.split(".")[d.name.split(".")?.length - 1] : d.name}</Button>
                                                    </List.Item>
                                                </Tooltip>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card bordered className='mt-1'>
                                <Row gutter={[16, 28]}>
                                    <Col xs={24} lg={12} xl={8}>
                                        <Paragraph>Monto a Retornar</Paragraph>
                                        <Statistic
                                            valueStyle={{ fontSize: 16 }}
                                            value={this.state.acreedor?.monto_retorno}
                                            precision={2}
                                            prefix={'$'} suffix={"MXN"} />
                                    </Col>
                                    <Col xs={24} lg={12} xl={8}>
                                        <Paragraph>Monto Actual Pagado</Paragraph>
                                        <Statistic
                                            valueStyle={{ fontSize: 16, color: "green" }}
                                            value={this.state.acreedor?.monto_pagado}
                                            precision={2}
                                            prefix={'$'} suffix={"MXN"} />
                                    </Col>
                                    <Col xs={24} lg={12} xl={8}>
                                        <Paragraph>Monto por Pagar</Paragraph>
                                        <Statistic
                                            valueStyle={{ fontSize: 16, color: "red" }}
                                            value={this.state.acreedor?.monto_pendiente}
                                            precision={2}
                                            prefix={'$'} suffix={"MXN"} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>



                    <Row className="mt-1">
                        <Col span={24}>
                            {this.props.verTransacciones || this.props.verTransaccionesProgramadas ? <Tabs className="tabs-proyectos tabs-crm-client"
                                onChange={tab => this.setState({ tab })}
                                destroyInactiveTabPane
                                activeKey={this.state.tab}
                            >
                                {this.props.verTransacciones ? <TabPane tab="Transacciones" key="transacciones">
                                    <TransaccionesTab
                                        key="transacciones"
                                        ref={this.transaccionesRef}
                                        acreedor_id={this.state.acreedor_id}
                                        updateAll={() => {
                                            this.updateAll()
                                        }}
                                    />
                                </TabPane> : null}
                                {this.props.verTransaccionesProgramadas ? <TabPane tab="Transacciones Programadas" key="transacciones-programadas">
                                    <TransaccionesProgramadasTab
                                        key="transacciones-programadas"
                                        ref={this.programadosRef}
                                        acreedor_id={this.state.acreedor_id}
                                        updateAll={() => {
                                            this.updateAll()
                                        }}
                                    />
                                </TabPane> : null
                                }
                            </Tabs> : <Empty className='mt-1' description="No tiene permisos para ver transacciones o transacciones programadas" />}
                        </Col>
                    </Row>


                </Content>

                <FloatingMenu
                    slideSpeed={500}
                    direction="left"
                    spacing={8}
                    isOpen={this.state.isOpen}
                    style={{

                        position: "fixed",
                        bottom: "1em",
                        right: "1.2em"

                    }}
                >
                    <MainButton
                        iconResting={<PlusOutlined style={{ fontSize: 30, color: "white" }} />}
                        iconActive={<CloseOutlined style={{ fontSize: 30, color: "white" }} />}
                        style={{ backgroundColor: "#0047FF" }}
                        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                        size={70}
                        direction="right"
                    />
                    <ChildButton
                        icon={
                            <Tooltip mouseLeaveDelay={0} title="Agregar una Transacción Programada" placement="top">
                                <div style={{ width: "100%", height: "100%", textAlign: "center", position: "relative", top: 5 }}>
                                    <ClockCircleOutlined style={{ fontSize: 40, color: "white" }} nativeColor="black" />
                                </div>
                            </Tooltip>
                        }
                        background={this.props.crearTransaccionesProgramadas ? "#0047FF" : "#0047FF80"}
                        size={50}
                        onClick={this.props.crearTransaccionesProgramadas ? () => this.setState({ modalTransaccionProgramadaVisible: true, isOpen: false }) : undefined}
                    />
                    <ChildButton
                        icon={
                            <Tooltip mouseLeaveDelay={0} title="Agregar una Transacción" placement="top">
                                <DollarCircleFilled style={{ fontSize: 50, color: this.props.crearTransacciones || true ? "#0047FF" : "#0047FF80" }} nativeColor="black" />
                            </Tooltip>
                        }
                        
                        background="white"
                        size={50}
                        onClick={this.props.crearTransacciones || true ? () => this.setState({ modalTransaccionVisible: true, tipo_pago: undefined, isOpen: false }) : undefined}
                    />
                </FloatingMenu>

               
                <ModalTransaccion
                    visible={this.state.modalTransaccionVisible}
                    onClose={() => {
                        this.setState({modalTransaccionVisible: false})
                        this.updateAll()
                    }}
                    acreedor_id={this.state.acreedor_id}
                    tipo_pago={this.state.tipo_pago}
                    cuenta={((this.state.tipo_pago === 2) ? false : true)}
                    initialValues={{
                        tipo: 1,
                        acreedor_id: this.state.acreedor_id
                    }}

                    disabled={{
                        inversion: true,
                        cliente: true,
                        propiedad: true,
                        acreedor: true,
                        proveedor: true
                    }}
                    required={{
                        acreedor: true
                    }}
                />
                <ModalTransaccionProgramada
                    visible={this.state.modalTransaccionProgramadaVisible}
                    onClose={() => {
                        this.setState({modalTransaccionProgramadaVisible: false})
                        this.updateAll()
                    }}
                    acreedor_id={this.state.acreedor_id}
                    area={true}
                    rubro={true}
                />
                <ModalDocumentos
                    modalVisible={this.state.modalVisible}
                    acreedor_id={this.props.params.id}
                    closeMethod={() => {
                        this.get()
                        this.setState({modalVisible: false})
                    }}
                    accept={() => {
                        this.get()
                        this.setState({modalVisible: false})
                    }}
                >
                </ModalDocumentos>
            </div>
        )
    }
}

export default function (props) {
    let user = React.useContext(Logged);

    const params = useParams();

    let permissions = usePermissions(user?.rol_id?.permisos, {
        
        crearTransacciones: ["acreedores", "transacciones", "create"],
        verTransacciones: ["acreedores", "transacciones", "read"],

        crearTransaccionesProgramadas: ["acreedores", "transacciones-programadas", "create"],
        verTransaccionesProgramadas: ["acreedores", "transacciones-programadas", "read"]

    })

    return <DetalleAcreedor {...props} {...permissions} params={params}/>
}

DetalleAcreedor.contextType = Logged;
