import React, { Component } from 'react';
import { Avatar, Spin, PageHeader, Layout, Row, Col, Typography, message, Space, } from 'antd'
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";

import {
    IconArrowBack, IconWalletBG,
    IconMoneyBG,
    IconHistorialBG,
    IconBilleteBG,
} from '../../Widgets/Iconos';
import { MoreOutlined, RightOutlined, } from "@ant-design/icons"
import { CardGraphMontosComparacion, CardIconoMoney, CardListaMontos, CardPagosPendientesPorcentajes } from '../../Widgets/Cards';
import axios from 'axios';
import User from '../../../Hooks/Logged';
import usePermissions from '../../../Hooks/usePermissions';
import NotAllow from '../NotAllow';

const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;
const moment = require('moment');
moment.locale('es');

/**
 * @export
 * @class Dashboard
 * @extends {Component}
 * @description Vista de dashboard de transacciones programadas
 */
class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading_montos: false,
            loading_historico: false,
            loading_listados: false,
            crm: {
                data: []
            },
            inversiones: {
                data: []
            },
            acreedores: {
                data: []
            },
            validaciones: {
                data: []
            },
            data_montos: [
                {
                    _id: 1,
                    fecha: new Date(),
                    monto: '12321.98',
                    concepto: 'Concepto'
                },
                {
                    _id: 1,
                    fecha: new Date(),
                    monto: '12321.98',
                    concepto: 'Concepto'
                },
                {
                    _id: 1,
                    fecha: new Date(),
                    monto: '12321.98',
                    concepto: 'Concepto'
                },
                {
                    _id: 1,
                    fecha: new Date(),
                    monto: '12321.98',
                    concepto: 'Concepto'
                },
                {
                    _id: 1,
                    fecha: new Date(),
                    monto: '12321.98',
                    concepto: 'Concepto'
                },

            ],
            por_recibir: 0,
            proximo: 0,
            recibido: 0,
            vencido: 0,
            ingresos: [],
            egresos:[]
        }
    }



    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getMontos()
        this.getListados()
    }


    getMontos = () => {
        this.setState({ loading_montos: true })
        axios.get("/cash-flow/montos",{
            params:{}
        })
        .then(({data}) => {
            this.setState({
                ...data
            })
        }).catch(error => {
            message.error('Error al obtener los montos anuales')
            console.log(error.response);
        }).finally(() => this.setState({ loading_montos: false }))
    }

    getListados = () => {
		this.setState({ loading_listados: true })
		axios.get('/cash-flow/listados')
			.then(({ data }) => {
				console.log("data", data);
				this.setState({ ...data });
			})
			.catch(error => {
				message.error('Error al obtener el listados')
				console.log(error.response)
			}
			).finally(() => this.setState({ loading_listados: false }))
	}

    render() {
        return (
            <Spin spinning={false}>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title="Cash Flow"
                />
                <Content className="admin-content content-bg pd-1">
                    <Row gutter={[8, 8]}>
                        
                    <Col span={6}>
                            <CardIconoMoney
                                to={`/admin/cash-flow/lista?grupo=vencidos`}
                                loading={this.state.loading_montos}
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                title="Vencidos"
                                icon={<IconWalletBG />}
                                first_subtitle="Pagos Vencidos"
                                second_subtitle="Cobros Vencidos"
                                first={this.state.egreso_vencido}
                                second={this.state.ingreso_vencido}
                            />
                        </Col>
                        <Col span={6}>
                            <CardIconoMoney
                                to={`/admin/cash-flow/lista?grupo=por_vencer`}
                                loading={this.state.loading_montos}
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                title="Por Vencer"
                                first_subtitle="Pagos por Vencer"
                                second_subtitle="Cobros por Vencer"
                                first={this.state.egreso_por_vencer}
                                second={this.state.ingreso_por_vencer}

                                icon={<IconMoneyBG />}
                            />

                        </Col>
                        <Col span={6}>
                            <CardIconoMoney
                                to={`/admin/cash-flow/lista?grupo=recibidos`}
                                loading={this.state.loading_montos}
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                title="Recibidos"
                                first_subtitle="Pagos Recibidos"
                                second_subtitle="Cobros Recibidos"
                                first={this.state.ingreso_recibido}
                                second={this.state.egreso_recibido}


                                icon={<IconHistorialBG />}
                            />

                        </Col>
                        <Col span={6}>
                            <CardIconoMoney
                                to={`/admin/cash-flow/lista?grupo=por_recibir`}
                                loading={this.state.loading_montos}
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                
                                title="Por Recibir"
                                first_subtitle="Pagos por Recibir"
                                second_subtitle="Cobros por Recibir"
                                first={this.state.ingreso_por_recibir}
                                second={this.state.egreso_por_recibir}
                                icon={<IconBilleteBG />}
                            />
                        </Col>
                        <Row gutter={[8, 8]} className=' width-100'>
                            <Col span={24}>
                                <CardGraphMontosComparacion/>
                            </Col>

                            <Col lg={6} md={12} xs={24}>
                                <CardListaMontos
                                    loading={this.state.loading_listados}
                                    title='Validación'
                                    data={this.state.validaciones?.data}
                                    extra={<Link to={'/admin/cash-flow/lista'}>Ver Todos<RightOutlined /></Link>} />
                            </Col>
                            <Col lg={6} md={12} xs={24}>
                                <CardPagosPendientesPorcentajes
                                    loading={this.state.loading_listados}
                                    header='Acreedores'
                                    title={(item) => <Title level={5}>{item.acreedor}</Title>}
                                    data={this.state.acreedores?.data}
                                    extra={<Link to={'/admin/acreedores'}>Ver Todos<RightOutlined /></Link>}
                                />
                            </Col>
                            <Col lg={6} md={12} xs={24}>
                                <CardPagosPendientesPorcentajes
                                    loading={this.state.loading_listados}
                                    header='CRM'
                                    title={(item) => <Title level={5}>{item.nombre}</Title>}
                                    data={this.state.crm?.data}
                                    extra={<Link to={'/admin/crm/clientes'}>Ver Todos<RightOutlined /></Link>}
                                />
                            </Col>
                            <Col lg={6} md={12} xs={24}>
                                <CardPagosPendientesPorcentajes
                                    loading={this.state.loading_listados}
                                    header='Inversiones'
                                    title={(item) => <Title level={5}>{item.nombre}</Title>}
                                    data={this.state.inversiones?.data}
                                    extra={<Link to={'/admin/financial-health/inversiones'}>Ver Todos<RightOutlined /></Link>}
                                />
                            </Col>

                        </Row>
                    </Row>


                </Content>
            </Spin>
        )
    }
}

export default function () {
    const location = useLocation()

    let user = React.useContext(User);
    let permissions = usePermissions(user?.rol_id?.permisos, {
        access: ["cash-flow", "access"],
    })

    return <Dashboard
        params={useParams()}
        navigate={useNavigate()}
        location={location}
    />

}