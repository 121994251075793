import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select 
const axios = require("axios");

/**
 * @const SelectInmueble
 * @description Select para los inmuebles registrados en el sistema, relacionados a un proyecto
 */
const SelectInmueble = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el inmueble", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        proyecto_id,
        mode = null
    } = props

    const [ inmuebles, setInmuebles ] = useState({
        data: [],
        page: 1,
        limit: 50,
        total: 0,
        search: null,
    })


    /**
     * @const getInmuebles
     * @description Obitiene los inmubeles relacionados a un proyecto
     */
    const getInmuebles = ({
        page, 
        limit, 
        search, 
        proyecto_id = props.proyecto_id
    } = inmuebles) => {

        axios.get('/inmuebles', {
            params: {
                page,
                limit,
                search,
                proyecto_id,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setInmuebles(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los inmuebles')
        })
    }

    //componentDidMount
    useEffect(() => {
        if(proyecto_id || params?.proyecto_ids)
            getInmuebles()
    }, [proyecto_id, params?.proyecto_ids])

    useEffect(() => {
        if(value)
            onChange(value?.value ?? value)
    }, [value])

    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            mode={mode}
            onSearch={(search)=> getInmuebles({search})}
            onSelect={(inmueble)=> {
                if(mode === null){
                    onChange(inmueble.value)
                    onSelect(inmueble.value)
                }
            }}
            onChange={(values)=>{
                if(mode === "multiple"){
                    let arr = values.map(e => e.value)
                    onChange(arr)
                    onSelect(arr)
                }
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {
                inmuebles?.data?.map(({ _id, nombre }) => <Option value={_id}>{nombre ?? ""} {}</Option>)
            }
        </Select>
    );
}



export default SelectInmueble;