import React, { Component } from 'react';
import { Button, Col, Row, PageHeader, Layout, message, Typography, Statistic, Card, List, Space, Popconfirm, Tag, Tooltip } from 'antd'
import Carousel from 'react-multi-carousel';
import axios from 'axios'
import { Link } from 'react-router-dom';
// Components
import { IconEye, IconLink, IconEdit, IconDelete } from '../../Widgets/Iconos';
import { CardSaldo, CardCuenta, CardGains, CardEmpresa } from "../../Widgets/Cards"
import ModalTransaccionDetalle from '../Finanzas/Transacciones/ModalTransaccionDetalle';
import ModalTransaccion from "../Finanzas/Transacciones/ModalTransaccion";
import ModalFacturas from './ModalFacturas';
import ModalAsociacionFacturasTransacciones from './ModalAsociacionFacturasTransacciones';

//css
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';

import '../../../Styles/Modules/Contabilidad/Dashboard/dashboard.scss'
import ModalRenovarEmpresasSyncfy from './ModalRenovarEmpresasSyncfy';
import { RedoOutlined } from '@ant-design/icons';

const { Content, } = Layout;
const { Text, Title } = Typography;

const tipo = { "0": 'Sin Cambios', "1": ' Ingreso', "2": 'Egreso' }
const estatus_facturas = ['Factura Cancelada', 'Pendiente', 'Liquidad']

const moment = require('moment');
moment.locale('es');





/**
 * 
 * @export
 * @class DashboardFinanzas
 * @extends {Component}
 * @description Componente del Dashboard de Finanzas
 */
class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            transacciones: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                loading: false,
            },
            facturas: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                loading: false,
            },
            empresas: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },
            modalTransacDetalleVisible: false,
            transaccion_id: undefined,
            factura_id:undefined,
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getFacturas();
        this.getTransaccionesSinFacturas();
        this.getEmpresas();
    }

    /**
*
*
* @memberof Dashboard
* @description Obtiene todas todas las transacciones
* @param page pagina
* @param filters Filtrado de registros mediante el uso del form de filtros
*/
    getFacturas = (
        {
            page = this.state.facturas.page,
            limit = this.state.facturas.limit,
            search = this.state.facturas.search,

        } = this.state.facturas,
        { facturas } = this.state,
    ) => {
        facturas.loading = true;
        facturas.page = page;
        facturas.limit = limit;
        facturas.search = search;
        this.setState({ facturas })

        axios.get('/facturas', {
            params: {
                page,
                limit,
                search,
                sin_transacciones: true
            }

        })
            .then(res => {

                console.log('facturas sin transacciones data', res)
                let facturas = {
                    data: res.data.data,
                    page: res.data.page,
                    total: res.data.total,
                    pages: res.data.pages,

                    filtroSearch: search,
                    searching: true,
                    loading: false
                }
                this.setState({
                    facturas
                })
            })
            .catch(res => {
                console.log("error", res);
                message.error('No se pudieron cargar las facturas')
            })
    }


    /**
     *
     * @memberof Dashboard
     * @method getEmpresas
     * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
     */
    getEmpresas = ({
        page = this.state.empresas.page,
        limit = this.state.empresas.limit,
        search = this.props.search
    } = this.state.empresas) => {
        this.setState({ loading: true, empresas: { ...this.state.empresas, page, limit } })
        axios.get('/empresas', {
            params: { limit, page, search, con_syncfy: true }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ empresas: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }));
    }

    /**
     *
     *
     * @memberof Dashboard
     * 
     * @method getTransacciones
     * @description Obitiene las Transacciones
     */
    getTransacciones = (
        {
            page = this.state.transacciones.page,
            limit = this.state.transacciones.limit,
            search = this.props.search
        } = this.state.transacciones
    ) => {
        // let search = this.props.search;
        // this.setState({ loading: true })
        axios.post('/transacciones/filters', {
            page, limit, search
        })
            .then(({ data }) => {
                this.setState({
                    transacciones: {
                        ...data,
                        ...this.state.transacciones,
                        loading: false
                    }
                })

            })
            .catch(error => {
                this.setState({
                    transacciones: { ...this.state.transacciones, loading: false }
                })
                console.log(error)
                message.error('Error al traer la información')
            })
    }

    getTransaccionesSinFacturas = (
        {
            page = this.state.transacciones.page,
            limit = this.state.transacciones.limit,
            search = this.state.transacciones.search,

        } = this.state.transacciones,
        { transacciones } = this.state,
    ) => {
        transacciones.loading = true;
        transacciones.page = page;
        transacciones.limit = limit;
        transacciones.search = search;
        this.setState({ transacciones })
        axios.post('/transacciones/filters', {
            page,
            limit,
            search,
            sin_facturas: true

        })
            .then(({ data }) => {
                console.log('data trans no fact',data)
                let transacciones = data.data
				this.setState({
					transacciones: {
						...this.state.transacciones,
						data: transacciones,
                        total: data.total,
                        pages: data.pages,
						loading: false
					},
					loading:false
				});
                
            })
            .catch(res => {
                console.log("error", res);
                message.error('No se pudieron cargar las transacciones')
            })
    }



    /**
     *
     * @memberof Dashboard
     * @description Renderiza el monto con el color correspondiente segun el tipo de transaccion
     */
    renderMonto = (tipo, monto) => {
        let colors = ['#000000', '#00FF19', '#FF0000'];
        return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }

    /**
     * @memberof Dashboard
     * 
     * @method reloadView
     * @description Actualiza la vista si se buscan datos
     */
     reloadView = () => {
        this.getTransaccionesSinFacturas();
        this.getFacturas();
    }

    render() {
        const { empresas, total } = this.state
        return (
            <>
                <PageHeader
                    className='custom-page-header'
                    title="Dashboard Contabilidad"
                    extra={
						<Tooltip title="Renovar tokens de empresas (Scynfy)">
							<Button icon={<RedoOutlined />} onClick={() => this.setState({modal_renovar: true})} >
								Renovar Credenciales
							</Button>
						</Tooltip>
					}
                />
                <Content className='admin-content content-bg pd-1'>
                    <Row >
                        <Col span={24}><Title className="subtitle-dashboard" level={2}>Empresas </Title></Col>

                        <Col xs={24} lg={24} xl={24} xxl={24}>
                            <Carousel
                            
                                containerClass='carousel-cards-contabilidad'
                                // className="x-carousel"
                                // valueStyle={{ position: "relative", left: "-30px" }}
                                responsive={{
                                    desktop_fhq: {
                                        breakpoint: { max: 4000, min: 1200 },
                                        items: 2
                                    },
                                    desktop_hq: {
                                        breakpoint: { max: 1500, min: 1200 },
                                        items: 1
                                    },
                                    desktop_xxl: {
                                        breakpoint: { max: 1200, min: 992 },
                                        items: 1
                                    },
                                    desktop_xl: {
                                        breakpoint: { max: 992, min: 768 },
                                        items: 1
                                    },
                                    desktop_lg: {
                                        breakpoint: { max: 768, min: 576 },
                                        items: 1
                                    },
                                    desktop_md: {
                                        breakpoint: { max: 576, min: 464 },
                                        items: 1
                                    },
                                    desktop_sm: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 1
                                    },
                                
                                    mobile: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 1
                                    }
                                }}
                            >
                                {empresas.data.map(empresa => {
                                    return <CardEmpresa className="card-empresa" empresa={empresa} />
                                })}
                            </Carousel>
                        </Col>
                    </Row>
                    <Row>
                        <Row className="width-100 mt-1 " justify="space-between" align="middle" >
                            <Col span={24}><Title className="subtitle-dashboard" level={2}>Facturas sin Transacción </Title></Col>
                            <Col span={24}>
                                <List
                                    loading={this.state.facturas.loading}
                                    className="component-list "
                                    size="large"
                                    itemLayout="horizontal"
                                    dataSource={this.state.facturas.data}
                                    renderItem={item => (
                                        <List.Item className="component-list-item">
                                            <Card className="card-list" bordered={false}>
                                                <Row className="width-100" justify="space-around">
                                                    <Col span={3} className="center item-info">
                                                        <Text ellipsis strong>{moment(item?.fecha).format('YYYY-MM-DD')}</Text>
                                                    </Col>
                                                    <Col span={4} className="center item-info">
                                                        <Text ellipsis className="text-gray-dark">{item?.concepto}</Text>
                                                    </Col>
                                                    <Col span={4} className="center item-info">
                                                        <span className="spn-estatus">
                                                            <Tag ellipsis className="text-gray-dark tag-facturas">{estatus_facturas[item?.estatus]}</Tag>
                                                        </span>
                                                    </Col>
                                                    <Col span={3} className="center item-info">
                                                        <span className="spn-tipo">
                                                            <Tag ellipsis className="text-gray-dark tag-facturas">{tipo[item?.tipo]}</Tag>
                                                        </span>
                                                    </Col>
                                                    <Col span={2} className="center item-info">
                                                        <Text ellipsis className="text-gray-dark">{item?.emisor_id?.nombre}</Text>
                                                    </Col>
                                                    <Col span={2} className="center item-info">
                                                        <Text ellipsis className="text-gray-dark">{item?.receptor_id?.nombre}</Text>
                                                    </Col>
                                                    <Col span={3} className="center item-info">
                                                        <Text ellipsis className="text-back text-monto"> $ {item?.monto}</Text>
                                                    </Col>
                                                    <Col span={3} className="center item-info">
                                                        <Space direction="horizontal">
                                                            <Button
                                                                className="btn-eye"
                                                                icon={<IconEye />}
                                                                type="primary"
                                                                title="Ver Detalle"
                                                                disabled={!item.factura_xml}
                                                            >
                                                            </Button>

                                                            <Button
                                                                className="btn-link"
                                                                icon={<IconLink height={15} width={15} />}
                                                                type="primary"
                                                                title="Link"
                                                                onClick={() => this.setState({ modal_link: true, factura_id: item._id })}

                                                            >
                                                            </Button>

                                                            <Button
                                                                className="btn-editar"
                                                                icon={<IconEdit />}
                                                                type="primary"
                                                                title="Editar"
                                                                onClick={() => { this.setState({ modal_factura_editar: true, factura_id: item._id }) }}

                                                            >
                                                            </Button>
                                                            <Popconfirm
                                                                placement="topRight"
                                                                title="¿Deseas eliminar esta factura?"
                                                                onConfirm={() => axios.delete('/facturas', {params:{ id: item._id }}).then((response) => {
                                                                    message.success('Factura Eliminada')
                                                                    this.getFacturas()
                                                                }).catch((error) => {
                                                                    message.success('Factura NO Eliminada')
                                                                    this.getFacturas();
                                                                    })
                                                                }
                                                                okText="Si"
                                                                cancelText="No"
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    danger
                                                                    icon={<IconDelete />} title="Eliminar" />
                                                            </Popconfirm>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                    pagination={{
                                        onChange: (page, limit) => {
                                            this.getFacturas({ page, limit })
                                        },
                                        hideOnSinglePage: true,
                                        total: this.state.facturas.total,
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="width-100 mt-1 " justify="space-between" align="middle">
                            <Col span={24}><Title className="subtitle-dashboard" level={2}>Transacción sin Facturas </Title></Col>
                            <Col span={24}>
                                <List
                                    loading={this.state.transacciones.loading}
                                    className="component-list "
                                    size="large"
                                    itemLayout="horizontal"
                                    dataSource={this.state.transacciones.data}
                                    renderItem={item => (

                                        <List.Item className="component-list-item ">
                                            <Card className="card-list" bordered={false}>
                                                <Row className="width-100" justify="space-around">
                                                    <Col span={2} className="center item-info">
                                                        <Text ellipsis strong>{moment(item?.fecha).format('YYYY-MM-DD')}</Text>
                                                    </Col>
                                                    <Col span={4} className="center item-info">
                                                        <Text ellipsis className="text-gray-dark">{item?.concepto}</Text>
                                                    </Col>
                                                    <Col span={3} className="center item-info">
                                                        <Text ellipsis className="text-gray-dark">{(item?.area_id !== undefined) ? item?.area_id.nombre : 'Sin Area'}</Text>
                                                    </Col>
                                                    <Col span={3} className="center item-info">
                                                        <Text ellipsis className="text-gray-dark">{(item?.rubro_id !== undefined) ? item?.rubro_id.nombre : 'Sin Rubro'}</Text>
                                                    </Col>

                                                    <Col span={4} className="center item-info">
                                                        <Text ellipsis className="text-gray-dark">{item?.cuenta_id?.nombre}</Text>
                                                    </Col>
                                                    <Col span={4} className="center item-info">
                                                        <Text ellipsis className="text-back text-monto"> $ {item?.monto}</Text>
                                                    </Col>
                                                    <Col span={4} className="center item-info">
                                                        <Space direction="horizontal">
                                                            <Button
                                                                className="btn-eye"
                                                                icon={<IconEye />}
                                                                type="primary"
                                                                title="Ver Detalle"
                                                                onClick={() => this.setState({ modal_trans_detalle: true, transaccion_id: item._id })}

                                                            >
                                                            </Button>

                                                            <Button
                                                                className="btn-link"
                                                                icon={<IconLink height={15} width={15} />}
                                                                type="primary"
                                                                title="Link"
                                                                onClick={() => this.setState({ modal_link: true, transaccion_id: item._id })}

                                                            >
                                                            </Button>

                                                            <Button
                                                                className="btn-editar"

                                                                icon={<IconEdit />}
                                                                type="primary"
                                                                title="Editar"
                                                                onClick={() => this.setState({ modal_trans_editar: true, transaccion_id: item._id })}

                                                            >
                                                            </Button>
                                                            <Popconfirm
                                                                placement="topRight"
                                                                title="¿Deseas eliminar esta Transacción?"
                                                                onConfirm={() => axios.delete('/transacciones', {params:{ id: item._id }}).then((response) => {
                                                                    message.success('Transaccion Eliminada')
                                                                    this.getTransaccionesSinFacturas()
                                                                }).catch((error) => {
                                                                    message.error('Transacción NO Eliminada')
                                                                    this.getTransaccionesSinFacturas();
                                                                })
                                                                }
                                                                okText="Si"
                                                                cancelText="No"
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    danger
                                                                    icon={<IconDelete />} title="Eliminar" />
                                                            </Popconfirm>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                    pagination={{
                                        onChange: (page, limit) => {
                                            this.getTransaccionesSinFacturas({ page, limit })
                                        },
                                        hideOnSinglePage: true,
                                        total: this.state.transacciones.total,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Row>
                </Content>

                <ModalFacturas
                        visible={this.state.modal_factura_editar}
                        onClose={() => {
                            this.setState({ modal_factura_editar: false, factura_id: undefined })
                            this.getFacturas()
                        }}
                        factura_id={this.state.factura_id}
                    />

                <ModalTransaccion
					visible={this.state.modal_trans_editar}
					transaccion_id={this.state.transaccion_id}
					onClose={() => {
						this.setState({ modal_trans_editar: false, transaccion_id: undefined })
						this.getTransacciones()
					}}
				/>
				<ModalTransaccionDetalle
					visible={this.state.modal_trans_detalle}
					onClose={() => this.setState({
						modal_trans_detalle: false,
						transaccion_id: null
					})}
					transaccion_id={this.state.transaccion_id}
				/>
                <ModalRenovarEmpresasSyncfy
					visible={this.state.modal_renovar}
					onClose={() => {
                        this.setState({
                            modal_renovar: false
                        })
                        this.reloadView()
                    }}
				/>
                <ModalAsociacionFacturasTransacciones
                    visible={this.state.modal_link}
                    onClose=

                    {() => this.setState({
                        modal_link: false,
                        transaccion_id: undefined,
                        factura_id: undefined
                    }, this.reloadView())

                    }
                    factura_id={this.state.factura_id}
                    transaccion_id={this.state.transaccion_id}

                />
            </>
        )
    }
}

export default function (props) {

    return <Dashboard {...props} navigate={useNavigate()} />

}