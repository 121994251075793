import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, Select, message, Upload, Spin, InputNumber, DatePicker, Space, Divider, Badge, Avatar, Popover } from 'antd';
import { PlusOutlined, UploadOutlined, MinusCircleOutlined, DeleteOutlined, CloseOutlined, ClockCircleOutlined, DollarCircleOutlined, CheckCircleOutlined, CheckOutlined } from '@ant-design/icons';
import moment from 'moment'

import Decimal from 'decimal.js'

import "../../../../Styles/Modules/CRM/Clientes.scss"
import { Uploader } from "../../../Widgets/Uploaders";
import { Link, useNavigate, useParams } from "react-router-dom";


const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;


class ModalPropiedades extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proyecto_id: undefined,
            area_id: undefined,

            proyectos: {
                data: [],
                page: 1,
                limit: 25,
                total: 0,
                search: undefined,
                loading: false
            },
            usuarios: {
                data: [],
                page: 1,
                limit: 25,
                total: 0,
                search: undefined,
                loading: false
            },
            inmuebles: {
                data: [],
                page: 1,
                limit: 25,
                total: 0,
                search: undefined,
                loading: false
            },
            areas: {
                data: [],
                page: 1,
                limit: 25,
                total: 0,
                search: undefined,
                loading: false
            },
            rubros: {
                data: [],
                page: 1,
                limit: 25,
                total: 0,
                search: undefined,
                loading: false
            },

            loadings: {
                documentos: false
            }
        }
    }

    refModalPropiedades = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.propiedad_id) this.getPropiedad()

        this.getUsuarios()
        this.getProyectos()
        this.getInmuebles()
        this.getAreas()
        this.getRubros()
    }



    /**
     * @memberof ModalPropiedades
     * @method getPropiedad
     * @description Obtiene un propiedad
     *
     */
    getPropiedad = () => {
        this.setState({ loading: true })
        axios.get(`/propiedades/${this.props.propiedad_id}`, {
            params: {
                id: this.props.propiedad_id
            }
        }).then(response => {
            let propiedad = response.data;
            console.log("propiedad", propiedad.adicionales);
            this.setState({
                proyecto_id: propiedad?.proyecto_id?._id,
                area_id: propiedad.area_id._id,
                monto_total: propiedad.monto_total
            })
            this.refModalPropiedades.current?.setFieldsValue({
                ...propiedad,
                fecha_venta: moment(propiedad.fecha_venta),
                proyecto_id: {
                    value: propiedad.proyecto_id?._id,
                    label: propiedad.proyecto_id?.nombre,
                },
                inmueble_id: {
                    value: propiedad.inmueble_id?._id,
                    label: propiedad.inmueble_id?.nombre,
                },
                area_id: {
                    value: propiedad.area_id?._id,
                    label: propiedad.area_id?.nombre,
                },
                rubro_id: {
                    value: propiedad.rubro_id?._id,
                    label: propiedad.rubro_id?.nombre,
                },
                asesor_id: {
                    value: propiedad.asesor_id?._id,
                    label: propiedad.asesor_id?.nombre,
                },
                documentos: propiedad.documentos.map((file, index) => ({
                    uid: file.file,
                    name: file.name,
                    status: 'done',
                    fromDB: true,
                    url: axios.defaults.baseURL + "/propiedades/" + this.props.propiedad_id + "?documento=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                })),
                adicionales: propiedad.adicionales?.map(({ documentos, ...adicional }) => ({
                    ...adicional,
                    documentos: documentos.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/propiedades/" + this.props.propiedad_id + "?adicional=" + file.file + "&adicional_id=" + adicional._id + "&Authorization=" + sessionStorage.getItem("token")
                    }))
                }))
            })
        }).catch(error => {
            message.error('Error al traer la propiedad')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof ModalPropiedades
     * 
     * @method getProyectos
     * @description Obtiene los Proyectos
     */
    getProyectos = ({
        page = this.state.proyectos.page,
        limit = this.state.proyectos.limit,
        search = this.props.search,
    } = this.state.proyectos) => {

        axios.get('/proyectos', {
            params: { page, limit, search }
        }).then(({ data }) => {
            this.setState(state => {
                state.proyectos.data = data.data
                state.proyectos.total = data.total
                state.proyectos.page = data.page

                return state;
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof ModalPropiedades
     * 
     * @method getInmuebles
     * @description Obtiene los Inmuebles
     */
    getInmuebles = ({
        page = this.state.inmuebles.page,
        limit = this.state.inmuebles.limit,
        search = this.props.search,
        proyecto_id = this.state.proyecto_id,
    } = this.state.inmuebles) => {
        this.setState({ proyecto_id })
        axios.get('/inmuebles', {
            params: {
                page,
                limit,
                search,
                listado: true,
                proyecto_id
            }
        }).then(({ data }) => {
            this.setState({
                inmuebles: {
                    ...this.state.inmuebles,
                    ...data
                }
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof ModalPropiedades
     * 
     * @method getUsuarios
     * @description Obtiene los Inmuebles
     */
    getUsuarios = ({
        page = this.state.usuarios.page,
        limit = this.state.usuarios.limit,
        search = this.props.search,
    } = this.state.usuarios) => {
        axios.get('/usuarios', {
            params: {
                page,
                limit,
                search,
                listado: true,
            }
        }).then(({ data }) => {
            this.setState({
                usuarios: {
                    ...this.state.usuarios,
                    ...data
                }
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof ModalPropiedades
     * 
     * @method getAreas
     * @description Obtiene las Areas
     */
    getAreas = ({
        page = this.state.areas.page,
        limit = this.state.areas.limit,
        search = this.props.search,
    } = this.state.areas) => {

        axios.get('/areas', {
            params: { page, limit, search }
        }).then(({ data }) => {
            this.setState(state => {
                state.areas.data = data.data
                state.areas.total = data.total
                state.areas.page = data.page

                return state;
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof ModalPropiedades
     * 
     * @method getRubros
     * @description Obtiene los Rubros
     */
    getRubros = ({
        page = this.state.rubros.page,
        limit = this.state.rubros.limit,
        area_id = this.state.area_id,
        search = this.props.search,
    } = this.state.rubros) => {

        this.setState({ area_id })
        axios.get('/rubros', {
            params: { page, limit, search, area_id }
        }).then(({ data }) => {
            this.setState({
                rubros: {
                    ...this.state.rubros,
                    ...data
                }
            })
            // this.setState(state => {
            //     state.rubros.data = data.data
            //     state.rubros.total = data.total
            //     state.rubros.page = data.page
            //     if (id) state.area_id = id
            //     return state;
            // })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }



    /**
    * @memberof ModalPropiedades
    * @method onFinish
    * @description Se ejecuta al dar enter al formulario
    * 
    */
    onFinish = (values) => {
        // console.clear()
        // console.log("values", values);
        const v = { ...values }

        for (let key in v) {
            if (v[key]?.label && v[key]?.value) {
                v[key] = v[key].value
            }
        }
        console.log("values", values);

        v.monto_total = this.state.monto_total
        v.cliente_id = this.props.cliente_id

        const formData = new FormData()
        formData.appendMultiple({
            ...v,
            documentos: undefined,
            adicionales: undefined,
            adicionales_documentos: undefined,
        })

        v.documentos?.forEach(e => {
            if (!e.fromDB)
                formData.append("documentos", e, e.name)
        })

        v.adicionales?.forEach((e, index) => {
            formData.append(`adicionales`, JSON.stringify({ _id: e._id, nombre: e.nombre, costo: e.costo, documentos: e.documentos?.map(f => f.name) }))

            if (e.documentos?.length > 0)
                for (const archivo of e.documentos) {
                    if (!archivo.fromDB)
                        formData.append("adicionales_documentos", archivo, archivo.name)
                }
        })

        //Aveces es ignorado y gg
        if (!formData.get("estatus"))
            formData.append("estatus", values.estatus)

        if (this.props.propiedad_id) {
            this.updatePropiedad(formData)
        } else {
            this.savePropiedad(formData)
        }
    }

    updatePropiedad = (values) => {
        this.setState({ loading: true })
        axios.put('/propiedades/' + this.props.propiedad_id, values)
            .then(response => {
                message.success('Propiedad actualizada')
                this.props.onClose()
            }).catch(error => {

                console.log(error.response)
                message.error('Error al actualizar la propiedad')
            }).finally(() => this.setState({ loading: false }))
    }

    savePropiedad = (values) => {
        this.setState({ loading: true })
        axios.post('/propiedades', values)
            .then(response => {
                message.success('Propiedad creada')
                this.props.onClose()
            }).catch(error => {
                console.log(error.response)
                message.error('Error al crear la propiedad')
            }).finally(() => this.setState({ loading: false }))
    }


    render() {

        let hide = this.props.only_docs === true || this.props.adicionales === true

        return (
            <Form
                layout="vertical"
                name="form-propiedads"
                id="form-propiedads"
                ref={this.refModalPropiedades}
                onFinish={this.onFinish}
                onValuesChange={(valuesChange, allValues) => {

                    if (valuesChange.monto_venta || valuesChange.adicionales) {
                        let monto_venta = allValues?.monto_venta ?? 0

                        let totalAdicionales = (Array.isArray(allValues.adicionales)) ? allValues.adicionales.reduce(
                            (previousValue, adicional) => previousValue.add(Decimal(adicional?.costo ?? 0)),
                            Decimal(0)
                        ) : Decimal(0)

                        this.setState({ monto_total: Decimal(monto_venta).add(totalAdicionales).toDecimalPlaces(2).toNumber() })
                    }
                }}

                initialValues={{
                    tipo: 1
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                hidden={hide}
                                label="Proyecto"
                                name="proyecto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el proyecto"
                                }]}
                            >
                                <Select
                                    className="width-100"
                                    showSearch
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getProyectos({ search })}
                                    onSelect={({ value }) => {
                                        if (value !== this.state.proyecto_id)
                                            this.refModalPropiedades.current?.setFieldsValue({
                                                inmueble_id: undefined
                                            })
                                        this.getInmuebles({ proyecto_id: value })
                                    }}
                                    labelInValue
                                >
                                    {this.state.proyectos?.data?.map((proyecto) => {
                                        return <Option value={proyecto._id} >
                                            {proyecto.nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                hidden={hide}
                                label="Inmuebles"
                                name="inmueble_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el inmueble"
                                }]}


                            >
                                <Select
                                    className="width-100"
                                    showSearch
                                    disabled={!this.state.proyecto_id}
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getInmuebles({ search })}
                                    labelInValue

                                    onSelect={(e, x) => {
                                        if (!x.available) {
                                            Modal.warning({
                                                title: "¡Está a punto de indicar un traspaso!",
                                                content: <>
                                                    Este inmueble pertence a otro cliente <strong>{x?.cliente?.nombre} {x?.cliente?.apellido}</strong>, por lo que al seleccionarlo, la propiedad del inmueble se traspará a este cliente y la propiedad del cliente anterior aparecera como "Cancelada"
                                                </>
                                            })
                                        }
                                    }}
                                >
                                    {this.state.inmuebles?.data?.map((inmueble) => {
                                        return <Option value={inmueble._id} title={inmueble.nombre} available={inmueble?.estatus == 0} cliente={inmueble?.cliente}>
                                            <Popover placement="left" content={(inmueble?.estatus == 1) ? <a
                                                onClick={() => {

                                                    // to={}
                                                    this.props.onClose()
                                                    this.props.navigate(`/admin/crm/clientes/${inmueble?.cliente?._id}/propiedad/${inmueble?.propiedad?._id}`)
                                                }}
                                            >
                                                <i><small>Comprado por:</small></i> <br></br>
                                                <strong>{inmueble?.cliente?.nombre} {inmueble?.cliente?.apellido}</strong>
                                            </a> : "Inmueble Disponible"}>
                                                <div style={{ width: "100%", height: "100%" }}>
                                                    {(inmueble?.estatus == 1) ? <CloseOutlined style={{ color: '#f5222d' }} /> : <CheckOutlined style={{ color: "green" }} />}
                                                    <span>{inmueble.nombre} </span>
                                                </div>
                                            </Popover>
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                hidden={hide}
                                label="Areas"
                                name="area_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el area"
                                }]}
                            >
                                <Select
                                    className="width-100"
                                    showSearch
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getAreas({ search })}
                                    onSelect={({ value }) => {
                                        if (value !== this.state.area_id) this.refModalPropiedades.current?.setFieldsValue({
                                            rubro_id: undefined
                                        })
                                        this.getRubros({ area_id: value })
                                    }}
                                    labelInValue
                                >
                                    {this.state.areas?.data?.map((area) => {
                                        return <Option value={area._id} >
                                            {area.nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                hidden={hide}
                                label="Rubros"
                                name="rubro_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el rubro"
                                }]}
                            >
                                <Select
                                    className="width-100"
                                    showSearch
                                    disabled={!this.state.area_id}
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getRubros({ search })}
                                    labelInValue
                                >
                                    {this.state.rubros?.data?.map((rubro) => {
                                        return <Option value={rubro._id} >
                                            {rubro.nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                hidden={hide}
                                label="Estatus"
                                name="estatus"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el estatus"
                                }]}
                            >
                                <Select
                                    className="width-100"
                                    showArrow
                                >
                                    <Option value={0} ><CloseOutlined style={{ color: "#F50" }} /> Cancelada</Option>
                                    <Option value={1} ><ClockCircleOutlined style={{ color: "#FBBC30" }} /> Apartada</Option>
                                    <Option value={2} ><DollarCircleOutlined style={{ color: "#2db7f5" }} /> En Proceso de Pago</Option>
                                    <Option value={3} ><CheckCircleOutlined style={{ color: "#87d068" }} /> Pagada</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                hidden={hide}
                                label="Asesor de la Venta"
                                name="asesor_id"
                                rules={[{
                                    required: true,
                                    message: "Debe de indicar el asesor en cuesti[on"
                                }]}
                            >
                                <Select
                                    className="width-100"
                                    showSearch
                                    disabled={!this.state.area_id}
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getUsuarios({ search })}
                                    labelInValue
                                >
                                    {this.state.usuarios?.data?.map(({ _id, nombre, apellido }) => {
                                        return <Option value={_id} >
                                            {nombre} {apellido}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} md={12}>
                            <Form.Item
                                label="Tipo"
                                name="tipo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo"
                                }]}
                            >
                                <Select
                                    className="width-100"
                                    showArrow
                                >
                                    <Option value={1} >Normal</Option>
                                    <Option value={2} >Cancelado</Option>
                                    <Option value={3} >Inactivo</Option>
                                </Select>
                            </Form.Item>
                        </Col> */}
                        {hide ? null : <Col xs={24}>
                            <Divider>Información de Venta</Divider>
                        </Col>}
                        <Col xs={24} md={12}>
                            <Form.Item
                                hidden={hide}
                                label="Monto"
                                name="monto_venta"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el monto"
                                }]}
                            >
                                <InputNumber
                                    disabled={false}
                                    min={0.01}
                                    className="width-100"
                                    decimalSeparator=","
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                hidden={hide}
                                label="Fecha de Venta"
                                name="fecha_venta"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha de venta"
                                }]}
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>
                    




                        {this.props.adicionales === true ? null : <Col xs={24}>
                            <Form.Item
                                label="Documentos"
                                name="documentos"
                                valuePropName="fileList"
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e?.fileList;
                                }}>
                                <Uploader
                                    {...this.props.propiedad_id ? {
                                        method: "PUT",
                                        name: "documento",
                                        headers: {
                                            authorization: sessionStorage.getItem("token")
                                        },
                                        //action: axios.defaults.baseURL + '/transacciones',
                                        onRemove: e => {
                                            axios.put('/propiedades/' + this.props.propiedad_id, {
                                                documento: e.uid,
                                            })
                                        }
                                    } : {}}>
                                    <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                </Uploader>
                            </Form.Item>
                        </Col>}
                        {this.props.only_docs === true ? null : <><Col xs={24}>
                            <Divider>Aspectos Adicionales</Divider>
                        </Col>
                        <Col xs={24} className="propiedades-adicionales">
                            <Form.List 
                                name="adicionales" 
                                >

                                {(fields, { add, remove }) => (
                                    <>

                                        <Row gutter={[16, 16]}>
                                            <Col span={8}>
                                                <Title level={5}>Nombre</Title>
                                            </Col>
                                            <Col span={8}>
                                                <Title level={5}>Cantidad / Monto</Title>
                                            </Col>
                                            <Col span={5}>
                                                <Title level={5}>Evidencia</Title>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]} style={{ marginTop: "0.5em" }}>
                                            {
                                                fields.map(({ key, name, ...restField }, index) => (
                                                    <>
                                                        <Form.Item
                                                            nname={[name, "_id"]}
                                                            hidden
                                                        />

                                                        <Col span={8}>
                                                            <Form.Item
                                                                {...restField}
                                                                // label="Nombre"
                                                                name={[name, "nombre"]}
                                                                rules={[{
                                                                    required: true,
                                                                    message: "Por favor, ingrese el nombre"
                                                                }]}
                                                            >
                                                                <Input placeholder="Nombre" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Form.Item
                                                                {...restField}
                                                                // label="Costo"
                                                                name={[name, "costo"]}
                                                                rules={[{
                                                                    required: true,
                                                                    message: "Por favor, ingrese el costo"
                                                                }]}
                                                            >
                                                                <InputNumber
                                                                    disabled={false}
                                                                    min={0.01}
                                                                    className="width-100"
                                                                    decimalSeparator=","
                                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                                                />
                                                            </Form.Item>

                                                        </Col>
                                                        <Col span={6} style={{ alignSelf: 'center' }}>
                                                            <Form.Item

                                                                // label="Documentos"
                                                                className="width-100"
                                                                name={[name, "documentos"]}
                                                                valuePropName={"fileList"}
                                                                getValueFromEvent={e => {
                                                                    if (Array.isArray(e)) {
                                                                        console.log("e",);
                                                                        return e.filter(f => {
                                                                            return f instanceof File || f.fromDB ? true : false
                                                                        });
                                                                    }
                                                                    return e.fileList.filter(f => {
                                                                        return f instanceof File || f.fromDB ? true : false
                                                                    });
                                                                }}>
                                                                <Uploader
                                                                    {...this.props.propiedad_id ? {
                                                                        onRemove: e => {
                                                                            axios.put('/propiedades/' + this.props.propiedad_id, {
                                                                                adicional: e.uid,
                                                                                index
                                                                            })
                                                                        }
                                                                    } : {}}>
                                                                    <Button icon={<UploadOutlined />} block size="small">Subir</Button>
                                                                </Uploader>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={2} align="end">
                                                            <Button danger icon={<DeleteOutlined />} onClick={() => remove(name)} />
                                                        </Col>
                                                    </>
                                                ))
                                            }
                                        </Row>
                                        {(fields.length > 0) ? [

                                            <Divider style={{ marginTop: "0.5em", marginBottom: "0.5em" }} />,
                                            <Row gutter={[16, 16]} style={{ marginTop: "0.5em", marginBottom: "0.5em" }}>
                                                <Col span={8}>
                                                    <Title level={5}>Monto Total</Title>
                                                </Col>
                                                <Col span={9}>
                                                    <Title level={4}>{this.state.monto_total?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Title>
                                                </Col>
                                            </Row>

                                        ] : null}

                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Agregar Adicional
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}

                            </Form.List>
                        </Col> </>}
                    </Row>
                </Spin>
            </Form >
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, propiedad_id, } = props


    // const  =
    return <Modal
        visible={visible}
        onCancel={onClose}
        title={propiedad_id ? "Editar Propiedad" : "Nueva Propiedad"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText={"Cancelar"}
        okText={"Guardar"}
        okButtonProps={{
            form: 'form-propiedads',
            key: 'submit',
            htmlType: 'submit'
        }}
    >
        <ModalPropiedades
            {...props}
            navigate={useNavigate()}
            params={useParams()}

        />
    </Modal>

}