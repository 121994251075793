import React, { useState, useEffect } from 'react';
import { Form, Row, Col, InputNumber, Typography, Upload, Button, Space, Popconfirm } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { BsCheckLg } from 'react-icons/bs';

import { Uploader } from "../../../Widgets/Uploaders";

import axios from 'axios';
const { Text } = Typography;
/**
 * @const FormRequisicionOpcion
 * @description Componente que contiene el formualario para editar las requisiciones opciones de una requisicion
 */
const FormRequisicionOpcion = (props) => {

	let [data, setData] = useState({
        requisicion_opcion_id: props._id,
        monto_total: props.monto_total,
        precio_unitario: props.precio_unitario,
        volumen: props.volumen,
        dias_entrega: props.dias_entrega,
        costo_envio: props.costo_envio,
        proveedor_id: props.proveedor_id,
        archivos: props.archivos?.length > 0 ? props.archivos.map((file, index) => ({
            uid: file.file,
            name: file.name,
            status: 'done',
            fromDB: true,
            url: axios.defaults.baseURL + "/requisicion-opcion/" + props._id + "?archivo=" + file.file +"&Authorization=" + sessionStorage.getItem("token")
        })) : [],
    })

    let [count, setCount] = useState(props.archivos?.length)

    const handleChange = (values) => {

        let monto_total = 0,
            costo_envio = values.costo_envio ?? 0

        monto_total = (values.precio_unitario * data.volumen) + costo_envio

        setData({
            ...data,
            ...values,
            monto_total,
        })

        props.update({
            ...data,
            ...values,
            monto_total,
        })

    }


    /**
     *
     * @memberof ModalUsuarios
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    const normFile = (e) => {

        const { file } = e;

        file.filename = file.response?.filename

        return e && e.fileList;

    };

    const updateOne = (archivo) => {
        console.log("archivo", archivo);
        if(archivo instanceof File){
            const formData = new FormData()
            formData.appendMultiple({
                requisicion_opcion_id: data.requisicion_opcion_id,
                archivos: undefined
            })

                formData.append("archivos", archivo, archivo.name)
            

            axios.put('/requisicion-opcion',formData)
        }

        
    }


	return (
		<Form
            className="form-requisicion-opcion"
            initialValues={{...data}}
            onValuesChange={(value, allValues)=>{

                if(Array.isArray(value.archivos)){
                    //handleArchivos(value.archivos)
                    // const allHaveFilename = value.archivos.every(object => object.hasOwnProperty('filename') && object.filename);
                    // if(allHaveFilename){
                    //     props.update({
                    //         archivos: value.archivos,
                    //         requisicion_opcion_id: data.requisicion_opcion_id,
                    //     })
                    // }
                }else{
                    handleChange(allValues)
                }
            }}                                          
        >
            <Row>
                <Col span={5} className="center">
                    <Text>{data.proveedor_id?.alias}</Text>
                </Col>
                <Col span={4} className="">
                    <Form.Item 
                        name="precio_unitario"
                        rules={[
                            {
                                min: 1,
                                type: "number", 
                                message: 'Valor no valido'
                            }
                        ]}
                    >
                        <InputNumber
                            bordered={false}
                            placeholder="Precio Unitario"
                            prefix="$"
                            precision={2}
                            min={1}
                        />
                    </Form.Item>
                </Col>
                <Col span={3} className="">
                    <Form.Item name="dias_entrega">
                        <InputNumber
                            className="w100"
                            bordered={false}
                            placeholder="Dias Entrega"
                            min={0}
                        />
                    </Form.Item>
                </Col>
                <Col span={3} className="">
                    <Form.Item name="costo_envio">
                        <InputNumber
                            className="w100"
                            bordered={false}
                            placeholder="Costo Envio"
                            precision={2}
                            min={0}
                        />
                    </Form.Item>
                </Col>
                <Col span={4} className="center ">
                    <Text>$ {data.monto_total.toMoney(true)} MXN</Text>
                </Col>
                <Col span={3} className="">
                    <Form.Item
                        name="archivos"
                        valuePropName="fileList"
                        className="center"
                        getValueFromEvent={e => {
                            if (Array.isArray(e)) {
                                return e;
                            }
                            return e?.fileList;
                        }}
                    >
                        <Uploader
                            onRemove={ e => {
                                axios.put('/requisicion-opcion', {
                                    requisicion_opcion_id: data.requisicion_opcion_id,
                                    archivo: e.uid,
                                })
                            }}
                            updateOne={updateOne}
                        >
                            <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                        </Uploader>
                    </Form.Item>
                </Col>
                <Col span={2} className="center">
                    <Space>
                        <Popconfirm
                            placement="topRight"
                            title="¿Deseas eliminar esta opción?"
                            onConfirm={() => props.delete()}
                            okText="Si"
                            cancelText="No"
                        >
                            <Button 
                                className="center" 
                                size='small' 
                                type="danger" 
                                icon={<DeleteOutlined />} 
                            />
                        </Popconfirm>
                        <Button 
                            className="center" 
                            size='small' 
                            type="primary" 
                            icon={<BsCheckLg />} 
                            onClick={()=>props.aceptarOpcion({requisicion_opcion_id: data.requisicion_opcion_id})}
                        />
                    </Space>

                </Col>
            </Row>
        </Form>
	)

}


export default FormRequisicionOpcion;