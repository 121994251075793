import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography, Collapse } from 'antd'

import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

import { IconDelete, IconEdit, IconDetails, IconPlus, IconPlusCollapsile, IconPlusGroup } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalProducto from './ModalProducto'

import Avatar from "../../Widgets/Avatar/Avatar";
import Logged from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
import ModalProductoProveedor from './ModalProductoProveedor';

const { Content } = Layout;
const { Text } = Typography;
const { Panel } = Collapse;

/**
 * @export
 * @class Productos
 * @extends {Component}
 * @description Vista de Productos
 */
export class Productos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,

            producto_id: undefined,
            productos: {
                data: [],
                limit: 20,
                page: 1,

                total: 0,
                pages: 0,
            },
            searching: true,
            filtroSearch: '',

        }
    }

    componentDidMount() {
        this.getProductos()
    }

    /**
     *
     * @memberof Cuentas
     * @method componentDidUpdate
     * @description Si se realiza una busqueda, filtra las Cuentas
     */
    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search)
            this.getProductos()
    }


    /**
     * @methodOf  Productos
     * @method getProductos
     *
     * @description Obtiene las productos
     * */
    getProductos = ({
        page = this.state.productos.page,
        limit = this.state.productos.limit,
        search = this.props.search

    } = this.state.productos) => {
        this.setState({ loading: true, productos: { ...this.state.productos, page, limit } })
        axios.get('/productos', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            console.log("Productos",data)
            this.setState({
                productos: data,
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }

    renderUrl = (item) => {
        const urlParams = new URL(axios.defaults.baseURL + "/productos/images/" + item._id)
        urlParams.searchParams.set("Authorization", sessionStorage.getItem("token"))
        urlParams.searchParams.set("name", item.imagen?.name)
        return urlParams.href
    }

    render() {

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Productos"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Productos " }}
                            dataSource={this.state.productos.data}
                            pagination={{
                                current: this.state.productos.page,
                                pageSize: this.state.productos.limit,
                                total: this.state.productos.total,
                                position: 'bottom',
                                className: "flex-right",
                                onChange: (page,limit) => this.getProductos({ page, limit })
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-2" >

                                <Col span={1}>
                                    
                                </Col>
                                <Col span={5}>
                                    <Text strong>Producto</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text strong>SKU Grupo manantial</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Fabricante</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Categoría</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Subcategoría</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <>
                                    <Collapse>
                                        <Panel
                                            header={
                                                <Row className='width-100' align='middle'>
                                                    <Col span={1}>
                                                        <Avatar
                                                            color={item}
                                                            {...(item.imagen ? {
                                                                image: this.renderUrl(item)
                                                            } : {
                                                                name: item.nombre
                                                            })}
                                                        />
                                                    </Col>
                                                    <Col span={5}>
                                                        <Text ellipsis>{item?.nombre}</Text>
                                                    </Col>
                                                    <Col span={3} className="center">
                                                        <Text ellipsis>{item?.sku}</Text>
                                                    </Col>
                                                    <Col span={4} className="center">
                                                        <Text ellipsis>{item?.fabricante}</Text>
                                                    </Col>
                                                    <Col span={4} className="center">
                                                        <Text ellipsis>{item?.categoria_id?.nombre}</Text>
                                                    </Col>
                                                    <Col span={4} className="center">
                                                        <Text ellipsis>{item?.sub_categoria_id?.nombre}</Text>
                                                    </Col>
                                                    <Col span={3} className="center">
                                                        <Space size="small" direction="horizontal">
                                                            
                                                                <Link title="Ver detalles" to={`/admin/productos/${item._id}`} >
                                                                    <Button type="secondary" name={item.nombre} id={item._id}
                                                                        title="Ver detalles" icon={<IconDetails />}>
                                                                    </Button>
                                                                </Link>
                                                                
                                                                


                                                            <Button
                                                                type="primary"
                                                                disabled={!this.props.createProductosProveedores}
                                                                icon={<IconPlusGroup />}
                                                                title="Agregar Proveedor"
                                                                onClick={(event) => { event.stopPropagation(); this.setState({ modal_visible_proveedor: true, producto_id: item._id }) }}
                                                            />


                                                            <Button
                                                                type="primary"
                                                                disabled={!this.props.editProductos}
                                                                icon={<IconEdit />}
                                                                title="Editar"
                                                                onClick={(event) => { event.stopPropagation(); this.setState({ modal_visible: true, producto_id: item._id }) }}
                                                            />
                                                            <Popconfirm
                                                                placement="topRight"
                                                                disabled={!this.props.deleteProductos}
                                                                title="¿Deseas eliminar esta área?"
                                                                onConfirm={() => axios.delete('/productos/' + item._id,
                                                                    {}).then(() => {
                                                                        this.getProductos()
                                                                        message.success('Producto eliminada')
                                                                    }).catch(error => {
                                                                        message.error('Error al eliminar el Producto')
                                                                        console.log(error)
                                                                    })}
                                                                okText="Si"
                                                                cancelText="No"
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    disabled={!this.props.deleteProductos}
                                                                    icon={<IconDelete />}
                                                                    title="Eliminar"
                                                                    onClick={event => { event.stopPropagation() }}
                                                                    danger
                                                                />
                                                            </Popconfirm>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            }
                                            key={item._id}
                                            className="mb-1"
                                        >
                                            <List
                                                className='component-list'
                                                itemLayout="horizontal"
                                                locale={{ emptyText: "Sin proveedores" }}
                                                dataSource={item?.proveedores}
                                                
                                                header={
                                                    <Row className="width-100 header-list">
                                                        <Col span={5}>
                                                            <Text strong>Proveedor</Text>
                                                        </Col>
                                                        <Col span={5} className="center">
                                                            <Text strong>SKU</Text>
                                                        </Col>
                                                        <Col span={5} className="center">
                                                            <Text strong>Descripción</Text>
                                                        </Col>
                                                        <Col span={5} className="center">
                                                            <Text strong>Precio</Text>
                                                        </Col>
                                                        <Col span={4} className="center">
                                                            <Text strong></Text>
                                                        </Col>
                                                    </Row>
                                                }
                                                renderItem={proveedor => (
                                                    <List.Item>
                                                        <Row className="width-100">
                                                            <Col span={5}>
                                                                <Text ellipsis>{proveedor?.proveedor_id?.alias}</Text>
                                                            </Col>
                                                            <Col span={5} className="center">
                                                                <Text ellipsis>{proveedor?.sku_proveedor}</Text>
                                                            </Col>
                                                            <Col span={5} className="center">
                                                                <Text ellipsis>{proveedor?.descripcion}</Text>
                                                            </Col>
                                                            <Col span={5} className="center">
                                                                <Text ellipsis>{proveedor?.costo}</Text>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Row align='end' className='mr-1'>
                                                                <Space size="small" align='end' direction="horizontal">
                                                                    <Button

                                                                        type="primary"
                                                                        disabled={!this.props.editProductosProveedores}
                                                                        icon={<IconEdit />}
                                                                        title="Editar"
                                                                        onClick={(event) => { event.stopPropagation(); this.setState({ modal_visible_proveedor: true, producto_id: item._id, proveedor_id: proveedor._id }) }}
                                                                    />
                                                                    <Popconfirm
                                                                        placement="topRight"
                                                                        disabled={!this.props.deleteProductosProveedores}
                                                                        title="¿Deseas eliminar este proveedor?"
                                                                        onConfirm={() => axios.delete('/productos-proveedores/' + proveedor._id,
                                                                            {}).then(() => {
                                                                                this.getProductos()
                                                                                message.success('Proveedor eliminado')
                                                                            }).catch(error => {
                                                                                message.error('Error al eliminar el Proveedor')
                                                                                console.log(error)
                                                                            })}
                                                                        okText="Si"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button

                                                                            type="primary"
                                                                            disabled={!this.props.deleteProductosProveedores}
                                                                            icon={<IconDelete />}
                                                                            title="Eliminar"
                                                                            danger
                                                                        />
                                                                    </Popconfirm>
                                                                </Space>
                                                                </Row>
                                                                
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                )}
                                            />


                                        </Panel>
                                    </Collapse>

                                    {/* <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 " gutter={[16, 16]}>

                                            <Col xs={24} lg={10} className="center">
                                                <Text>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={24} lg={10} className="center">
                                                <Text>{item.descripcion}</Text>
                                            </Col>
                                            <Col xs={24} lg={4} className="center">
                                                <Space size="small" direction="horizontal">
                                                    <Link title="Editar" to={`/admin/productos/rubros/${item._id}`}>
                                                        <Button type="secondary" name={item.nombre} id={item._id}
                                                            title="Ver rubros" icon={<IconDetails />}>
                                                        </Button>
                                                    </Link>


                                                    <Button
                                                        type="primary"
                                                        disabled = {!this.props.editProductos}
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modal_visible: true, producto_id: item._id }) }}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        disabled = {!this.props.deleteProductos}
                                                        title="¿Deseas eliminar esta área?"
                                                        onConfirm={() => axios.delete('/productos',
                                                            { params: { id: item._id } }).then(() => {
                                                                this.getProductos()
                                                                message.success('Área eliminada')
                                                            }).catch(error => {
                                                                message.error('Error al eliminar el Área')
                                                                console.log(error)
                                                            })}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            disabled = {!this.props.deleteProductos}
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item> */}
                                </>
                            )}
                        />

                    </Content>

                    {this.props.createProductos ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} /> : null}


                    <ModalProducto
                        visible={this.state.modal_visible}
                        onClose={() => {
                            this.setState({ modal_visible: false, producto_id: undefined })
                            this.getProductos()
                        }}
                        producto_id={this.state.producto_id}
                    />

                    <ModalProductoProveedor
                        visible={this.state.modal_visible_proveedor}
                        onClose={() => {
                            this.setState({ modal_visible_proveedor: false, producto_id: undefined, proveedor_id: undefined })
                            this.getProductos()
                        }}
                        producto_id={this.state.producto_id}
                        proveedor_id={this.state.proveedor_id}
                    />
                </Spin>
            </>
        )
    }
}

export default function (props) {

    let user = React.useContext(Logged)

        console.log("user?.rol_id?.permisos", user?.rol_id);

    let permissions = usePermissions(user?.rol_id?.permisos, {
        editProductos: ["productos", "edit"],
        createProductos: ["productos", "create"],
        deleteProductos: ["productos", "delete"],


        editProductosProveedores: ["productos","proveedores", "edit"],
        createProductosProveedores: ["productos","proveedores", "create"],
        deleteProductosProveedores: ["productos","proveedores", "delete"],

    })

    return <Productos {...props} {...permissions} navigate={useNavigate()} />

}