import React, { Component } from 'react';
import { Row, Col, Button, Popconfirm, Space, Spin, Table, PageHeader, Layout, message, Typography, Tooltip, Card, List } from 'antd'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BankOutlined } from '@ant-design/icons'

//componentes
import Logged from '../../../Hooks/Logged';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import usePermissions from '../../../Hooks/usePermissions';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { IconDelete, IconEdit, IconBankError, IconMoneyCircle } from '../../Widgets/Iconos';

//modales
import ModalCuentas from './ModalCuentas.js'
import ModalConfiguraciónSyncfy from '../Syncfy/ModalConfiguraciónSyncfy'



const { Content } = Layout;
const { Text } = Typography;
/**
 *
 *
 * @export
 * @class Cuentas
 * @extends {Component}
 * @description Vista de Project Manager
 */
class Cuentas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,
            cuenta_id: undefined,


            cuentas: {
                data: [],
                limit: 20,
                page: 1,

                total: 0,
                pages: 0,

            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCuentas();
    }

    /**
     *
     * @memberof Cuentas
     * @method componentDidUpdate
     * @description Si se realiza una busqueda, filtra las Cuentas
     */
    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search)
            this.getCuentas()
    }

    /**
    * @memberof Cuentas
    * @method getCuentas
    * @description Trae las cuentas 
    * 
    */
    getCuentas = ({
        page = this.state.cuentas.page,
        limit = this.state.cuentas.limit,
        search = this.props.search
    } = this.state.cuentas) => {
        this.setState({ loading: true, cuentas: { ...this.state.cuentas, page, limit } })
        axios.get('/cuentas', {
            params: { page, limit, search }
        }).then(({ data }) => {
            this.setState({ 
                cuentas: { 
                    data: data.data,
                    limit: data.limit,
                    page: data.page,
                    total: data.total,
                    pages: data.pages,
                } 
            })
        }).catch(error => {
            message.error(error.response.data.message)
        }).finally(() => this.setState({ loading: false }))
    }

    render() {
        const { cuentas } = this.state
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Cuentas"
                    />

                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Cuentas" }}
                            dataSource={cuentas.data}
                            pagination={{
                                current: cuentas.page,
                                pageSize: cuentas.limit,
                                total: cuentas.total,
                                showSizeChanger: true,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page, limit) => {
                                    this.getCuentas({ page, limit })
                                }
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >
                                <Col span={2} className="center">
                                    <Text strong>Logo</Text>
                                </Col>

                                <Col span={5} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Saldo</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Titular</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Número Cuenta</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={2} className="center">
                                                <CustomAvatar 
                                                    name={item.nombre} 
                                                    
                                                    color={item.color}
                                                    url={ item.logo ? {
                                                        url: `${axios.defaults.baseURL}/cuentas/${item?._id}`,
                                                        logo: item?.logo?.name
                                                    } : null}
                                                />
                                            </Col>

                                            <Col span={5} className="center">
                                                <Text className="text-gray">{item.nombre}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text className="text-gray">$ {item.saldo?.toMoney(true)} MXN</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text className="text-gray">{item.titular}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text className="text-gray">{item.cuenta}</Text>
                                            </Col>
                                            <Col span={3} align="right">
                                                <Space>

                                                    <Tooltip title={!this.props.enlaceFiscal && (item.syncfy_usuario_id && item.syncfy_credenciales_id) ? "Cuenta con enlace bancario" : "Cuenta sin enlace bancario"}>
                                                        <Button
                                                            disabled={!this.props.enlaceFiscal}
                                                            onClick={() => this.setState({ syncfy_id: item._id, modalSyncfy: true })}
                                                            danger={!(item.syncfy_usuario_id && item.syncfy_credenciales_id)}
                                                            type="primary"
                                                            icon={
                                                                (item.syncfy_usuario_id && item.syncfy_credenciales_id)
                                                                    ?
                                                                    <BankOutlined style={{ fontSize: 22, color: "white", position: "relative", bottom: 2 }} />
                                                                    :
                                                                    <IconBankError color="white" style={{ position: 'relative', bottom: 2 }} />
                                                            }
                                                            title="Eliminar"
                                                            style={{ backgroundColor: (item.syncfy_usuario_id && item.syncfy_credenciales_id) ? "#1890ff" : undefined, border: (item.syncfy_usuario_id && item.syncfy_credenciales_id) ? "none" : undefined }}
                                                        />
                                                    </Tooltip>
                                                    <Button
                                                        disabled={!this.props.editCuentas}
                                                        type="primary"
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modal_visible: true, cuenta_id: item._id }) }}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        disabled={!this.props.deleteCuentas}
                                                        title="¿Deseas eliminar esta Cuenta?"
                                                        onConfirm={() => axios.delete('/cuentas', { id: item._id }).then(() => {
                                                            this.getCuentas()
                                                            message.success('Cuenta eliminada')
                                                        }).catch(error => console.log(error))}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            disabled={!this.props.deleteCuentas}
                                                            danger
                                                            type="primary"
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                        />
                                                    </Popconfirm>


                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>
                    {this.props.createCuentas ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} /> : null}
                </Spin>
                <ModalCuentas
                    visible={this.state.modal_visible}
                    onClose={() => {
                        this.setState({ modal_visible: false, cuenta_id: undefined })
                        this.getCuentas()
                    }}
                    cuenta_id={this.state.cuenta_id}
                />
                <ModalConfiguraciónSyncfy
                    visible={this.state.modalSyncfy}
                    id={this.state.syncfy_id}
                    onCancel={() => {
                        this.setState({ modalSyncfy: false, id: null, cuenta_id: undefined })
                        this.getCuentas();
                    }}
                    tipo={1}
                />
            </>
        )

    }
}

export default function (props) {

    let user = React.useContext(Logged)

    let permissions = usePermissions(user?.rol_id?.permisos, {
        createCuentas: ["cuentas", "create"],
        editCuentas: ["cuentas", "edit"],
        readCuentas: ["cuentas", "read"],
        deleteCuentas: ["cuentas", "delete"],
        enlaceFiscal: ["cuentas", "enlace_bancario"],
    })

    return <Cuentas {...props} navigate={useNavigate()}  {...permissions} />

}