import React from 'react'
import { Route, Routes } from "react-router-dom";

import Dashboard from "../components/Admin/CashFlow/Dashboard"
import Lista from "../components/Admin/CashFlow/Lista"
import Cuentas from "../components/Admin/CashFlow/Cuentas"



/**
 * 
 * @export
 * @function RouterCashFlow
 * @description Router para Transacciones Programdas  
 */
function RouterCashFlow(props) {
    return (
        <Routes>
            <Route path="" element={<Dashboard {...props} />} />
            <Route path="lista" element={<Lista {...props} />} />
            <Route path="cuentas" element={<Cuentas {...props} />} />
        </Routes>
    )
}

export default RouterCashFlow

