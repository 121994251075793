import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select 
const axios = require("axios");

/**
 * @const SelectTransaccionProgramada
 * @description Select para las transacciones programadas, dependiendo del cliente, inversion o acreedor 
 */
const SelectTransaccionProgramada = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione la transacción programada a pagar", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},

        inversion_id,
        acreedor_id,
        cliente_id,
        propiedad_id,
        proveedor_id,

    } = props


    const [ transacciones, setTransacciones ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getInversiones
     * @description Obitiene las transacciones
     */
    const getInversiones = ({page, limit, search} = transacciones) => {
                console.log("proveedor_id", proveedor_id);

        axios.get('/transacciones-programadas', {
            params: {
                page,
                limit,
                search,
                inversion_id,
                cliente_id,
                propiedad_id,
                acreedor_id,
                proveedor_id,
                pendientes: true,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setTransacciones(data)
        }).catch(error => {
            message.error(error?.response?.data?.message + " (transacciones programadas)" ?? 'Error al obtener las transacciones programadas')
        })
    }


    //componentDidUpdate
    useEffect(() => {
    console.log("proveedor_id", proveedor_id);  
        if(inversion_id || acreedor_id || propiedad_id || proveedor_id)
            getInversiones()
    }, [inversion_id, acreedor_id, propiedad_id, proveedor_id])

    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getInversiones({search})}
            onSelect={(transaccion)=> {
                onChange(transaccion.value)
                onSelect(transaccion.value)
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {transacciones?.data?.map(({ _id, concepto, monto_pagado, monto_pendiente, monto, folio }) => <Option value={_id} >
                <div className="flex-between" style={{ justifyContent: "space-between"}}>
                    <span>{concepto ?? ""} <small>{folio}</small></span>
                    <i>{monto_pendiente?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}/{monto?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</i>
                    
                </div>
            </Option>)}
        </Select>
    );
}



export default SelectTransaccionProgramada;