import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography } from 'antd'

import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

import { IconDelete, IconEdit, IconDetails } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import Avatar from "../../Widgets/Avatar/Avatar";
import ModalAlmacenes from './ModalAlmacen'

import Logged from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
import CustomAvatar from '../../Widgets/Avatar/Avatar';

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class Almacenes
 * @extends {Component}
 * @description Vista de Almacenes
 */
export class Almacenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,

            area_id: undefined,
            almacenes: {
                data: [],
                limit: 20,
                page: 1,

                total: 0,
                pages: 0,
            },
            searching: true,
            filtroSearch: '',

        }
    }

    componentDidMount() {
        this.getAlmacenes()
    }


    /**
     *
     *
     * @memberof Almacenes
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate(prevProps) {

        if (this.props.search !== prevProps.search)
        this.getAlmacenes()
    }

    /**
     * @methodOf  Almacenes
     * @method getAlmacenes
     *
     * @description Obtiene las almacenes
     * */
    getAlmacenes = ({
        page = this.state.almacenes.page,
        limit = this.state.almacenes.limit,
        search = this.props.search

    } = this.state.almacenes) => {
        this.setState({ loading: true, almacenes: { ...this.state.almacenes, page, limit } })
        axios.get('/almacenes', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            this.setState({
                almacenes: data,
                loading: false
            });

        }).catch(error => {
            console.log(error)
            this.setState({ loading: false })
            message.error('Error al traer la información')
        })
    }

    renderUrl = (item) => {
        
        const urlParams = new URL(axios.defaults.baseURL + "/proyectos/logo/" + item._id)
        urlParams.searchParams.set("Authorization", sessionStorage.getItem("token"))
        urlParams.searchParams.set("name", item.logo?.name)
        return urlParams.href
    }

    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Almacenes"
                    />
                    <Content className="admin-content content-bg pd-1">
                        
                        <List
                            loading={this.state.almacenes.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Almacenes " }}
                            dataSource={this.state.almacenes.data}
                            pagination={{
                                current: this.state.almacenes.page,
                                pageSize: this.state.almacenes.limit,
                                total: this.state.almacenes.total,
                                showSizeChanger: true,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page, limit) => this.getAlmacenes({ page, limit })
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" gutter={[16, 16]}>

                                <Col xs={24} lg={10}>
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col xs={24} lg={10} className="center">
                                    <Text strong>Proyecto</Text>
                                </Col>
                                <Col xs={24} lg={4} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 " gutter={[16, 16]}>

                                            <Col xs={24} lg={10}>
                                                <Text>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={24} lg={10} className="center">
                                                <Space direction='horizontal' align='center' size='small'  >
                                                    {
                                                        item.proyecto_id && (
                                                            <CustomAvatar
                                                                url={{
                                                                    url:`${axios.defaults.baseURL}/proyectos/logo/${item.proyecto_id?._id}`,
                                                                    name: item.proyecto_id?.logo?.name
                                                                }}
                                                                name={item.proyecto_id?.nombre}
                                                                color={item.proyecto_id?.color}
                                                            />
                                                        )
                                                    }
                                                    <Text className='ml-1'>{item.proyecto_id?.nombre ? item.proyecto_id.nombre : 'No Asignado'}</Text>
                                                </Space>
                                            </Col>
                                            <Col xs={24} lg={4} className="center">
                                                <Space size="small" direction="horizontal">
                                                    <Link title="Editar" to={`/admin/almacenes/${item._id}`}>
                                                        <Button type="secondary" name={item.nombre} id={item._id}
                                                            title="Ver detalles" icon={<IconDetails />}>
                                                        </Button>
                                                    </Link>


                                                    <Button
                                                        type="primary"
                                                        disabled={!this.props.editAlmacenes}
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modal_visible: true, almacen_id: item._id }) }}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        disabled={!this.props.deleteAlmacenes}
                                                        title="¿Deseas eliminar esta área?"
                                                        onConfirm={() => axios.delete('/almacenes',
                                                            { params: { id: item._id } }).then(() => {
                                                                this.getAlmacenes()
                                                                message.success('Área eliminada')
                                                            }).catch(error => {
                                                                message.error('Error al eliminar el Área')
                                                                console.log(error)
                                                            })}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            disabled={!this.props.deleteAlmacenes}
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                     
                        

                    </Content>

                    {this.props.createAlmacenes ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} /> : null}


                    <ModalAlmacenes
                        visible={this.state.modal_visible}
                        onClose={() => {
                            this.setState({ modal_visible: false, almacen_id: undefined })
                            this.getAlmacenes()
                        }}
                        almacen_id={this.state.almacen_id}
                    />
                </Spin>
            </>
        )
    }
}

export default function (props) {

    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {
        editAlmacenes: ["almacenes", "edit"],
        createAlmacenes: ["almacenes", "create"],
        deleteAlmacenes: ["almacenes", "delete"],

    })

    return <Almacenes {...props} {...permissions} navigate={useNavigate()} />

}