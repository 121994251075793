import React, { Component } from "react";
import { Row, Col, Button, Modal, Input, Select, message, List, Space, Typography } from 'antd';
import { RedoOutlined, SearchOutlined } from '@ant-design/icons';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import moment from 'moment'


//Paybook
import SyncWidget from "@paybook/sync-widget";
import "@paybook/sync-widget/dist/widget.css";

const { Text } = Typography;
const axios = require('axios').default;




class ModalRenovarCuentasSyncfy extends Component {


    constructor(props) {
        super(props);
        this.state = {
            cuentas: {
                data: [],
                limit: 20,
                page: 1,

                total: 0,
                pages: 0,
            },
            activo: undefined,
            result: {},
            id_site_organization_type: "56cf4f5b784806cf028b4568"
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.get()
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.result.id !== this.state.result.id){
            this.update() 
        }
    }

    componentWillUnmount(){
        window.syncWidget = undefined
    }

    get = ({
        page = this.state.cuentas.page,
        limit = this.state.cuentas.limit,
        search = this.state.search,
    } = this.state.cuentas, {cuentas} = this.state) => {
        this.setState({cuentas: {...cuentas, page, limit}, activo: undefined, loading: true, search})
        axios.get('/cuentas', {
            params: {
                page,
                limit,
                search,
                con_syncfy: true
            }
        }).then(({data}) => {
            this.setState({
                cuentas: {...cuentas, ...data}
            })
        }).catch(error => {
            message.error("Error al obtener las cuentas")
        }).finally(() => {
            this.setState({loading: false})
        })
    }
    
    /**
     *
     *
     * @param {object} cuenta Objecto con los datos de la cuenta
     * @memberof ModalRenovarCuentasSyncfy
     * @name renovate
     * @description Realiza la renovacion de las credenciales Syncfy de una cuenta
     */
    renovate = async (cuenta) => {
        //syncfy_credenciales_id syncfy_cuenta_bancaria_id syncfy_fecha_actualizacion_saldo
        // console.log('cuenta', cuenta);
        // return  
        this.setState({activo: cuenta._id})

        // Si ya hay un token, cerramos sesion

        try {
            await this.deleteSession()
        } catch (error) {
            console.log('e', error);
        }
        
        // Iniciar Sesion con Id del usuario
        try{
            const { data } = await axios.get('/syncfy/session', { params: { id_user_syncfy: cuenta.syncfy_usuario_id } })
            sessionStorage.setItem('syncfy', data.token)
            axios.defaults.headers.common['syncfy'] = data.token
        }catch(error){
            message.error("Error al iniciar sesión")
            this.update({response: {error}, id: cuenta._id})
            this.setState({activo: undefined})
            return
        }
        // Obtener las credenciales
        // Solicitar la renovacion
        this.renderWidget(cuenta.syncfy_credenciales_id, cuenta._id)
    }

    deleteSession = () => {
        return axios.delete('/syncfy/session')
            .then(({ data }) => {
                sessionStorage.removeItem('syncfy')
            })
            .finally(() => console.log('Sessión Eliminada'))
    }

    update = async ({id, response} = this.state.result) => {
        try{
            await axios.put(`/cuentas`, { 
                id, 
                estatus: response?.success ? 1 : 2,
                renovacion: true
            })
        }catch(error){
            console.log("ERROR: ", error)
            message.error("Error al actualizar las credenciales de la cuenta")
            return
        }finally{
            this.get()
        }
    }

    /**
     *
     *
     * @param {string} credential Credencial de la cuenta Syncfy
     * @param {string} cuenta_id Identificador de la cuenta a renovar
     * @memberof ModalRenovarCuentasSyncfy
     * @name renderWidget
     * @description Renderiza un widget de Syncfy que realiza la actualizacion de credenciales
     */
    renderWidget = (credential, cuenta_id) => {
        if (sessionStorage.getItem('syncfy') && sessionStorage.getItem('syncfy') !== null) {
            if (!window.syncWidget){
                window.syncWidget = new SyncWidget({
                    // Set up the token you created in the Quickstart:	
                    token: sessionStorage.getItem('syncfy'),
                    config: {
                        // Set up the language to use:	
                        locale: 'es',
                        navigation: {
                            displayStatusInToast: true,
                            displaySiteOrganizationTypes: this.state.id_site_organization_type ? [this.state.id_site_organization_type,] : null
                        },
                        entrypoint: {
                            credential: credential ? credential : undefined,
                        }
                    }
                })
            }else {
                window.syncWidget.setConfig({
                    navigation: {
                        displayStatusInToast: true,
                        displaySiteOrganizationTypes: this.state.id_site_organization_type ? [this.state.id_site_organization_type,] : null
                        // displaySiteOrganizationTypes: this.state.id_site_organization_type ? [this.state.id_site_organization_type,] : null
                    },
                    entrypoint: {
                        credential: credential ? credential : undefined,
                    }
                })
                window.syncWidget.setToken(sessionStorage.getItem('syncfy'))
            }

            window.syncWidget.$on("error", e => {
                this.setState({result: {id: cuenta_id, response: {error: e}}})
            });
            window.syncWidget.$on("success", e => {
                this.setState({result: {id: cuenta_id, response: {success: e}}})
            });

            if (!credential){
                window.syncWidget.open()
            }
            return
        }
        return Modal.error({
            title: "Debe seleccionar un Usuario de Syncfy para poder generar las credenciales."
        })
    }


    render() {
        const { cuentas } = this.state
        return (
            <Row gutter={[24, 24]}>
                <Col xs={24}>
                    <Input
                        allowClear
                        prefix={<SearchOutlined />}
                        placeholder="Buscar Cuenta"
                        value={this.state.search}
                        onChange={(e) => this.get({search: e.target.value})}     
                    />
                </Col>
                <Col xs={24}>
                    <List
                        loading={this.state.loading}
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Cuentas" }}
                        dataSource={cuentas.data}
                        pagination={{
                            current: cuentas?.page,
                            pageSize: cuentas?.limit,
                            total: cuentas?.total,
                            position: 'bottom',
                            className: "flex-left",
                            onChange: (page, limit) => {
                                this.getCuentas({ page, limit })
                            }
                        }}
                        renderItem={item => (
                            <List.Item className="">
                                <Row className="width-100 " align={"middle"} gutter={[16, 16]}>
                                    <Col xs={2}>
                                        <CustomAvatar 
                                            name={item.nombre} 
                                            
                                            color={item.color}
                                            url={ item.logo ? {
                                                url: `${axios.defaults.baseURL}/cuentas/${item?._id}`,
                                                logo: item?.logo?.name
                                            } : null}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Space size={2} direction="vertical">
                                            <Text>{item.nombre}</Text>
                                            <Text className="text-gray">{item.cuenta}</Text>
                                        </Space>
                                    </Col>
                                    <Col xs={8}>
                                        <Space size={2} direction="vertical">
                                            <Text className="text-gray">Ultima renovación</Text>
                                            <Text>{moment(item.renovacion?.createdAt || item.createdAt).format('YYYY-MM-DD HH:mm')}</Text>
                                        </Space>
                                    </Col>
                                    <Col xs={2}>
                                        <Button
                                            type="primary"
                                            icon={<RedoOutlined />}
                                            title="Renovar"
                                            disabled={this.state.activo && this.state.activo !== item._id}
                                            loading={this.state.activo === item._id}
                                            onClick={() => this.renovate(item)}
                                        />
                                    </Col>
                                </Row>
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        )
    }
}


export default function (props) {
    const { visible = false, onClose = () => { }, cuenta_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={"Renovar Credenciales de Syncfy"}
        destroyOnClose={true}
        footer={null}
    >
        <ModalRenovarCuentasSyncfy {...props} />
    </Modal>

}