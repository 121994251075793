import React from 'react'
import { Route, Routes } from "react-router-dom";


import Requisiciones from '../components/Admin/Requisiciones/Requisiciones';
import RequisicionesListas from '../components/Admin/Requisiciones/RequisicionesListas';
import RequisicionDetalle from '../components/Admin/Requisiciones/RequisicionDetalle';



/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterRequisiciones(props) {
    return (
        <Routes>
            <Route path="/compras" element={<Requisiciones {...props} />} />
            <Route path=":requisicion_id" element={<RequisicionDetalle {...props} />} />
            <Route path="" element={<RequisicionesListas {...props} />} />
            
        </Routes>
    )
}

export default RouterRequisiciones;
