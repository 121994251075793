import React, { Component } from 'react';
import { Row, Col, Card, Statistic, Typography, PageHeader, Space, Empty, Tabs, Tooltip, Button, message } from 'antd'
import { useParams } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { FloatingMenu, MainButton, ChildButton } from 'react-floating-button-menu';
import { ClockCircleOutlined, CloseOutlined, CopyOutlined, DollarCircleFilled, EyeOutlined, PlusOutlined } from '@ant-design/icons';

//componentes
import Logged from '../../../Hooks/Logged';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import TransaccionesTab from './Secciones/TransaccionesTab';
import { IconArrowBack, IconNewTarget } from '../../Widgets/Iconos';
import TransaccionesProgramadasTab from './Secciones/TransaccionesProgramadasTab';

//modales
import usePermissions from '../../../Hooks/usePermissions';
import ModalTransaccion from '../Finanzas/Transacciones/ModalTransaccion';
import ModalTransaccionProgramada from '../Finanzas/Transacciones/ModalTransaccionProgramada';

//css
import './detallesClientes.css'
import ModalDocumentosInversion from './Modales/ModalDocumentosInversion';

const { TabPane } = Tabs
const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

const { Text, Paragraph, Title } = Typography


class InversionDetalle extends Component {
    volver = () => {
        window.history.back();
    }

    constructor(props) {
        super(props)
        this.state = {
            inversion_id: this.props.params.id,
            inversion: {},
            tab: 'transacciones'
        }
    }

    transaccionesRef = React.createRef()
    programadosRef = React.createRef()

    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.getInversion()
    }

    /**
     * @memberOf InversionDetalle
     * @method getInversion
     * @descripcion Obtiene la información de las inversiones
     * */
    getInversion = (page) => {
        axios.get('/inversiones/get', {
            params: {
                _id: this.state.inversion_id,
                page
            }
        }).then(response => {
            this.setState({
                inversion: response.data.data.inversion,
                valor_estimado_actual: response.data.data.inversion.valor_estimado_actual,
                monto_venta: response.data.data.inversion.monto_venta,
                monto_restante: response.data.data.inversion.monto_restante,
                monto_pagado: response.data.data.inversion.monto_pagado
            })
        }).catch(error => {
            console.log(error)
        })
    }

    /**
     * @memberOf InversionDetalle
     * @method updateAll
     * @descripcion Actualiza la información de las vista
     * */
    updateAll = () => {
        this.getInversion()
        if(this.state.tab === 'transacciones'){
            this.transaccionesRef.current?.get()
        }else{
            this.programadosRef.current?.get()
        }
    }

    /**
     * @memberOf InversionDetalle
     * @method renderUrl
     * @descripcion Retorna el url necesario para visualizar imagenes de las inversiones
     * */
    renderUrl = ({_id, filename, url = 'inversiones'}) => {
        const urlParams = new URL(axios.defaults.baseURL + `/${url}/images/` + _id)
        urlParams.searchParams.set("Authorization", sessionStorage.getItem("token"))
        urlParams.searchParams.set("file", filename)
        return urlParams.href
    }


    render() {
        let usuario = this.context;

        const { inversion } = this.state

        return (
            <div>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title="Inversiones"
                    backIcon={<IconArrowBack />}
                    onBack={this.volver}
                />
                <Content className="admin-content content-bg pd-1">
                    <Row>
                        <Col span={24}>
                            <Card bordered loading={this.state.loading}>
                                <Row gutter={[24, 24]}>
                                <Col xs={24}>
                                    <Title level={4} >Información de la Inversión</Title>
                                    <Title level={5} >{inversion.nombre}</Title>
                                </Col>
                                <Col xs={24}>
                                    <Row gutter={[16, 24]}>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Proyecto</Paragraph>
                                            <Space size={5}>
                                                <CustomAvatar
                                                    name={inversion.proyecto_id?.nombre}
                                                    color={inversion.proyecto_id?.color}
                                                    url={inversion.proyecto_id?._id ? {
                                                        url:`${axios.defaults.baseURL}/proyectos/logo/${inversion.proyecto_id?._id}`,
                                                        name: inversion.proyecto_id?.logo?.name
                                                    } : null}
                                                />
                                                <Text style={{ fontSize: 16 }}>{inversion.proyecto_id?.nombre}</Text>
                                            </Space>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Contact</Paragraph>
                                            <Space size={5}>
                                                <CustomAvatar
                                                    color={inversion.usuario_id?.color}
                                                    {...(inversion.usuario_id?.logo ? {
                                                        image: this.renderUrl({_id: inversion.usuario_id, url: "usuarios"})
                                                    } : {
                                                        name: inversion.usuario_id?.nombre
                                                    })}


                                                // image={}
                                                />
                                                <Text style={{ fontSize: 16 }}>{inversion.usuario_id?.nombre}</Text>
                                            </Space>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <div>
                                                <Space align='start'>
                                                    <Paragraph>Referencia Bancaria</Paragraph>
                                                    <Button
                                                        size="small"
                                                        type="link"
                                                        style={{ padding: 0, fontSize: 16, height: 'auto' }}
                                                        icon={<CopyOutlined />}
                                                        disabled={!inversion?.referencia}
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(inversion.referencia)
                                                            message.success('¡Referencia copiada!')
                                                        }}

                                                    />
                                                </Space>
                                            </div>
                                            <Paragraph strong style={{ fontSize: 16 }}>{inversion?.referencia}</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Valor Estimado Actual</Paragraph>
                                            <Statistic
                                                    valueStyle={{ fontSize: 16 }}
                                                    value={this.state.valor_estimado_actual}
                                                    precision={2}
                                                    prefix={'$'} suffix={"MXN"} />
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Monto de Venta</Paragraph>
                                            <Statistic
                                                    valueStyle={{ color: '#0047ff', fontSize: 16 }}
                                                    value={this.state.monto_venta}
                                                    precision={2}
                                                    prefix={'$'} suffix={"MXN"} />
                                        </Col>

                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Monto Actual Pagado</Paragraph>
                                            <Statistic
                                                    valueStyle={{ color: '#00FF19', fontSize: 16 }}
                                                    value={this.state.monto_pagado}
                                                    precision={2}
                                                    prefix={'$'} suffix={"MXN"} />
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Monto por Pagar</Paragraph>
                                            <Statistic
                                                    valueStyle={{ color: '#FF0000', fontSize: 16 }}
                                                    value={this.state.monto_restante}
                                                    precision={2}
                                                    prefix={'$'} suffix={"MXN"} />
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Space size={5} align='start'>
                                                <Paragraph>Documentos</Paragraph>
                                                <Button type="link" size="small" icon={<EyeOutlined />} onClick={() => this.setState({modalDocumentosInversion: true})}/>
                                            </Space>
                                            {(Array.isArray(inversion.documentos)) ? inversion.documentos.map(item => {
                                                    return <a target="_blank" className="link-document"
                                                        style={{ display: "block" }} download
                                                        href={this.renderUrl({_id: this.state.inversion?._id, filename: item.file})}>
                                                        {item.name}
                                                    </a>
                                                }) : null}
                                        </Col>
                                    </Row>
                                </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-1">
                        <Col span={24}>
                            {this.props.verTransacciones || this.props.verTransaccionesProgramadas ? <Tabs className="tabs-proyectos tabs-crm-client"
                                onChange={tab => this.setState({ tab })}
                                destroyInactiveTabPane
                                activeKey={this.state.tab}
                            >
                                {this.props.verTransacciones ? <TabPane tab="Transacciones" key="transacciones">
                                    <TransaccionesTab
                                        key="transacciones"
                                        ref={this.transaccionesRef}
                                        inversion_id={this.state.inversion_id}
                                        updateAll={() => {
                                            this.updateAll()
                                        }}
                                    />
                                </TabPane> : null}
                                {this.props.verTransaccionesProgramadas ? <TabPane tab="Transacciones Programadas" key="transacciones-programadas">
                                    <TransaccionesProgramadasTab
                                        key="transacciones-programadas"
                                        ref={this.programadosRef}
                                        inversion_id={this.state.inversion_id}
                                        updateAll={() => {
                                            this.updateAll()
                                        }}
                                    />
                                </TabPane> : null
                                }
                            </Tabs> : <Empty className='mt-1' description="No tiene permisos para ver transacciones o transacciones programadas" />}
                        </Col>
                    </Row>
                </Content>
                <FloatingMenu
                    slideSpeed={500}
                    direction="left"
                    spacing={8}
                    isOpen={this.state.isOpen}
                    style={{

                        position: "fixed",
                        bottom: "1em",
                        right: "1.2em"

                    }}
                >
                    <MainButton
                        iconResting={<PlusOutlined style={{ fontSize: 30, color: "white" }} />}
                        iconActive={<CloseOutlined style={{ fontSize: 30, color: "white" }} />}
                        style={{ backgroundColor: "#0047FF" }}
                        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                        size={70}
                        direction="right"
                    />
                    <ChildButton
                        icon={
                            <Tooltip mouseLeaveDelay={0} title="Agregar una Transacción Programada" placement="top">
                                <div style={{ width: "100%", height: "100%", textAlign: "center", position: "relative", top: 5 }}>
                                    <ClockCircleOutlined style={{ fontSize: 40, color: "white" }} nativeColor="black" />
                                </div>
                            </Tooltip>
                        }
                        background={this.props.crearTransaccionesProgramadas ? "#0047FF" : "#0047FF80"}
                        size={50}
                        onClick={this.props.crearTransaccionesProgramadas ? () => this.setState({ modalTransaccionProgramadaVisible: true, isOpen: false }) : undefined}
                    />
                    <ChildButton
                        icon={
                            <Tooltip mouseLeaveDelay={0} title="Agregar una Transacción" placement="top">
                                <DollarCircleFilled style={{ fontSize: 50, color: this.props.crearTransacciones || true ? "#0047FF" : "#0047FF80" }} nativeColor="black" />
                            </Tooltip>
                        }
                        
                        background="white"
                        size={50}
                        onClick={this.props.crearTransacciones || true ? () => this.setState({ modalTransaccionVisible: true, tipo_pago: undefined, isOpen: false }) : undefined}
                    />
                </FloatingMenu>
                <ModalTransaccion
                    visible={this.state.modalTransaccionVisible}
                    onClose={() => {
                        this.setState({modalTransaccionVisible: false})
                        this.updateAll()
                    }}
                    inversion_id={this.state.inversion_id}
                    tipo_pago={this.state.tipo_pago}
                    initialValues={{
                        tipo: 1,
                        inversion_id: this.state.inversion_id
                    }}
                    disabled={{
						cliente: true,
						propiedad: true,
						cuenta: ((this.state.tipo_pago === 2) ? false : true),
						acreedor: true
					}}
                    required={{
                        cuenta: ((this.state.tipo_pago === 2) ? true : false)
                    }}
                />
                <ModalTransaccionProgramada
                    visible={this.state.modalTransaccionProgramadaVisible}
                    onClose={() => {
                        this.setState({modalTransaccionProgramadaVisible: false})
                        this.updateAll()
                    }}
                    inversion_id={this.state.inversion_id}
                    area={true}
                    rubro={true}
                />
                <ModalDocumentosInversion
                    visible={this.state.modalDocumentosInversion}
                    onClose={() => {
                        this.setState({ modalDocumentosInversion: false })
                        this.getInversion()
                    }}
                    inversion_id={this.state.inversion_id}
                />
            </div>
        )
    }
}
InversionDetalle.contextType = Logged;

export default function (props) {
    let user = React.useContext(Logged);

    const params = useParams();

    let permissions = usePermissions(user?.rol_id?.permisos, {

        crearTransacciones: ["inversiones", "transacciones", "create"],
        verTransacciones: ["inversiones", "transacciones", "read"],

        crearTransaccionesProgramadas: ["inversiones", "transacciones-programadas", "create"],
        verTransaccionesProgramadas: ["inversiones", "transacciones-programadas", "read"]

    })

    return <InversionDetalle {...props} {...permissions} params={params} />
}
