import React from 'react'
import { Route, Routes } from "react-router-dom";
import { Layout } from 'antd';

// Components
import ThemeEdit from '../components/ThemeEdit/index';
import Login from '../components/Auth/Login'
import Recovery from '../components/Auth/Recovery';
import Landing from '../components/Landing/Landing';
import UpdatePassword from '../components/Auth/UpdatePassword'


/**
 * 
 * @export
 * @function PublicRoutes
 * @description Router for handling all public routes
 */
function PublicRoutes(props) {

  return (
    <Layout>
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='login/:back?' element={<Login {...props}/>} />
        <Route path='password/recovery' element={<Recovery />} />
        <Route path='recovery/:token' element={<UpdatePassword />} />
        <Route path='register/:token' element={<UpdatePassword />} />
        <Route path='theme' element={<ThemeEdit />} />
      </Routes>
    </Layout>
  )
}

export default PublicRoutes;
