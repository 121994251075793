import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography, Tag } from 'antd'

import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

import {FilterOutlined} from '@ant-design/icons'
import { IconDelete, IconEdit, IconDetails } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import DrawerFiltros from "../../Widgets/DrawerFiltros/DrawerFiltros";

import Logged from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import ModalInventario from './ModalInventario';

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class Areas
 * @extends {Component}
 * @description Vista de Areas
 */
export class Inventario extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,

            categoria_id: undefined,
            bitacora: {
                data: [],
                limit: 20,
                page: 1,

                total: 0,
                pages: 0,
            },
            searching: true,
            filtroSearch: '',

        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        // this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.get()
    }


    /**
     *
     *
     * @memberof Areas
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate(prevProps) {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;
        if (filtro !== propFiltro && this.state.searching == true) {
            this.get();
            this.state.searching = false;
        }

        if (this.props.search !== prevProps.search)
            this.get()
    }

    /**
     * @methodOf  Inventario
     * @method get
     *
     * @description Obtiene el bitacora
     * */
    get = ({
        page = this.state.bitacora.page,
        limit = this.state.bitacora.limit,
        search = this.props.search,
        filters = this.state.filters
    } = this.state.bitacora) => {
        this.setState({ loading: true, bitacora: { ...this.state.bitacora, page, limit, filters } })
        axios.get('/almacenes-bitacoras/list', {
            params: {
                page,
                limit,
                search,
                filters
            }
        }).then(({ data }) => {
            this.setState({
                bitacora: data,
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }


    render() {

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Inventario"
                        extra={
                            <Button
								type="dashed"
								onClick={() => this.setState({ visibleFilters: true })}
								className="button-plus"
								icon={<FilterOutlined />} />
                        }
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.bitacora.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Inventario" }}
                            dataSource={this.state.bitacora.data}
                            pagination={{
                                current: this.state.bitacora.page,
                                pageSize: this.state.bitacora.limit,
                                total: this.state.bitacora.total,
                                position: 'bottom',
                                className: "flex-left",
                                showSizeChanger: true,
                                onChange: (page, limit) => this.get({ page, limit })
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" gutter={[16, 16]}>

                                <Col span={6} className="center">
                                    <Text strong>Producto</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text strong>SKUGM</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text strong>Categoria</Text>
                                </Col>
                                <Col span={2} className="center">
                                    <Text strong>Proyecto</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Almacen</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text strong>Volumen</Text>  
                                </Col>
                                <Col span={3} className="center">
                                    <Text strong>Unidad</Text>
                                </Col>
                                
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 " gutter={[16, 16]}>
                                            <Col xs={8} lg={2} className="center">
                                                <CustomAvatar 
                                                    image={ item.producto_id?.imagen ? axios.defaults.baseURL + "/productos/images/" + item.producto_id?._id + "?Authorization=" + sessionStorage.getItem("token") : null} 
                                                    name={item.nombre}  
                                                /> 
                                            </Col>
                                            <Col xs={8} lg={4} className="center">
                                                <Text>{item.producto_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={8} lg={3} className="center">
                                                <Text>{item.producto_id?.sku_manantial || '--'}</Text>
                                            </Col>
                                            <Col xs={8} lg={3} className="center">
                                                <Text>{item.categoria_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={8} lg={2} className="center">
                                                <CustomAvatar 
                                                    image={ item.proyecto_id?.logo ? axios.defaults.baseURL + "/proyectos/logo/" + item.proyecto_id?._id + "?Authorization=" + sessionStorage.getItem("token") : null} 
                                                    name={item.proyecto_id?.nombre} 
                                                    color={item.color} 
                                                /> 
                                            </Col>
                                            <Col xs={8} lg={4} className="center">
                                                <Text>{item.almacen_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={8} lg={3} className="center">
                                                <Tag color={item.tipo === 1 ? '#008b0e' : '#b80000'} style={{fontWeight: 700}}>{item.tipo === 1 ? '+' : '-'}{item.volumen}</Tag>  
                                            </Col>
                                            <Col xs={8} lg={3} className="center">
                                                <Text>{item.producto_id?.unidad?.nombre} ({item.producto_id?.unidad?.unidad})</Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>

                    <FloatingButton title="Nuevo movimiento" onClick={() => this.setState({ modal_visible: true })} />


                    <ModalInventario
                        visible={this.state.modal_visible}
                        onClose={() => {
                            this.setState({ modal_visible: false })
                            this.get()
                        }}
                    />
                    <DrawerFiltros
                        title="Filtrar Movimientos"
                        ref={ref => this.drawerFiltros = ref}
                        updateFilters={filters => this.get({
                            page: 1,
                            filters
                        })}
                        onClose={() => this.setState({ visibleFilters: false })}
                        visible={this.state.visibleFilters}
                        ordenes_compras={false}
                        ordenes={false}
                        proveedores={false}
                        accredores={false}
                        inversiones={false}
                        fechas={false}
                        cuentas={false}
                        area_rubro={false}
                        empresas={false}
                        clientes={false}
                        acreedores={false}
                        proyectos_inmueble={false}
                    />
                </Spin>
            </>
        )
    }
}

export default function Vista(props) {

    /* let user = React.useContext(Logged)

    
	let permissions = usePermissions(user?.rol_id?.permisos, {
		editAreas: ["bitacora-rubros", "edit"],
		createAreas: ["bitacora-rubros", "create"],
		deleteAreas: ["bitacora-rubros", "delete"],

	}) */

    return <Inventario {...props}  navigate={useNavigate()} />

}