import React, { Component } from "react";
import { Row, Col, Modal, Form, message, Spin, Typography } from 'antd';
import moment from "moment";

import { PaperClipOutlined} from "@ant-design/icons"

import "../../../../Styles/Modules/Usuarios/ModalUsuarios.scss";

const axios = require('axios').default;
const {Text} = Typography



/**
 * @class ModalVacacionesDetalles
 * @description Modal para los detalles de Vacaciones
 */
class ModalVacacionesDetalles extends Component {

    constructor(props) {
        super(props)
        this.state = {
            vacaciones: {},
            loading: false,
            mostrarProyectos: false
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')


        if (this.props.vacaciones_id) {
            this.get()
        }
    }




    formRef = React.createRef();

    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/vacaciones/' + this.props.vacaciones_id)
            .then(({ data }) => {
                let datos = data
                datos.fecha_inicio = datos.fecha_inicio ? moment(datos.fecha_inicio) : null
                datos.fecha_fin = datos.fecha_fin ? moment(datos.fecha_fin) : null
                this.setState({vacaciones: datos})
            }).catch(error => {
                console.log(error)
                message.error('Error al obtener la solicitud')
            }).finally(() => this.setState({ loading: false }))
    }



    render() {

       const { vacaciones } = this.state

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-empleado"
                    layout={"vertical"}
                >
                    <Row justify="center" className="center" gutter={[16, 16]}>
                        <Col md={24} xl={12}>
                            <Form.Item
                                label="Fecha de Inicio"
                            >
                                <Text type="secondary" >{vacaciones?.fecha_inicio?.format("DD/MM/YYYY")}</Text>
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                label="Fecha de Fin"
                            >
                                <Text type="secondary" >{vacaciones?.fecha_fin?.format("DD/MM/YYYY")}</Text>
                            </Form.Item>
                        </Col>
                        <Col md={24}>
                            <Form.Item
                                label="Comprobantes"
                            >
                                 {vacaciones.comprobantes?.map((comprobante, index) => {
                                    return <><a target="_blank" icon={<PaperClipOutlined />} 
                                        href={axios.defaults.baseURL + "/vacaciones/" + vacaciones?._id + "?comprobante=" + comprobante.file +"&Authorization=" + sessionStorage.getItem("token")}
                                    >{comprobante.name}</a><br/></>
                                })}
                            </Form.Item>
                        </Col>
                       
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Vista(props) {

    const { visible = false, onClose = () => { }, vacaciones_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={"Detalles de la Solicitud"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        footer={null}
    >
        <ModalVacacionesDetalles  {...props} />
    </Modal>

}