import React, { Component, createRef } from "react";
import { Avatar, Button, Col, DatePicker, Form, Input, InputNumber, List, message, Modal, Row, Select, Spin, Statistic, Typography, Upload, Checkbox, Tooltip } from 'antd';
import { UploadOutlined, QuestionCircleOutlined, FilePdfFilled } from "@ant-design/icons";
import { Redirect, Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from 'moment'
import 'moment/locale/es';


//componentes
// import { IconArrowSelect, IconCloseModal, IconTemplate } from '../../../Widgets/Iconos';
// import CustomAvatar from "../../../Widgets/Avatar/Avatar";
// import { MyUpload } from './../../../Widgets/Upload';
// import './../../../../Styles/finanzas.css'

const axios = require('axios').default;
moment.locale('es');
const { Option } = Select;
const { Title, } = Typography;

class ModalTran extends Component {

    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            updateChecker: false
        }
    }

    //Referencia de form dentro del modal.
    modalTransaccion = createRef();

    /**
     * @memberof ModalTran
     * @method componentDidMount
     * @description Se ejecuta al finalizar el render de la vista.
     */
    componentDidMount() { axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token'); }


    render() {
        const { visible, } = this.props;
        // const { modalTransaccion, normFile } = this;

        return (

            <Spin spinning={this.state.spinning}>
                <Form
                    layout="vertical"
                    ref={this.modalTransaccion}
                    className="frm-transacciones"
                    name="formulario-transacciones"
                >
                    <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                        <List
                            style={{ justifyContent: "center" }}
                            align="center"
                            size="small"
                            header={<div>Toma en cuenta las siguientes consideraciones.</div>}
                            footer={<div>Descarga el template CSV para subir múltiples transacciones.</div>}
                            dataSource={[
                                <div style={{ justifyContent: 'center', width: '100%' }}>- El formato de fecha en dd/mm/aaaa</div>,
                                <div style={{ justifyContent: 'center', width: '100%' }}>- Disminuir el uso de acentos, o evitarlo para prevenir una mala lectura</div>,
                                <div style={{ justifyContent: 'center', width: '100%' }}>- Eliminar el formato de moneda ($123)</div>,
                                <div style={{ justifyContent: 'center', width: '100%' }}>- Asegurarse que el dato esté escrito correctamente  igual que en el sistema (incluyendo mayúsculas y acentos)</div>]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />

                    </Row>
                    <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                        <a href={`${axios.defaults.baseURL}/files/template.csv`} download>
                            <FilePdfFilled style={{ color: "red", fontSize: 24 }} />
                            Descargar Archivo
                        </a>
                    </Row>
                    <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                        <label> Una vez que hayas guardado tu archivo CSV con las transacciones, súbelo aqui. </label>
                    </Row>
                    <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                        <Col span={24} className="colDocsComprobantes">
                            <Form.Item
                                name="actualizacion"
                                initialValue={false}
                            >
                                <Checkbox onChange={(e) => {
                                    this.modalTransaccion.current.setFieldsValue({ actualizacion: e.target.checked })
                                }}>
                                    Actualizar saldos
                                    <Tooltip title="Habilita la opcion para actualizar los saldos de las cuentas con los ingresos o egresos incluidos en el archivo CSV.">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center", margin: "20px 0px", padding: '50px 0px' }}>
                        <Col span={24} className="colDocsComprobantes">
                            <Form.Item
                                label=""
                                name="docs"
                            >
                                <Upload
                                    accept={"csv"}
                                    data={() => {
                                        return {
                                            actualizar: this.modalTransaccion.current.getFieldValue('actualizacion')
                                        }
                                    }}
                                    headers={{ Authorization: axios.defaults.headers.common['Authorization'] }}
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/transacciones/csv"}
                                    onChange={
                                        (info) => {
                                            const { status } = info.file;
                                            console.log('status', status)
                                            if (status === 'done') {
                                                Modal.success({
                                                    title: "Hemos subido el archivo. Revisa los resultados obtenidos en la descarga de resultados."
                                                })
                                                const url = window.URL.createObjectURL(new Blob([info.file.response]));
                                                const link = document.createElement('a');
                                                link.href = url;
                                                link.setAttribute('download', 'resultados.csv'); // Nombre del archivo
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                            } else if (status === 'error') {
                                                message.error(`${info.file.name} No se pudo subir el archivo.`);
                                            }
                                        }
                                    }

                                >
                                    <Button>
                                        <UploadOutlined /> Cargar
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Spin>
        )
    }
}


export default function (props) {

    const { visible, hideModal } = props

    return <Modal
        visible={visible}
        onCancel={hideModal}
        title={"Subir Multiples Transacciones"}
        footer={null}
        destroyOnClose={true}
        zIndex={1000}
    >
        <ModalTran {...props} />
    </Modal>
}



