import React, { Component } from "react";
import { Row, Col, Space, Modal, List, Typography, Form, DatePicker, Input, InputNumber, Select, message, Spin, AutoComplete, Divider, Button, Checkbox } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';

//componentes
import { Uploader } from "../../../Widgets/Uploaders";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import BudgetingInputs from "../../../Widgets/Inputs/BudgetingInputs";
import {
    SelectInversion, SelectTransaccionProgramada, SelectAcreedor, SelectPropiedad, SelectCliente, SelectArea,
    SelectProveedor, SelectRubro, SelectCuenta, SelectEmpresa, SelectProyecto
} from "../../../Widgets/Inputs/Selects"
import Paragraph from "antd/lib/skeleton/Paragraph";

// Modals
import ModalProveedores from "../../Proveedores/ModalProveedores";

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');

const Decimal = require('decimal.js');

const getDefaultProps = () => ({
    disabled: {},
    required: {
        concepto: true,
        fecha: true,
        cuenta: true,
        tipo: true,
        monto: true,
        empresa: true,
        proyecto: true
    },
    initialValues: {
        fecha: moment()
    }
})

class ModalTransacciones extends Component {


    static defaultProps = {
        shown: {
            monto: true,
            fecha: true
        },
        initialValues: {
            fecha: moment()
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            facturas: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },
            filters: {},
            cuentaSeleccionada: '',
            transaccion: {},
            cliente_id: undefined,
            disabled_campos: null,
            agregar: this.props.agregar,
            required: {
                concepto: true,
                fecha: true,
                cuenta: true,
                tipo: true,
                monto: true,
                empresa: true,
                proyecto: true,
                ...this.props.required
            },
            disabled: { ...this.props.disabled },

            initialValues: this.props.initialValues,
            modals: {},


            seleccionadas: Object.values(this.props.seleccionadas || {}),
            

        }
    }

    modalRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


        if (this.props.initialValues) {
            this.modalRef.current?.setFieldsValue({
                ...this.props.initialValues,
                fecha: moment()
            })
        }

        if (this.props.transaccion_id !== undefined || this.props.transaccion_id != null) {
            this.getTransaccion();
        }

        this.setState({
            inversion_id: this.props.inversion_id ?? undefined,
            acreedor_id: this.props.acreedor_id ?? undefined,
            propiedad_id: this.props.propiedad_id ?? undefined,
            proveedor_id: this.props.proveedor_id ?? undefined,
            cuenta_id: this.props.cuenta_id ?? undefined,
        })

    }


    /**
     * @memberof ModalTransacciones
     * @method getTransaccion
     * @description 
     * 
     */
    getTransaccion = () => {
        this.setState({ loading: true })

        axios.get(`/transacciones/${this.props.transaccion_id}`, {
            params: {
                id: this.props.transaccion_id
            }
        })
            .then(({ data }) => {
                this.setState({
                    transaccion: data,
                    area_id: data.area_id?._id,
                    cliente_id: data.cliente_id?._id,
                    disabled_campos: data.propiedad_id || data.inversion_id || data.acreedor_id || data.proveedor_id || data.transaccion_programada_id
                })

                this.modalRef.current?.setFieldsValue({
                    ...data,
                    fecha: moment(data.fecha),
                    area_id: data?.area_id?.nombre ? {
                        value: data?.area_id?._id,
                        label: data?.area_id?.nombre
                    } : null,
                    rubro_id: data?.rubro_id?.nombre ? {
                        value: data?.rubro_id?._id,
                        label: data?.rubro_id?.nombre
                    } : null,
                    cliente_id: data?.cliente_id?.nombre ? {
                        value: data?.cliente_id?._id,
                        label: data?.cliente_id?.nombre
                    } : null,
                    propiedad_id: data?.propiedad_id?.nombre ? {
                        value: data?.propiedad_id?._id,
                        label: data?.propiedad_id?.nombre
                    } : null,
                    cuenta_id: data?.cuenta_id?.nombre ? {
                        value: data?.cuenta_id?._id,
                        label: data?.cuenta_id?.nombre
                    } : null,
                    empresa_id: data?.empresa_id?.nombre ? {
                        value: data?.empresa_id?._id,
                        label: data?.empresa_id?.nombre
                    } : null,
                    proveedor_id: data?.proveedor_id?.alias ? {
                        value: data?.proveedor_id?._id,
                        label: data?.proveedor_id?.alias
                    } : null,
                    inversion_id: data?.inversion_id?.nombre ? {
                        value: data?.inversion_id?._id,
                        label: data?.inversion_id?.nombre
                    } : null,
                    acreedor_id: data?.acreedor_id?.acreedor ? {
                        value: data?.acreedor_id?._id,
                        label: data?.acreedor_id?.acreedor
                    } : null,

                    proyecto_id: data?.proyecto_id?.nombre ? {
                        value: data?.proyecto_id?._id,
                        label: data?.proyecto_id?.nombre
                    } : null,

                    empresa_id: data?.empresa_id?.razon_social ? {
                        value: data?.empresa_id?._id,
                        label: data?.empresa_id?.razon_social
                    } : null,
                    // transaccion_programada_id: data?.transaccion_programada_id?._id ? {
                    //     value: data?.transaccion_programada_id?._id,
                    //     label: data?.transaccion_programada_id?.concepto
                    // } : null,
                    conceptos: data.conceptos.length > 0 ? data.conceptos.map(e => {
                        return {
                            proyecto_id: {
                                value: e?.proyecto_id?._id,
                                label: e?.proyecto_id?.nombre
                            },
                            concepto_id: {
                                value: e?.concepto_id?._id,
                                label: e?.concepto_id?.nombre
                            },
                            subconcepto_id: {
                                value: e?.subconcepto_id?._id,
                                label: e?.subconcepto_id?.nombre
                            },
                            monto: e.monto
                        }
                    }) : [],
                    comprobantes: data.comprobantes.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/transacciones/" + this.props.transaccion_id + "?comprobante=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })),

                })
                this.setState({ loading: false })
            })
            .catch(error => {

                console.log('error al traer transaccon', error)
                this.setState({ loading: false })

            }).finally(() => {

                this.setState({ loading: false })
            })
    }




    /**
    * @memberof ModalTransacciones
    * @method getCuentas
    * @description Trae las facturas 
    * 
    */
    getFacturas = ({

        page = this.state.facturas.page,
        limit = this.state.facturas.limit,
        search = this.state.facturas.search,

    } = this.state.facturas) => {
        this.setState({ facturas: { ...this.state.facturas, loading: true, page, limit, search } })
        axios.get('/facturas', {
            params: {
                page,
                limit,
                search
            }
        })
            .then(({ data }) => {
                this.setState(state => {
                    state.facturas.data = data.data;
                    state.facturas.total = data.total;
                    state.facturas.pages = data.pages;
                    return state;
                })
            })
            .catch(error => {
                console.log(error)
                message.error('No es posible traer las facturas')
            })
    }



    /**
     * @memberof ModalTransacciones
     * @method clasificarTransacciones
     * @description Envia las transacciones a clasificar. Las clasifica. 
     * 
     */
    clasificarTransacciones = (values) => {
        this.setState({ loading: true })
        axios.post('/syncfy/transacciones/add', {
            ...values,
            cuenta_id: this.props.cuenta_id,
            transacciones: this.state.seleccionadas
        }).then(response => {
            message.success('Transacciones clasificadas')
            this.props.onClose()
            this.props.clearSeleccionadas()
        }).catch(error => {
            console.log(error)
            message.error('Error al clasificar transacciones')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalTransacciones
    * @method addTransaccion
    * @description Trae las cuentas 
    * 
    */
    addTransaccion = (values) => {
        this.setState({ loading: true })
        axios.post('/transacciones', values)
            .then(response => {
                message.success('Transacción creada')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error(error?.response?.data?.message ?? 'Error al crear la transacción')
                this.setState({ loading: false })
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalTransacciones
    * @method updateTransaccion
    * @description Trae las cuentas 
    * 
    */
    updateTransaccion = (values) => {
        this.setState({ loading: true })
        axios.put('/transacciones', values)
            .then(response => {
                message.success('Transacción Actualizada')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error('Error al guardar la información')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalTransacciones
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        if (this.props.tipo_pago)
            values.tipo_pago = this.props.tipo_pago

        for (const key of Object.keys(values)) {
            if (values[key]?.value)
                values[key] = values[key]?.value

        }

        const formData = new FormData()
        formData.appendMultiple({
            inversion_id: this.props?.inversion_id,
            propiedad_id: this.props?.propiedad_id,
            acreedor_id: this.props?.acreedor_id,
            cliente_id: this.props?.cliente_id,
            id: this.props.transaccion_id,
            ...values,
            comprobantes: undefined,
            conceptos: undefined
        })

        if (Array.isArray(values.conceptos) && values.conceptos.length > 0)
            values.conceptos.forEach(e => (

                formData.append("conceptos", JSON.stringify({
                    proyecto_id: e.proyecto_id.value,
                    concepto_id: e.concepto_id.value,
                    subconcepto_id: e.subconcepto_id.value,
                    monto: e.monto
                }))
            ))

        values.comprobantes?.forEach(e => {
            if (!e.fromDB)
                formData.append("comprobantes", e, e.name)
        })

        if (this.props.clasificacion) {

            this.clasificarTransacciones(values)

        } else
            if (this.props.transaccion_id)
                this.updateTransaccion(formData)
            else
                this.addTransaccion(formData)
    }

    render() {

        const {
            agregar,
            disabled,
            required,
            transaccion,
            seleccionadas,
        } = this.state;

        const {
            transaccion_id,
        } = this.props;


        return (
            <>
                <Form
                    layout="vertical"
                    name="form-transaccion"
                    id="form-transaccion"
                    ref={this.modalRef}
                    onFinish={this.onFinish}
                    initialValues={{
                        ...this.state.initialValues,
                        fecha: moment()
                    }}

                >
                    <Spin spinning={this.state.loading}>

                        {this.props.clasificacion && <>
                            <Divider className="modal-divider">Transacciones a clasificar</Divider>
                            <Row>
                                <List
                                    className="width-100"
                                    itemLayout="horizontal"
                                    dataSource={seleccionadas || []}
                                    renderItem={(item, index) => (
                                        <List.Item className="width-100" style={{ display: "block" }}>
                                            <Row style={{ width: "100%", gap: 20 }}>
                                                <Form.Item label="Concepto" style={{ flex: 1, marginBottom: '10px' }}>
                                                    <Input
                                                        value={item.description}
                                                        style={{ width: "100%", }}
                                                        onChange={e => {
                                                            this.setState(state => {
                                                                state.seleccionadas[index].description = e.target.value
                                                                return state
                                                            })
                                                        }}
                                                    />
                                                </Form.Item>
                                                <div style={{ textAlign: "right", marginTop: '20px' }}>
                                                    <strong>{moment.unix(item.dt_transaction).format("YYYY-MM-DD")}</strong>
                                                    {(item.currency === "MXN") ? <>
                                                        <p>{Decimal(item.amount).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> MXN</strong></small></p>
                                                    </> : <>
                                                        <p>{Decimal(item.amount).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> USD</strong></small></p>
                                                    </>}
                                                </div>
                                            </Row>
                                            <Row style={{ width: "100%" }}>
                                                <Form.Item label="Descripcion" style={{ width: "100%" }}>
                                                    <Input.TextArea
                                                        value={item.reference}
                                                        onChange={e => {
                                                            this.setState(state => {
                                                                state.seleccionadas[index].reference = e.target.value
                                                                return state
                                                            })
                                                        }}
                                                        // onChange={e => {
                                                        //     console.log('e', e.target.value);
                                                        // }}
                                                        rows={2}
                                                    />
                                                </Form.Item>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Row>
                            <Divider className="modal-divider">Información de las Transacciones</Divider>
                        </>}
                        <Row gutter={[16, 0]}>
                            {!this.props.clasificacion && <Col xs={24} lg={this.props.shown?.fecha ? 12 : 24}>
                                <Form.Item
                                    label="Concepto"
                                    name="concepto"
                                    rules={[{
                                        required: required?.concepto,
                                        message: "Por favor, ingrese el concepto"
                                    }]}
                                    extra={"Si no hay un concepto, se indicará la descripción del banco"}
                                >
                                    <Input disabled={disabled?.concepto} placeholder="Concepto"></Input>
                                </Form.Item>
                            </Col>}
                            {
                                this.props.shown?.fecha && <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Fecha"
                                        name="fecha"
                                        rules={[{
                                            required: required?.fecha,
                                            message: "Por favor, ingrese la fecha"
                                        }]}
                                    >
                                        <DatePicker disabled={disabled?.fecha} className="width-100" />
                                    </Form.Item>
                                </Col>
                            }
                            {!this.props.clasificacion && <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Descripción"
                                    name="descripcion"
                                    rules={[{
                                        required: required?.descripcion,
                                        message: "Por favor, ingrese la descripción"
                                    }]}
                                >
                                    <Input.TextArea disabled={disabled?.descripcion} placeholder="Descripción" />
                                </Form.Item>
                            </Col>}

                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Área"
                                    name="area_id"
                                    rules={[{
                                        required: required?.area,
                                        message: "Por favor, seleccione el area"
                                    }]}
                                >
                                    <SelectArea
                                        disabled={(disabled?.area)}
                                        onSelect={area_id => {
                                            this.setState({ area_id: area_id })
                                            this.modalRef.current.setFieldsValue({ rubro_id: undefined })
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Rubro"
                                    name="rubro_id"
                                    rules={[{
                                        required: required?.rubro,
                                        message: "Por favor, seleccione el rubro"
                                    }]}
                                >
                                    <SelectRubro
                                        className="width-100"
                                        disabled={(disabled?.rubro)}
                                        area_id={this.state.area_id}

                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Cliente"
                                    name="cliente_id"
                                    rules={[{
                                        required: required?.cliente,
                                        message: "Por favor, seleccione el cliente"
                                    }]}
                                >
                                    <SelectCliente
                                        disabled={disabled?.cliente}
                                        onSelect={(cliente_id) => {
                                            this.setState({ cliente_id })
                                            this.modalRef.current.setFieldsValue({ propiedad_id: undefined })
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Propiedad"
                                    name="propiedad_id"
                                    rules={[{
                                        required: required?.propiedad,
                                        message: "Por favor, seleccione la propiedad"
                                    }]}
                                >
                                    <SelectPropiedad
                                        disabled={disabled?.propiedad}
                                        cliente_id={this.state.cliente_id}
                                        onSelect={(propiedad_id) => {
                                            this.modalRef.current.setFieldsValue({ acreedor_id: undefined, proveedor_id: undefined, inversion_id: undefined, transaccion_programada_id: undefined })
                                            this.setState({ propiedad_id, acreedor_id: undefined, proveedor_id: undefined, inversion_id: undefined })
                                        }
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Inversión"
                                    name="inversion_id"
                                    rules={[{
                                        required: required?.inversion,
                                        message: "Por favor, seleccione la inversión"
                                    }]}
                                >
                                    <SelectInversion
                                        disabled={disabled?.inversion}
                                        onSelect={(inversion_id) => {
                                            this.modalRef.current.setFieldsValue({ acreedor_id: undefined, proveedor_id: undefined, propiedad_id: undefined, transaccion_programada_id: undefined })
                                            this.setState({ inversion_id, acreedor_id: undefined, proveedor_id: undefined, propiedad_id: undefined })
                                        }
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Acreedor"
                                    name="acreedor_id"
                                    rules={[{
                                        required: required?.acreedor,
                                        message: "Por favor, seleccione el acreedor"
                                    }]}
                                >
                                    <SelectAcreedor
                                        disabled={disabled?.acreedor}
                                        onSelect={(acreedor_id) => {
                                            this.modalRef.current.setFieldsValue({ inversion_id: undefined, proveedor_id: undefined, propiedad_id: undefined, transaccion_programada_id: undefined })
                                            this.setState({ acreedor_id, inversion_id: undefined, proveedor_id: undefined, propiedad_id: undefined })
                                        }
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Proveedor"
                                    name="proveedor_id"
                                    extra={agregar?.proveedor ? (
                                        <Button
                                            type="link"
                                            onClick={() => this.setState(state => {
                                                state.modals.proveedor = true
                                                return state
                                            })}
                                        >Agregar nuevo</Button>
                                    ) : null}
                                    rules={[{
                                        required: required?.proveedor,
                                        message: "Por favor, seleccione el proveedor"
                                    }]}
                                >
                                    <SelectProveedor
                                        disabled={disabled?.proveedor}
                                        onSelect={(proveedor_id) => {
                                            this.modalRef.current.setFieldsValue({ inversion_id: undefined, propiedad_id: undefined, acreedor_id: undefined, transaccion_programada_id: undefined })
                                            this.setState({ proveedor_id, inversion_id: undefined, propiedad_id: undefined, acreedor_id: undefined })
                                        }
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Tipo de Pago"
                                    name="tipo_pago">
                                    <Select
                                        disabled={disabled?.tipo}
                                        onSelect={value => {
                                            this.setState({ tipoTransaccion: value })
                                        }}
                                        placeholder="Seleccione tipo">
                                        <Option value={null}>Normal</Option>
                                        <Option value={2}>Pago en Especie</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Facturas (UUID)"
                                    name="uuid"
                                    rules={[{
                                        required: required?.factura,
                                        message: "Por favor, seleccione la factura"
                                    }]}
                                >
                                    <AutoComplete
                                        disabled={disabled?.factura}
                                        className="width-100"
                                        options={this.state.facturas.data.map(item => {
                                            const { uuid, serie, folio, cliente, proveedor, concepto, monto, monto_restante, _id } = item
                                            return (
                                                {
                                                    option: item,
                                                    value: _id,
                                                    label: <List.Item className="width-100" style={{ borderBottom: "1px solid lightgray" }}>
                                                        <List.Item.Meta
                                                            title={<>
                                                                {_id}
                                                                <small>{concepto}</small>
                                                                <small>{(cliente || proveedor) ? (cliente ? cliente.razon_social : proveedor.nombre_comercial) : null}</small>
                                                            </>}
                                                            description={<>
                                                                {monto.toLocaleString('en-US', { style: 'currency', currency: "USD" })}<br />
                                                                <small><strong>{monto_restante.toLocaleString('en-US', { style: 'currency', currency: "USD" })}</strong></small>
                                                            </>}
                                                        />
                                                    </List.Item>,
                                                    title: uuid
                                                }
                                            )
                                        })}
                                        onSearch={search => this.getFacturas({ search })}
                                        onSelect={(value, option) => {

                                            if (this.ModalTransacciones.current.getFieldValue("monto_pesos") > option.option.monto_restante) {
                                                this.ModalTransacciones.current.setFieldsValue({
                                                    uuid: null
                                                })
                                                return message.error("El monto es mayor al monto restante")
                                            }
                                            this.ModalTransacciones.current.setFieldsValue({
                                                uuid: value
                                            })
                                        }}
                                    >
                                        <Input placeholder="UUID" disabled={disabled?.factura} />
                                    </AutoComplete>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Cuenta"
                                    name="cuenta_id"

                                    rules={
                                        (this.props.tipo_pago == !2) ? [
                                            {
                                                required: required?.cuenta,
                                                message: "Por favor, seleccione la cuenta"
                                            }
                                        ] : []
                                    }
                                >
                                    <SelectCuenta
                                        disabled={disabled?.cuenta}
                                        onSelect={value => {
                                            this.setState({ cuentaSeleccionada: value })
                                            let cuenta_detino = this.modalRef.current?.getFieldValue('cuenta_destino_id')
                                            if (cuenta_detino === value)
                                                this.modalRef.current?.setFieldsValue({
                                                    cuenta_destino_id: null
                                                })
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Tipo"
                                    name="tipo"
                                    rules={[{
                                        required: required?.tipo,
                                        message: "Por favor, seleccione el tipo"
                                    }]}
                                >
                                    <Select
                                        disabled={disabled?.tipo}
                                        onSelect={value => {
                                            this.setState({ tipoTransaccion: value })
                                        }}
                                        placeholder="Seleccione tipo">
                                        <Option value={1}>Ingreso</Option>
                                        <Option value={2}>Egreso</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {(this.props.shown?.monto) && <Col xs={24} lg={24} >
                                <Form.Item
                                    label="Monto"
                                    name="monto"

                                    rules={[{
                                        required: required?.monto,
                                        message: "Por favor, ingrese monto"
                                    }]}
                                >
                                    <InputNumber disabled={disabled?.monto} min={0.01} defaultValue={0} className="width-100"
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                    //disabled={this.state.transaccion["monto"]}
                                    />
                                </Form.Item>
                            </Col>}
                            {/* <Col xs={24} lg={24}>
                                <Form.Item
                                    // label="Afectar Saldo"
                                    name="afecta_saldo"
                                    valuePropName="checked"
                                >
                                    <Checkbox>¿Deseas usar esta transacción para liquidar el saldo de Transacciones Programadas?</Checkbox>
                                </Form.Item>
                            </Col> */}
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Empresa"
                                    name="empresa_id"

                                    rules={[{
                                        required: required?.empresa,
                                        message: "Por favor, seleccione la empresa"
                                    }]}

                                >
                                    <SelectEmpresa
                                        disabled={(!transaccion_id && disabled?.empresa)}
                                    />

                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Proyectos"
                                    name="proyecto_id"
                                    rules={[{
                                        required: required?.proyecto,
                                        message: "Por favor, seleccione el proyecto"
                                    }]}
                                >
                                    <SelectProyecto/>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Divider className="modal-divider">Budgeting</Divider>
                        <Form.List name="conceptos">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <BudgetingInputs key={key} name={name} restField={{ ...restField }} add={add} remove={remove} />
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" disabled={disabled?.budgeting} onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <Divider className="modal-divider">Comprobantes</Divider>
                        <Form.Item
                            label="Archivos e imagenes"
                            name="comprobantes"
                            valuePropName="fileList"
                            getValueFromEvent={e => {
                                if (Array.isArray(e)) {
                                    return e;
                                }
                                return e?.fileList;
                            }}>
                            <Uploader
                                {...this.props.transaccion_id ? {
                                    method: "PUT",
                                    name: "comprobante",
                                    headers: {
                                        authorization: sessionStorage.getItem("token")
                                    },
                                    action: axios.defaults.baseURL + '/transacciones',
                                    onRemove: e => {
                                        axios.put('/transacciones', {
                                            id: this.props.transaccion_id,
                                            comprobante: e.uid,
                                        })
                                    }
                                } : {}}>
                                <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                            </Uploader>
                        </Form.Item>

                    </Spin>
                </Form>
                <ModalProveedores
                    visible={this.state.modals?.proveedor}
                    onClose={(proveedor = null) => {
                        this.setState(state => {
                            state.modals.proveedor = false
                            return state
                        });
                        if (proveedor) {
                            this.modalRef?.current?.setFieldsValue({
                                proveedor_id: {
                                    value: proveedor._id,
                                    label: proveedor.alias
                                }
                            })
                        }
                    }}
                />
            </>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, transaccion_id, clasificacion } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={transaccion_id ? "Editar Transacción" : "Nueva Transacción"}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-transaccion', key: 'submit', htmlType: 'submit' }}
        style={{ top: '10px' }}
        width={650}
    >
        <ModalTransacciones {...props} />
    </Modal>

}