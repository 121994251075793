import React, { Component } from "react";
import { Row, Col, Spin, PageHeader, Layout, message, Card } from "antd";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

//componentes
import InmueblesTree from './Inmuebles/InmueblesTree'
import { IconArrowBack } from '../../Widgets/Iconos';


//css
import "../../../Styles/Modules/Proyectos/Proyectos.scss";

const { Content } = Layout;

/**
 *
 *
 * @export
 * @class proyectos
 * @extends {Component}
 * @description Vista donde se listan todos los proyectos
 */
class Propiedades extends Component {

    back = () => {
        this.props.history.goBack();
    };

    /**
     *Creates an instance of proyectos.
     * @param {*} props
     * @memberof proyectos
     */
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proyectos: {
                data: [],
                limit: 20,
                page: 1,

                total: 0,
                pages: 0,
            },
            proyecto: {},

            addPolygon: false,
            mapperWidth: 100,
            proyecto_id: this.props.params.propiedad_id,
        };
    }

    componentDidMount() {
        this.getProyecto()
    }

    /**
    * @memberof Conceptos
    * @method getProyecto
    * @description  Obtiene la informacion del proyecto seleccionado
    **/
    getProyecto = () => {
        axios.get(`/proyectos/${this.state.proyecto_id}`,{
            params:{
                id: this.state.proyecto_id,
                inmuebles_count: true
            }
        })
        .then(response => {
            this.setState({proyecto: response.data})
        }).catch(error => {
            message.error('Error al obtener la información del proyecto')
        })
    }



    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={`Inmuebles de ${this.state.proyecto.nombre}`}
                        onBack={(e) => this.props.navigate(-1)}
                        backIcon={<IconArrowBack/>}
                        extra={
                            <span className="page-header-extra">
                                {this.state.proyecto.inmuebles_count} propiedades
                            </span>
                        }
                    />
                    <Content className="admin-content content-bg pd-1 w-100">
                        <Row className="mt-1" gutter={[16, 16]}>
                            <Col xs={24}>
                                <Card className="w-100">
                                    <InmueblesTree
                                        proyecto_id={this.state.proyecto_id}
                                        updateData={this.getProyecto}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                 
                </Spin>
            </>
        );
    }
}


export default function (props) {


    const params = useParams()

    return <Propiedades {...props} params={params} navigate={useNavigate()} />
}