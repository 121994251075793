import { Col, Empty, PageHeader, Row } from "antd"
import { Content } from "antd/lib/layout/layout"
import { Component } from 'react'

export default class NotAllow extends Component {
	render() {
		return (
			<>
				<PageHeader
					className="site-page-header custom-page-header"
					title={this.props.title}
				/>
				<Content className="pd-1 dashboard-content-bg">
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Empty className="mt-3" description="No tienes acceso a este módulo"/>
						</Col>
					</Row>
				</Content>
			</>
		)
	}
}