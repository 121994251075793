import React, { Component } from 'react';
import { Row, Col, Card, Button, Spin, Tag, Layout, Space, List, Typography, Popconfirm, message } from 'antd'

import axios from 'axios';

import { IconPlusGroup, IconDelete } from '../../../Widgets/Iconos';	
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

import './Sections.scss'

import ModalBitacoraInventario from '../ModalesSecciones/ModalBitacoraInventario';

import Logged from '../../../../Hooks/Logged'
import usePermissions from '../../../../Hooks/usePermissions';
import moment from 'moment';

const { Content } = Layout;
const { Text } = Typography;


export class BitacoraInventario extends Component {

    constructor(props) {
        super(props)
        this.state = {

            bitacoras: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },

        }
    }

    componentDidMount() {


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getBitacora();

    }

    /**
     *
     * @memberof BitacoraInventario
     * @method getBitacora
     * @description Obtiene las bitacoras de inventario realcionadas con un producto
     */
    getBitacora = async ({
        page = this.state.bitacoras.page,
        limit = this.state.bitacoras.limit,
        search = this.props.search
    } = this.state.bitacoras) => {

        this.setState({ loading: true, bitacoras: { ...this.state.bitacoras, page, limit } })

        axios.get('/almacenes-bitacoras/list', {
            params: { limit, page, search, almacen_id: this.props.almacen_id }
        })
            .then(({ data }) => {
                console.log(data)
                this.setState({ bitacoras: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <Content className="admin-content content-bg">
                        <List
                            loading={false}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Bitacoras " }}
                            dataSource={this.state.bitacoras.data}
                            pagination={{
                                current: 1,
                                pageSize: 10,
                                total: 3,
                                position: 'bottom',
                                className: "flex-left",

                            }}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 " gutter={[16, 16]}>
                                            <div className={"badge-card bitacora-tipo-" + item.tipo}>
                                            </div>
                                            
                                            <Col span={4} className="center">
                                                <Text>{item?.producto_id?.nombre}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text>{item?.producto_id?.sku}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Tag className='tag-inmueble'>{item?.inmueble_id?.nombre}</Tag>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>{item?.contratista_id?.nombre}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                            <Text>{item?.volumen} {item.producto_id.unidad.simbolo ? item.producto_id.unidad.simbolo : item.producto_id.unidad.nombre}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text>{moment(item.fecha).format('YYYY-MM-DD')}</Text>
                                            </Col>
                                            <Col span={3} align="right">
                                                <Space size="small" direction="horizontal">
                                                <Button
                                                        type="primary"
                                                        disabled={false}
                                                        icon={<IconPlusGroup />}
                                                        title="Editar"
                                                        onClick={() => this.setState({ bitacora_id: item._id, modal_detalle_visible: true })}
                                                    />
                                                <Popconfirm
                                                        placement="topRight"
                                                        disabled={false}
                                                        title="¿Deseas eliminar esta bitacora?"
                                                        onConfirm={() => axios.delete('/almacenes-bitacoras/' + item._id,
                                                            {}).then(() => {
                                                                this.getBitacora()
                                                                message.success('Bitacora eliminada')
                                                            }).catch(error => {
                                                                message.error('Error al eliminar la bitacora')
                                                                console.log(error)
                                                            })}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            disabled={false}
                                                            type="primary"
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>

                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>

                    {this.props.createBitacora ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} /> : null}
                    
                    <ModalBitacoraInventario
                        visible={this.state.modal_visible}
                        onClose={() => { this.setState({ modal_visible: false, producto_almacen: undefined }); this.getBitacora()}}
                        almacen_id={this.props.almacen_id}
                    />

                    
                </Spin>
            </>
        )
    }
}


export default function (props) {

    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {
        
        createBitacora: ["almacenes-bitacoras", "create"],
        

    })

    console.log("Permisos",permissions)

    return <BitacoraInventario {...props} {...permissions}/>

}