import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography, Collapse, Dropdown, Menu, Upload, Switch } from 'antd'
import { UploadOutlined, FileExcelOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

import { IconCheckBtn, IconCheckMedal, IconPlusGroup } from '../../Widgets/Iconos'
import { BsFillFlagFill, BsCheckLg } from 'react-icons/bs';

import CustomAvatar from '../../Widgets/Avatar/Avatar';

import Logged from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
//componentes
import ListaRequerido from './Listas/ListaRequerido'
import ListaRequisicion from './Listas/ListaRequisicion'
import ListaEntregas from './Listas/ListaEntregas'

//Modales
import ModalRequisiciones from './ModalRequisiciones'
import ModalRequisicionDetalle from './ModalRequisicionDetalle'


import "./Requisiciones.scss"

const { Text } = Typography;
const moment = require('moment')

const { Content } = Layout;
const { Panel } = Collapse;

/**
 * @export
 * @class Areas
 * @extends {Component}
 * @description Vista de Areas
 */
export class Areas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,


            panels: [1,2,3,0,4,5,6],
            searching: true,
            filtroSearch: '',

        }
    }


    referencias = {
        1: React.createRef(),
        2: React.createRef(),
        3: React.createRef(),
        0: React.createRef(),
        4: React.createRef(),
        5: React.createRef(),
        6: React.createRef(),
        7: React.createRef(),
        8: React.createRef(),
    }
    

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

    }


    getRequisiciones = (array_estatus) => {

        for(const estatus of array_estatus){
            this.referencias[estatus].current.getRequisiciones({page: 1})
        }
    }

    getCSV = (tipo) => {

        let url = new URL(axios.defaults.baseURL + '/requisiciones-csv')
        url.searchParams.set('Authorization', sessionStorage.getItem('token'))
        window.open(url, '_blank');

    }

    render() {

        let props = {
            getRequisiciones: (array) => this.getRequisiciones(array),
            search: this.props.search
        }

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Requisiciones"
                        extra={[
                            <Button 
                                key="3" 
                                title="Importar a .csv"
                                style={{fontSize: '25px'}} 
                                icon={<FileExcelOutlined style={{color: "green"}}/>}
                                onClick={()=>this.getCSV()  }
                            />   
                        ]}
                    />
                    <Content className="admin-content requisiciones content-bg pd-1">


                        <ListaRequerido 
                            ref={this.referencias[1]}
                            {...props}
                        />

                        <ListaRequisicion 
                            label={"Cotizado"}
                            name={"cotizado"}
                            estatus={2}
                            ref={this.referencias[2]}
                            {...props}
                        />

                        <ListaRequisicion 
                            label={"Pausado"}
                            name={"pausado"}
                            estatus={3}
                            ref={this.referencias[3]}
                            {...props}
                        />

                        <ListaRequisicion 
                            label={"Cancelado"}
                            name={"cancelado"}
                            estatus={0}
                            ref={this.referencias[0]}
                            {...props}
                        />

                        <ListaEntregas 
                            label={"En Pago"}
                            name={"en_pago"}
                            estatus={8}
                            ref={this.referencias[8]}
                            {...props}
                        />

                        <ListaEntregas 
                            label={"Entrega"}
                            name={"entrega"}
                            estatus={4}
                            ref={this.referencias[4]}
                            {...props}
                        />

                        <ListaEntregas 
                            label={"Pendiente de Entrega"}
                            name={"pendiente_entrega"}
                            estatus={7}
                            ref={this.referencias[7]}
                            {...props}
                        />

                        <ListaEntregas 
                            label={"Recolección"}
                            name={"recoleccion"}
                            estatus={5}
                            ref={this.referencias[5]}
                            {...props}
                        />

                        <ListaEntregas 
                            label={"Entregado"}
                            name={"entregado"}
                            estatus={6}
                            ref={this.referencias[6]}
                            {...props}
                        />

                    </Content>


                
                <FloatingButton title="Nueva Requisición" onClick={()=>this.setState({modal_visible: true})}/>




                </Spin>
                <ModalRequisiciones
                    visible={this.state.modal_visible}
                    onClose={(flag)=>{
                        this.setState({modal_visible:  false})
                        if(flag === true){
                            this.getRequisiciones([1])
                        }
                    }}
                />

            </>
        )
    }
}

export default function (props) {

    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {


    })

    return <Areas {...props} {...permissions} navigate={useNavigate()} />

}