import React, { Component, useContext } from "react";
import { Button, Col, Row, Typography, Card, List, Space, message, Popconfirm, Modal, Tooltip } from "antd";
import axios from 'axios'
import { MdOutlineEmail, MdOutlineMarkEmailRead } from "react-icons/md"
import { DeleteOutlined, EditOutlined, CheckOutlined, FilePdfOutlined, LinkOutlined } from "@ant-design/icons"


//compoenentes
import User from "../../../../Hooks/Logged";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import usePermissions from "../../../../Hooks/usePermissions";
import { renderMontoTransaccion, tipos_facturas } from "../../../Utils";

//Modal
import ModalAceptar from "./ModalAceptar"



const { Text } = Typography;
const moment = require('moment');

/**
 * @export
 * @class CotizacionesTab
 * @extends {Component}
 * @description Lista de cotizacciones (FACTURAS CON ESTATUS DE -1) relacionadas al proveedor
 */
class CotizacionesTab extends Component {

	constructor(props) {
		super(props)
		this.state = {
			facturas: {
				data: [],
				limit: 20,
				page: 1,
				total: 0,
				pages: 0,
			},
			proveedor_id: this.props.proveedor_id,
			transaccion_id: undefined
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
		if(this.props.proveedor_id){
			this.getFacturas()	
		} 
	}

	componentDidUpdate(prevProps){
		if(this.props.proveedor_id && prevProps.proveedor_id !== this.props.proveedor_id){
			this.getFacturas()	
		} 
	}

	/**
	 * @memberof CotizacionesTab
	 * @method getFacturas
	 * @description Obtiene los facturas realcionadas al proveedor
	 *
	 */
	getFacturas = ({
		page = this.state.facturas.page,
		limit = this.state.facturas.limit,
		proveedor_id = this.props.proveedor_id,
	} = this.state.facturas) => {

		this.setState({ loading: true })
		axios.get('/facturas', {
			params: {
				proveedor_id,
				page,
				limit,
				cotizaciones: true
			}
		})
			.then(({ data }) => {
				this.setState({ 
					facturas: {
						...data 
					} 
				});
			})
			.catch(error => {
				message.error('Error al traer las facturas')
				console.log(error.response)
			}
			).finally(() => this.setState({ loading: false }))
	}



	render() {

		const { data } = this.state.facturas

		return (
			<>
				<Row gutter={[8, 8]}>
					<List
						itemLayout="horizontal"
						dataSource={data}
						loading={this.state.loading}
						key={(item => item._id)}
						className="component-list width-100"
						pagination={{
							current: this.state.facturas.page,
							pageSize: this.state.facturas.limit,
							total: this.state.facturas.total,
							position: 'bottom',
							className: "flex-left",
							showSizeChanger: true,
							onChange: (page, limit) => this.getFacturas({ page, limit })
						}}
						renderItem={item => (
							<List.Item className="component-list-item transacciones">
								<Card className="ant-card-list width-100">
									<Row className="width-100" gutter={[16, 16]}>
										<Col xs={24} lg={2} className="center ">
											<Text className="text-date-format fecha">{moment(item.fecha).format('YYYY-MM-DD')}</Text>
										</Col>
										<Col xs={24} lg={4} className="center ">
											<Text className="concepto">{tipos_facturas(item.tipo)}</Text>
										</Col>
										<Col xs={24} lg={4} className="center ">
											<Text className="concepto">{item.concepto}</Text>
										</Col>
										<Col xs={24} lg={3} className="center ">
											<CustomAvatar
												image={axios.defaults.baseURL+"/upload/"+ item.empresa_id?.logo}
												color={item.empresa_id?.color}
												name={item.empresa_id?.alias}
											/>
											<Text ellipsis className="concepto">{item.empresa_id?.alias}</Text>
										</Col>
										<Col xs={24} lg={4} className="center ">
											{renderMontoTransaccion({tipo: item.tipo, monto: item.monto})}
										</Col>
										<Col xs={24} lg={4} className="center ">
											<Text strong>$ {item.monto_restante.toMoney(true)} MXN</Text>
										</Col>
										<Col xs={24} lg={3} className="center">
											<Space direction="horizontal" size={5}>
												
												<Tooltip mouseEnterDelay={0} title="Editar la Cotización">
													<Button
														disabled={!this.props.editarFacturas}
														size="small"
														type="primary"
														icon={<EditOutlined style={{ color: "currentcolor" }} />}
														onClick={() => this.props.editCotizacion({ factura_id: item._id})}
													/>
												</Tooltip>
												<Tooltip mouseEnterDelay={0} title="Aceptar Cotiazción">
													<Button
														size="small"
														className="ant-btn-primary-green"
														type="primary"
														disabled={!this.props.editarFacturas}
														icon={<CheckOutlined style={{ color: "currentcolor" }} />}
														onClick={() => this.setState({modal_visible: true, factura_id: item._id,})}
													/>
												</Tooltip>
												<Popconfirm
													placement="topRight"
													title="¿Deseas eliminar esta Cotización?"
													onConfirm={() => axios.delete(`/facturas`,
														{ params: { id: item._id } }).then(() => {
															message.success('Factura eliminada')
														}).catch(error => console.log(error))
														.finally(e => this.props.updateAll())
													}
													okText="Si"
													cancelText="No"
												>
													<Button
														disabled={!this.props.eliminarFacturas}
														size="small"
														type="primary"
														icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
														title="Eliminar"
														danger
													/>
												</Popconfirm>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Row>
				<ModalAceptar
					visible={this.state.modal_visible}
					onClose={()=>{
						this.setState({modal_visible: false,factura_id: undefined})
						this.props.updateAll()
					}}
					factura_id={this.state.factura_id}
				/>
			</>
		)
	}
}

export default React.forwardRef((props, ref) => {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
		
        editarFacturas: ["proveedores", "facturas", "edit"],
        eliminarFacturas: ["proveedores", "facturas", "delete"]
    });

	return <CotizacionesTab ref={ref} {...props} user={user} {...permisos}/>
})