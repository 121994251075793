import React, { Component } from "react";
import { Row, Col, Modal, Form, Checkbox, Input, message, Spin, Select, Space, List, } from 'antd';

//componentes
import PhoneInput from "../../Widgets/Inputs/PhoneInput";
import Avatar from "../../Widgets/Avatar/Avatar";
import { SimpleUploader, Uploader, AvatarLoader } from "../../Widgets/Uploaders";
import { UploadOutlined } from "@ant-design/icons";

import "../../../Styles/Modules/Usuarios/ModalUsuarios.scss";

const axios = require('axios').default;
const { Option } = Select;



/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalUsuarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            roles: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
            proyectos: {
                data: [],

                page: 1,
                limit: 50,

                pages: 0,
                total: 0,
                search: null
            },
            loading: false,
            mostrarProyectos: false
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        this.getRoles()
        this.getProyectos()
        if (this.props.usuario_id)
            this.get()

    }




    formRef = React.createRef();

    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/usuarios/' + this.props.usuario_id)
            .then(({ data }) => {
                        
                this.formRef.current.setFieldsValue({
                    ...data,
                    avatar: data.avatar ? {
                        uid: data.avatar.file,
                        name: data.avatar.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/usuarios/" + this.props.usuario_id + "?avatar=" + data.avatar.file +"&Authorization=" + sessionStorage.getItem("token")
                    } : null
                })
            }).catch(error => {
                message.error('Error al obtener el usuario')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            avatar: undefined,
            usuario_id: this.props.usuario_id,
        })

        if(values.avatar){
            formData.append("avatar", values.avatar, values.avatar.name)
        }
        

        if (this.props.usuario_id) {
            this.update(formData)
        } else {
            this.add(formData)
        }

    };
    // onFinish = (values) => {
    //     if (this.props.usuario_id) {
    //         this.update(values)
    //     } else {
    //         this.add(values)
    //     }
    // }

    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/usuarios', values)
        .then(response => {

            message.success('¡Usuario Creado!')
            this.props.onClose()
        }).catch(error => {
            console.log("error", error);
            message.error('Error al crear el Usuario')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateUser
    * @description Actualiza la informacion de un usuario
    */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/usuarios',values)
        .then(response => {
            message.success('¡Usuario Actualizado!')
            this.props.onClose()
        }).catch(error => {
            console.log("error", error);
            message.error('Error al actualizar el Usuario')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @methodOf  Areas
     * @method getAreas
     *
     * @description Obtiene los roles
     * */
    getRoles = ({
        page = this.state.roles.page,
        limit = this.state.roles.limit,
        search = this.props.search
    } = this.state.roles) => {
        this.setState({ loading: true, roles: { ...this.state.roles, page, limit } })
        axios.get('/roles', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            console.log("data", data);
            this.setState({
                roles: { ...this.state.roles, ...data },
            });
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    /**
    *
    * @memberof ModalTransacciones
    * @method getProyectos
    * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
    */
    getProyectos = ({

        page = this.state.proyectos.page,
        limit = this.state.proyectos.limit,
        search = this.state.proyectos.search

    } = this.state.proyectos) => {

        this.setState({ proyectos: { ...this.state.proyectos, page, limit }, loading: true })
        axios.get('/proyectos', {
            params: { limit, page, search }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ proyectos: { ...this.state.proyectos, ...data } })
            })
            .catch(error => {
                console.log('error', error)
                message.error('No se pudieron cargar las empresas')
            })
            .finally(() => this.setState({ loading: false }))
    }

    render() {

        window.a = this.formRef

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-usuario"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center" gutter={[16, 16]}>
                        <Col span={24} className="center">
                            <Form.Item
                                name="avatar"
                            >
                                <AvatarLoader
                                    imageCrop={true}
                                    onRemove={()=>{
                                        axios.put('/usuarios',{
                                            usuario_id: this.props.usuario_id,
                                            delete_avatar: true
                                        })
                                    }}
                                    
                                />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el nombre',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="apellidos"
                                label="Apellidos"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese los apellidos',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24}>
                            <Form.Item
                                name="email"
                                label="E-mail"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email no valido',
                                    },
                                    {
                                        required: true,
                                        message: 'Ingrese el Emil',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="posicion"
                                label="Posición / Descripción de Puesto"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el nombre',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="telefono"
                                label="Teléfono"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el Teléfono',
                                    },
                                ]}
                            >
                                <PhoneInput />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="rol_id"
                                label="Tipo Usuario"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el Tipo de Usuario',
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={false}
                                    onSearch={search => this.getRoles({ search, page: 1 })}
                                >
                                    {this.state.roles.data.map(rol => <Option key={rol._id} value={rol._id} rol={rol}>{rol.nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>

                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="proyectos"
                                label={"Permisos sobre proyectos:"}

                            >
                                <Checkbox.Group style={{ width: '100%' }}
                                
                                
                                    onChange={value => {


                                        if (value?.length  > 0 && value?.length  == this.state.proyectos.total) {
                                            this.formRef.current.setFieldsValue({ proyectos_all: true })
                                             this.setState({ intermediateFull: false })
                                        } 
                                        else  this.setState({ intermediateFull: !!value.length && value.length < this.state.proyectos.total })
                                    }}
                                >
                                    <List
                                        className="list-proyectos"
                                        size="small"
                                        itemLayout="horizontal"
                                        dataSource={this.state.proyectos.data}
                                        header={
                                            <Row >
                                                <Form.Item
                                                    name="proyectos_all"
                                                    noStyle
                                                    valuePropName="checked"
                                                    onChange={value => {

                                                        // setCheckedList(e.target.checked ? plainOptions : []);
                                                        // setIndeterminate(false);
                                                        // setCheckAll(e.target.checked);

                                                    }}
                                                >
                                                    <Checkbox skipGroup indeterminate={this.state.intermediateFull}>Todos los proyectos</Checkbox>
                                                </Form.Item>
                                            </Row>
                                        }
                                        renderItem={proyecto => (
                                            <Checkbox className="width-100 mt-1" value={proyecto._id} key={proyecto._id}>
                                                <Space>
                                                    <Avatar
                                                        // size="small"
                                                        image={axios.defaults.baseURL + "/proyectos/logo/" + proyecto._id + "?Authorization=" + sessionStorage.getItem("token")}
                                                        name={proyecto.alias}
                                                        color={proyecto.color}
                                                    />{proyecto.nombre}
                                                </Space>
                                            </Checkbox>
                                        )}
                                    />
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Vista(props) {

    const { visible = false, onClose = () => { }, usuario_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={usuario_id ? "Editar Usuario" : "Crear Usuario"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-usuario', key: 'form-usuario', htmlType: 'submit' }}
    >
        <ModalUsuarios  {...props} />
    </Modal>

}