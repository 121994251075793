import React, { Component, useRef, useContext, useEffect } from "react";
import { Route, Routes, Redirect, useNavigate, useLocation } from "react-router-dom";
import { Layout } from "antd";
import '../Styles/Global/admin.css';

//Componentes
import Navbar from "../components/Header/Header";
import AdminSidebar from "../components/Sidebar/Sidebar.js"
import { User, SetUser } from '../Hooks/Logged';

// Routers
import RouterCashFlow from "./RouterCashFlow"
import RouterCRM from "./RouterCRM"
import RouterAreas from "./RouterAreas"
import RouterRoles from "./RouterRoles"
import RouterFinance from "./RouterFinance"
import RouterCuentas from "./RouterCuentas"
import RouterEmpresas from "./RouterEmpresas"
import RouterUsuarios from "./RouterUsuarios"
import RouterDashboard from "./RouterDashboard"
import RouterBudgeting from "./RouterBudgeting"
import RouterProyectos from "./RouterProyectos"
import RouterAlmacenes from "./RouterAlmacenes"
import RouterProductos from "./RouterProductos"
import RouterEmpleados from "./RouterEmpleados";
import RouterAcreedores from "./RouterAcreedores"
import RouterCategorias from "./RouterCategorias"
import RouterProveedores from "./RouterProveedores"
import RouterContabilidad from "./RouterContabilidad"
import RouterRequisiciones from "./RouterRequisiciones";
import RouterFinancialHealth from "./RouterFinancialHealth";
import RouterInventario from "./RouterInventario";

import ISYWidget from "iseeyoutech-widget-web/dist/iseeyoutech-widget-web.es.js";
import "iseeyoutech-widget-web/dist/style.css";

import {PrivateRoutes} from "./Middlewares/PrivateRoutes"

import axios from 'axios'

const { Content } = Layout;

class AdminRoutes extends Component {

	constructor(props) {
		super(props)
		this.state = {
			search: null
		}
	}


	SetFilterSearch(e) {
		this.setState({ search: e })
	}

	ref = React.createRef();

	/**
	 * @method onChangeModulo
	 * @description Limia el input de busqueda
	 * */
	onChangeModulo = () => {
		if (this.ref?.current)
			this.ref.current.clear()
	}

	render() {
		return (
			<PrivateRoutes>
				<Layout className="layout" >
					<Navbar
						ref={this.ref}
						setFilterSearch={(e) => this.SetFilterSearch(e)}
					/>
					<Layout>
						<AdminSidebar onChangeModulo={this.onChangeModulo} />
						<Content className="admin-content">
							<Routes>
								<Route path="dashboard/*" 			element={<RouterDashboard search={this.state.search} />} />
								<Route path="finanzas/*" 	 		element={<RouterFinance search={this.state.search} />} />
								<Route path="contabilidad/*" 		element={<RouterContabilidad search={this.state.search} />} />
								<Route path="cuentas/*"  			element={<RouterCuentas search={this.state.search} />} />
								<Route path="budgeting/*"  			element={<RouterBudgeting search={this.state.search} />} />
								<Route path="empresas/*" 			element={<RouterEmpresas search={this.state.search} />} />
								<Route path="areas/*"  				element={<RouterAreas search={this.state.search} />} />
								<Route path="crm/*" 				element={<RouterCRM search={this.state.search} />} />
								<Route path="roles/*" 				element={<RouterRoles search={this.state.search} />} />
								<Route path="usuarios/*" 			element={<RouterUsuarios search={this.state.search} />} />
								<Route path="proveedores/*" 		element={<RouterProveedores search={this.state.search} />} />
								<Route path="proyectos/*" 			element={<RouterProyectos search={this.state.search} />} />
								<Route path="almacenes/*" 			element={<RouterAlmacenes search={this.state.search} />} />
								<Route path="productos/*" 			element={<RouterProductos search={this.state.search} />} />
								<Route path="categorias/*" 			element={<RouterCategorias search={this.state.search} />} />
								<Route path="financial-health/*" 	element={<RouterFinancialHealth search={this.state.search} />} />
								<Route path="acreedores/*" 			element={<RouterAcreedores search={this.state.search} />} />
								<Route path="cash-flow/*" element={<RouterCashFlow search={this.state.search} />} />
								<Route path="requisiciones/*" 		element={<RouterRequisiciones search={this.state.search} />} />
								<Route path="empleados/*" 			element={<RouterEmpleados search={this.state.search} />} />
								<Route path="inventario/*" 			element={<RouterInventario search={this.state.search} />} />
							</Routes>
							{/*<ISYWidget
								nombre={(this.props?.user?.nombre) + " " + this.props?.user?.apellidos}
								email={this.props?.user?.email}
								proyectoId="632e04bfb3928934bc432c87"
								URL="https://ws.admin.iseeyoutech.com"
							/>*/}
						</Content>
					</Layout>
					{/*<ISYWidget
						nombre={(this.props?.user?.nombre) + " " + this.props?.user?.apellidos}
						email={this.props?.user?.email}
						proyectoId="632e04bfb3928934bc432c87"
						URL="https://ws.admin.iseeyoutech.com"
					/>*/}
				</Layout>
			</PrivateRoutes>
		)
	}
}

export default function (props) {

	const navigate = useNavigate();
	const setUser = useContext(SetUser)
	const user = useContext(User)

  	useEffect(()=>{

		//Cuando se haga una peticion y el token esta vencido reedireciona al login 
		axios.interceptors.response.use(
			response => {
			    return response;
			},
			error => {
			  	if(error?.response?.status === 401 && error?.response?.data?.message === "jwt expired"){
			  		try{
			  			navigate("/login/1",{ replace: false })
			  		}catch(e){
			  			console.log("e", e);
			  		}
			  		setUser(null)
			  	}
			    return Promise.reject(error);
			});
  	},[])


	return <AdminRoutes {...props} user={user} />
}