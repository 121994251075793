import React from 'react';
import { Row, Col, Button, Popconfirm, PageHeader, message, Space, List, Card, Typography, Collapse, Tooltip, Tag } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom"
import { Content } from 'antd/lib/layout/layout';

// Componentes
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { IconDelete, IconEdit, IconDetails } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

//css
import '../../../../Styles/Modules/CRM/Clientes.scss';
//import ModalPropiedades from './Modales/ModalPropiedades';
import { CheckCircleOutlined, ClockCircleOutlined, CloseOutlined, DeleteOutlined, DollarCircleOutlined, EditOutlined, EyeOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import ModalPropiedades from './ModalPropiedades';

const { Text } = Typography;
const { Panel } = Collapse

/**
 *
 *
 * @export
 * @class Propiedades
 * @extends {Component}
 * @description Componente del listado de transacciones
 */
export default class Propiedades extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            modalVisible: false,
            propiedad_id: undefined,

            propiedades: {
                data: [],

                page: 1,
                limit: 20,

                total: 0,
                pages: 0,

                search: null
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getPropiedades();
    }

    /**
     *
     *
     * @memberof Propiedades
     * 
     * @method getPropiedades
     * @description Obtiene las Propiedades
     */
    getPropiedades = ({
        page = this.state.propiedades.page,
        limit = this.state.propiedades.limit,
        search = this.props.search,
    } = this.state.propiedades
    ) => {

        axios.get('/propiedades', {
            params: { page, limit, search, cliente_id: this.props.cliente_id }
        }).then(({ data }) => {
            this.setState(state => {
                state.propiedades.data = data.data
                state.propiedades.total = data.total
                state.propiedades.page = data.page

                return state;
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }


    renderEstatus = (tipo) => {
        console.log("tipo", tipo)

        // return <Tag color="#F50">Cancelada<CloseOutlined style={{ color: "currentcolor" }} /></Tag>


        switch (tipo) {
            case 0:
                return <Tag color="#F50">Cancelada<CloseOutlined style={{ color: "currentcolor" }} /></Tag>
            case 1:
                return <Tag color="#FBBC30" icon={<ClockCircleOutlined style={{ color: "currentcolor" }} />}>Apartada</Tag>
                // return <Tooltip title="Apartada">
                //     <ClockCircleOutlined style={{ color: "" }} />
                // </Tooltip>
            case 2:
                return <Tag color="#2db7f5" icon={<DollarCircleOutlined style={{ color: "currentcolor" }} />}>En Proceso de Pago</Tag>
                // return <Tooltip title="En Proceso de Pago">
                //     <DollarCircleOutlined style={{ color: "#2db7f5" }} />
                // </Tooltip>
            case 3:
                return <Tag color="#87d068" icon={<CheckCircleOutlined style={{ color: "currentcolor" }} />}>Pagada</Tag>
                // return <Tooltip title="Pagada">
                //     <CheckCircleOutlined style={{ color: "#87d068" }} />
                // </Tooltip>
            default:
                return <Tag color="#FBBC30" icon={<ClockCircleOutlined style={{ color: "currentcolor" }} />}>Pagada</Tag>
                // return <Tooltip title="Apartada">
                //     <ClockCircleOutlined style={{ color: "#FBBC30" }} />
                // </Tooltip>
        }
    }

    render() {

        return (
            <>
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.propiedades.data}
                    key={(item => item._id)}
                    className='component-list width-100 propiedades'
                    style={{overflow: "hidden"}}
                    locale={{ emptyText: "Sin Propiedades" }}
                    pagination={{
                        current: this.state.propiedades.page,
                        pageSize: this.state.propiedades.limit,
                        total: this.state.propiedades.total,
                        showSizeChanger: true,
                        hideOnSinglePage: false,
                        size: "small",
                        position: 'bottom',
                        className: "flex-right",
                        onChange: (page, limit) => this.getPropiedades({ page, limit })
                    }}
                    header={
                        <Row className='header-list width-100' style={{ padding: "0 1rem" }} gutter={[16, 16]}>
                            <Col xs={24} lg={5}className='center'>
                                <Text strong>Inmueble</Text>
                            </Col>
                            <Col xs={24} lg={5}className='center'>
                                <Text strong>Proyecto</Text>
                            </Col>
                            <Col xs={24} lg={5}className='center'>
                                <Text strong>Asesor</Text>
                            </Col>
                            <Col xs={24} lg={5}className='center'>
                                <Text strong>Estatus</Text>
                            </Col>
                            <Col xs={24} lg={3} className='center'>

                            </Col>
                        </Row>
                    }
                    renderItem={({ _id, inmueble_id, proyecto_id, asesor_id, estatus, ...args }) => (
                        <List.Item className="component-list-item w-100">
                            {/* <Card className="card-list"> */}
                            <Row className='width-100' align="middle">
                                <Col xs={24} md={5}>
                                    <Text ellipsis>{inmueble_id?.nombre}</Text>
                                </Col>
                                <Col xs={24} md={5}>
                                    <Space>
                                        {console.log("proyecto_id", args)}
                                        <CustomAvatar
                                            size="small"
                                            name={proyecto_id?.nombre}
                                            color={proyecto_id?.color}
                                            url={{
                                                url: `${axios.defaults.baseURL}/proyectos/logo/${proyecto_id?._id}`,
                                                name: proyecto_id?.logo?.name
                                            }}
                                        />
                                        <Text ellipsis>{proyecto_id?.nombre}</Text>
                                    </Space>
                                </Col>
                                <Col xs={24} md={5}>
                                    <Text ellipsis>{asesor_id?.nombre} {asesor_id?.apellidos}</Text>
                                </Col>
                                <Col xs={24} md={5} align="center">
                                    {this.renderEstatus(estatus)}
                                </Col>
                                <Col xs={24} md={3} className="flex-right" >
                                    <Space align="center">

                                        <Link to={"/admin/crm/clientes/" + this.props.cliente_id + "/propiedad/" + _id}>

                                            <Button
                                                disabled={!this.props.puedeVerDetalles}
                                                className='ant-btn-primary-lime'
                                                type="primary"
                                                title="Editar"
                                                onClick={() => this.setState({ modalVisible: true, propiedad_id: _id })}
                                                icon={<EyeOutlined style={{ color: "currentcolor" }} />}
                                            />
                                        </Link>

                                        <Button
                                            disabled={!this.props.editarPropiedades}
                                            type="primary"
                                            title="Editar"
                                            onClick={() => this.setState({ modalVisible: true, propiedad_id: _id })}
                                            icon={<EditOutlined style={{ color: "currentcolor" }} />}
                                        />
                                        <Popconfirm
                                            placement="topRight"
                                            title="¿Deseas eliminar esta Propiedad?"
                                            onConfirm={() => axios.delete(`/propiedades/${_id}`, {
                                                params: {
                                                    id: _id
                                                }
                                            }).then((response) => {
                                                message.success('¡Propiedad eliminada!')
                                                this.getPropiedades()
                                            }).catch((error) => {
                                                message.error('Propiedad no eliminada')
                                            })
                                            }
                                            okText="Si"
                                            cancelText="No"
                                            disabled={!this.props.editarPropiedades}
                                        >
                                            <Button
                                                disabled={!this.props.editarPropiedades}
                                                type="primary"
                                                danger
                                                icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
                                                title="Eliminar"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                }}
                                            />
                                        </Popconfirm>
                                    </Space>
                                </Col>
                            </Row>
                            {/* </Card> */}
                        </List.Item>
                    )}
                />
                <ModalPropiedades
                    visible={this.state.modalVisible}
                    onClose={(flag) => {
                        this.setState({ modalVisible: false, propiedad_id: undefined })
                        this.getPropiedades()
                    }}
                    propiedad_id={this.state.propiedad_id}
                />
            </>
        )
    }
}