import { Space, Tooltip, Typography } from 'antd'
import React from 'react'

import '../../Styles/Modules/Widgets/multiprogress.scss'


const {Text} = Typography
class Progress extends React.Component{

    state = {
        showData: false
    }

    barRef = React.createRef()

    componentDidMount(){
        if(this.barRef.current?.clientWidth > 10) this.setState({showData: true})
    }

    render(){
        let {
            percentage = 0
        } = this.props

        if(isNaN(percentage)) percentage = 0

        const styles = {}

        if(this.props.fill){
            styles.flexGrow = 1
        }else if(percentage !== undefined){
            styles.width = `${percentage}%`
        }

        if(!this.props.value) return (
            <div
                ref={this.barRef} 
                className={`progress ${this.props.first ? 'first' : ''} ${this.props.last ? 'last' : ''}`}
                style={{
                    ...styles,
                    backgroundColor: this.props.color || '#d4d4d4',
                    color: this.props.color ? 'white' : 'black',
                    height: this.props.height || 20
                }}
            >
                {percentage ? Number(percentage).toFixed(2).toString() + ' %' : ''}
            </div>
        )

        return (
            <Tooltip 
                color={this.props.color || "black"} 
                title={this.props.label ? (
                    <Space direction='vertical' size={2}>
                        <Text style={{color: 'white'}}>{this.props.label}</Text>
                        <Text style={{color: 'white'}} strong>{this.props.value}</Text>
                    </Space>
                ) : this.props.value} 
                placement='top'
                arrow={false}
                {...(this.state.showData ? {} : {open: false})}
            >
                <div 
                    ref={this.barRef}
                    className={`progress ${this.props.first ? 'first' : ''} ${this.props.last ? 'last' : ''}`}
                    style={{
                        ...styles,
                        backgroundColor: this.props.color || '#d4d4d4',
                        color: this.props.color ? 'white' : 'black',
                        height: this.props.height || 20
                    }}
                >
                    {Number(percentage).toFixed(2).toString() + ' %'}
                </div>
            </Tooltip>
        )
    }
}
class MultiProgress extends React.Component{

    render(){

        const {
            elements = [],
            lastElement = null,
            valueFixed = false,
        } = this.props

        return <div className="multiprogress" style={{marginTop: valueFixed ? 20 : 0}}>
            {elements.map((el, idx) => {

                return <Progress
                    label={el.label}
                    valueFixed={valueFixed}
                    value={el.value}
                    percentage={el.percentage}
                    color={el.color}
                />
            })}
            {lastElement ? (
                <Progress
                    fill
                    label={lastElement.label}
                    valueFixed={lastElement.valueFixed}
                    value={lastElement.value}
                    percentage={lastElement.percentage}
                />
            ) : (
                <Progress
                    fill
                />
            )}
        </div>
    }
}

export default MultiProgress