import React, { Component, useContext } from 'react';
import { Avatar, Spin, PageHeader, Tooltip, Layout, message, Row, Col, Card, Typography, Tabs, Menu, Space, Button, Result, Popconfirm, Tag, Empty, Badge, Breadcrumb } from 'antd'
import axios from 'axios';
import Decimal from 'decimal.js'
import { BsReceipt } from 'react-icons/bs'
import { BiEnvelope } from 'react-icons/bi'
import { FaRegHandshake } from 'react-icons/fa'
import { useParams, useNavigate, useLocation, matchRoutes, Link } from "react-router-dom";
import { CloseOutlined, InboxOutlined, DeleteOutlined, LoadingOutlined, EditOutlined, DollarCircleFilled, EyeOutlined, PlusOutlined, ClockCircleOutlined, DollarCircleOutlined, CheckCircleOutlined, } from "@ant-design/icons"

//Componentes
import Logged from '../../../../Hooks/Logged';
import DetallesPropiedad from './DetallesPropiedad';
import { IconArrowBack } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import TransaccionesTab from './Secciones/TransaccionesTab';
import usePermissions from '../../../../Hooks/usePermissions';
import TransaccionesProgramadasTab from './Secciones/TransaccionesProgramadasTab';

//Modales
import ModalPropiedades from './ModalPropiedades'
import ModalTransaccion from '../../Finanzas/Transacciones/ModalTransaccion'
import ModalTransaccionDetalle from '../../Finanzas/Transacciones/ModalTransaccionDetalle'
import ModalTransaccionProgramada from '../../Finanzas/Transacciones/ModalTransaccionProgramada';





//modal

import "../../../../Styles/Modules/CRM/Clientes.scss"

import {
    FloatingMenu,
    MainButton,
    ChildButton,
} from 'react-floating-button-menu';


const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;
const { TabPane } = Tabs;


const moment = require('moment');
moment.locale('es');

/**
 * @export
 * @class DetalleClientes
 * @extends {Component}
 * @description Vista de Detalle Clientes
 */
class DetalleClientes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            propiedad_id: this.props.params.propiedad_id,

            cliente_id: this.props.params.cliente_id,
            cliente: {},

            propiedades: {
                data: [],

                page: 1,
                limit: 20,

                total: 0,
                pages: 0
            },

            propiedad: null,

            modalPropiedadesVisible: false,
            modalPropiedadesNuevaVisible: false,

            modalTransaccionVisible: false,
            modalTransaccionDetalleVisible: false,

            tipo_pago: undefined,
            transaccion_id: undefined,

            isOpen: false,
            pathname: null,


            activeKey: "transacciones"
        }
    }

    propiedadRef = React.createRef();
    transaccionRef = React.createRef();
    programadosRef = React.createRef();

    componentDidMount() {
        this.get()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.params.propiedad_id !== this.props.params.propiedad_id) {
            this.setState({ propiedad_id: this.props.params.propiedad_id }, () => {

                this.propiedadRef.current?.get()
                if (this.state.tab === 'transacciones') {
                    this.transaccionRef.current?.get()
                } else {
                    this.programadosRef.current?.get()
                }
            })
        }

        if (prevProps.params.cliente_id !== this.props.params.cliente_id) {
            this.get({ cliente_id: this.props.params.cliente_id })
        }
    }

    /**
     * @memberof DetallesClientes
     * @method get
     * @description Obtiene los detalles y propiedades del cliente
     *
     */
    get = ({
        page = this.state.propiedades.page,
        limit = this.state.propiedades.limit,
        propiedad_id = this.state.propiedad_id,
        cliente_id = this.state.cliente_id
    } = this.state) => {
        this.setState({ loading: true, cliente_id, propiedad_id })
        axios.get(`/crm/clientes/${cliente_id}`, { params: { page, limit } })
            .then(({ data }) => {
                this.setState({
                    cliente: data.cliente,
                    propiedades: { ...data.propiedades },
                    propiedad_id: propiedad_id || data.propiedades.data[0]?._id
                })
            })
            .catch(error => {
                message.error('Error al traer la cliente y sus propiedades')
            })
            .finally(() => this.setState({ loading: false }))
    }

    updateAll = (props) => {
        console.log("updateAll")
        this.get()
        this.propiedadRef.current?.get()
        this.transaccionRef.current?.get()
        this.programadosRef.current?.get()

        // propiedad_id={propiedad_id}
        // 63fe313931d40dffcd526473
        if (String(this.state.propiedad_id) == String(props?.propiedad_id))
            this.props.navigate("/admin/crm/clientes/" + this.state.cliente_id)
    }

    renderEstatus = (tipo) => {
        console.log("tipo", tipo)
        switch (tipo) {
            case 0:
                return <Tooltip title="Cancelada">
                    <CloseOutlined style={{ color: "#F50" }} />
                </Tooltip>
            case 1:
                return <Tooltip title="Apartada">
                    <ClockCircleOutlined style={{ color: "#FBBC30" }} />
                </Tooltip>
            case 2:
                return <Tooltip title="En Proceso de Pago">
                    <DollarCircleOutlined style={{ color: "#2db7f5" }} />
                </Tooltip>
            case 3:
                return <Tooltip title="Pagada">
                    <CheckCircleOutlined style={{ color: "#87d068" }} />
                </Tooltip>
            default:
                return <Tooltip title="Apartada">
                <ClockCircleOutlined style={{ color: "#FBBC30" }} />
            </Tooltip>
        }
    }


    render() {
        const { cliente, propiedades, propiedad_id, propiedad } = this.state

        let tienePropiedades = propiedades.total > 0
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        backIcon={<IconArrowBack />}
                        onBack={(e) => this.props.navigate('/admin/crm/clientes')}
                        title={
                            <Breadcrumb className="breadcrumb-header">
                                <Breadcrumb.Item>
                                    <Link to="/admin/crm/clientes">Clientes</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {cliente?.nombre} {cliente?.apellido || ""}
                                </Breadcrumb.Item>
                                {
                                    propiedad ? (
                                        <Breadcrumb.Item>
                                            {propiedad?.inmueble_id?.nombre} ({propiedad?.proyecto_id?.nombre})
                                        </Breadcrumb.Item>
                                    ) : null
                                }
                            </Breadcrumb>
                        }
                    />
                    <Content className="admin-content content-bg pd-1 detalle-propiedad">
                        <Row >
                            <Col span={24}>
                                <Card bordered>
                                    <Row align="middle" className='mb-1' gutter={[12, 12]}>
                                        <Col>
                                            <Title level={4} >Información Personal</Title>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Nombre</Paragraph>
                                            <Paragraph strong style={{ fontSize: 16 }}>{cliente?.nombre} {cliente?.apellido || ""}</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>RFC</Paragraph>
                                            <Paragraph strong style={{ fontSize: 16 }}>{cliente?.rfc}</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Telefono</Paragraph>
                                            <Paragraph strong style={{ fontSize: 16 }}>{cliente?.telefono}</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Email</Paragraph>
                                            <Paragraph strong style={{ fontSize: 16 }}>{cliente?.email}</Paragraph>
                                        </Col>

                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Monto Total</Paragraph>
                                            <Paragraph strong style={{ fontSize: 16, color: "blue" }}>$ {cliente?.monto_total?.toMoney && cliente?.monto_total?.toMoney(true)}</Paragraph>
                                        </Col>
                                        
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Monto Pagado</Paragraph>
                                            <Paragraph strong style={{ fontSize: 16, color: "#00FF19" }}>$ {cliente?.monto_pagado?.toMoney && cliente?.monto_pagado?.toMoney(true)}</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Monto Pendiente</Paragraph>
                                            <Paragraph strong style={{ fontSize: 16, color: "#FF0000" }}>$ {cliente?.monto_pendiente?.toMoney && cliente?.monto_pendiente?.toMoney(true)}</Paragraph>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={24}>
                                {tienePropiedades ? <Menu
                                    className='menu-propiedades'
                                    mode="horizontal"
                                    selectedKeys={[this.state.propiedad_id]}
                                    // this.getPropiedad({propiedad_id: key })
                                    onClick={({ key }) => this.props.navigate("/admin/crm/clientes/" + this.state.cliente_id + "/propiedad/" + key)}
                                    items={propiedades.data.map(({ _id, inmueble_id, proyecto_id, estatus }) => ({
                                        label: <Space style={{ marginBottom: "0.5em" }}>
                                            <Badge count={this.renderEstatus(estatus)}>
                                                <CustomAvatar
                                                    color={proyecto_id?.color}
                                                    name={proyecto_id?.nombre}
                                                    url={{
                                                        url: `${axios.defaults.baseURL}/proyectos/logo/${proyecto_id?._id}`,
                                                        name: proyecto_id?.logo?.name
                                                    }}
                                                />
                                            </Badge>

                                            <Typography style={{ lineHeight: 0 }}>
                                                <Title level={5}>{inmueble_id?.nombre}</Title>
                                                <small>{proyecto_id?.nombre}</small>
                                            </Typography>
                                        </Space>,
                                        key: _id,
                                    }))}
                                /> : <div className='menu-propiedades' />}
                                <Button
                                    disabled={!this.props.crearPropiedades}
                                    type='primary' icon={<PlusOutlined style={{ color: "currentcolor" }} />}
                                    onClick={() => this.setState({ visibleModalPropiedades: true })}
                                >
                                    Agregar Propiedad
                                </Button>
                            </Col>
                            {
                                propiedades.total < 1 && !this.state.loading && (
                                    <Result
                                        style={{ margin: "2em auto 0" }}
                                        // status="default"
                                        icon={<InboxOutlined />}
                                        title="Este cliente no tiene ninguna propiedad "
                                        extra={[
                                            <Button
                                                icon={<PlusOutlined />}
                                                type="primary"
                                                key="console"
                                                onClick={() => this.setState({ visibleModalPropiedades: true })}>
                                                Agregar una Propiedad Ahora
                                            </Button>
                                        ]}
                                    />
                                )
                            }
                            {
                                propiedad_id && (
                                    <DetallesPropiedad
                                        ref={this.propiedadRef}
                                        propiedad_id={propiedad_id}
                                        updateAll={this.updateAll}
                                        setPropiedad={(propiedad)=>this.setState({propiedad})}
                                    />
                                )
                            }
                            {
                                propiedades.total > 0 && (
                                    <Col span={24}>
                                        {this.props.verTransaccionesPropiedad || this.props.verTransaccionesProgramadasPropiedad ? <Tabs className="tabs-proyectos tabs-crm-client"
                                            onChange={tab => this.setState({ tab })}
                                            destroyInactiveTabPane
                                            activeKey={this.state.tab}
                                        >
                                            {this.props.verTransaccionesPropiedad ? <TabPane tab="Transacciones" key="transacciones">
                                                <TransaccionesTab
                                                    key="transacciones"
                                                    ref={this.transaccionRef}
                                                    propiedad_id={this.state.propiedad_id}
                                                    updateAll={this.updateAll}
                                                    propiedad={this.state.propiedad}
                                                />
                                            </TabPane> : null}
                                            {this.props.verTransaccionesProgramadasPropiedad ? <TabPane tab="Transacciones Programadas" key="transacciones-programadas">
                                                <TransaccionesProgramadasTab
                                                    key="transacciones-programadas"
                                                    ref={this.programadosRef}
                                                    propiedad_id={this.state.propiedad_id}
                                                    updateAll={this.updateAll}
                                                />
                                            </TabPane> : null
                                            }
                                        </Tabs> : <Empty className='mt-1' description="No tiene permisos para ver transacciones o transacciones programadas" />}
                                    </Col>
                                )
                            }
                        </Row>
                    </Content>
                </Spin>

                <FloatingMenu
                    slideSpeed={500}
                    direction="left"
                    spacing={8}
                    isOpen={this.state.isOpen}
                    style={{

                        position: "fixed",
                        bottom: "1em",
                        right: "1.2em"

                    }}
                >
                    <MainButton
                        iconResting={<PlusOutlined style={{ fontSize: 30, color: "white" }} />}
                        iconActive={<CloseOutlined style={{ fontSize: 30, color: "white" }} />}
                        style={{ backgroundColor: "#0047FF" }}
                        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                        size={70}
                        direction="right"
                    />
                    <ChildButton
                        icon={
                            <Tooltip mouseLeaveDelay={0} title="Agregar una Transacción en Especie" placement="top">
                                <div style={{ width: "100%", height: "100%", textAlign: "center", position: "relative", top: 10 }}>
                                    <FaRegHandshake style={{ fontSize: 30, color: "white" }} nativeColor="black" />
                                </div>
                            </Tooltip>
                        }
                        background={this.props.crearTransaccionesPropiedad ? "#0047FF" : "#0047FF80"}
                        size={50}
                        onClick={this.props.crearTransaccionesPropiedad ? () => this.setState({ modalTransaccionVisible: true, tipo_pago: 2, isOpen: false }) : undefined}
                    />
                    <ChildButton
                        icon={
                            <Tooltip mouseLeaveDelay={0} title="Agregar una Transacción Programada" placement="top">
                                <div style={{ width: "100%", height: "100%", textAlign: "center", position: "relative", top: 5 }}>
                                    <ClockCircleOutlined style={{ fontSize: 40, color: "white" }} nativeColor="black" />
                                </div>
                            </Tooltip>
                        }
                        background={this.props.crearTransaccionesProgramadasPropiedad ? "#0047FF" : "#0047FF80"}
                        size={50}
                        onClick={this.props.crearTransaccionesProgramadasPropiedad ? () => this.setState({ modalTransaccionProgramadaVisible: true, isOpen: false }) : undefined}
                    />
                    <ChildButton
                        icon={
                            <Tooltip mouseLeaveDelay={0} title="Agregar una Transacción" placement="top">
                                <DollarCircleFilled style={{ fontSize: 50, color: this.props.crearTransaccionesProgramadasPropiedad ? "#0047FF" : "#0047FF80" }} nativeColor="black" />
                            </Tooltip>
                        }

                        background="white"
                        size={50}
                        onClick={this.props.crearTransaccionesProgramadasPropiedad ? () => this.setState({ modalTransaccionVisible: true, tipo_pago: undefined, isOpen: false }) : undefined}
                    />
                </FloatingMenu>

                <ModalPropiedades
                    visible={this.state.visibleModalPropiedades}
                    onClose={(flag) => {
                        this.setState({ visibleModalPropiedades: false })

                        console.log("onClose")
                        this.updateAll()
                    }}
                    cliente_id={this.state.cliente_id}
                />

                <ModalTransaccion
                    visible={this.state.modalTransaccionVisible}
                    onClose={() => {
                        this.setState({ modalTransaccionVisible: false })
                        this.updateAll()
                    }}
                    transaccion_id={this.state.transaccion_id}
                    propiedad_id={this.state.propiedad_id}
                    cliente_id={this.state.cliente_id}
                    tipo_pago={this.state.tipo_pago}
                    disabled={{
                        area: !!this.state.propiedad?.area_id,
                        rubro: !!this.state.propiedad?.rubro_id,
                        proyecto: !!this.state.propiedad?.proyecto_id,
                        cliente: true,
                        propiedad: true,
                        factura: true,
                        inversion: true,
                        acreedor: true,
                        cuenta: ((this.state.tipo_pago === 2) )
                    }}
                    required={{
                        cuenta: (!(this.state.tipo_pago === 2) ),
                        cliente: true,
						propiedad: true
                    }}
                    initialValues={{
                        tipo: 1,
                        area_id: this.state.propiedad?._id ? {
                            value: this.state.propiedad?.area_id?._id,
                            label: this.state.propiedad?.area_id?.nombre,
                        } : null,
                        rubro_id: this.state.propiedad?._id ? {
                            value: this.state.propiedad?.rubro_id?._id,
                            label: this.state.propiedad?.rubro_id?.nombre,
                        } : null,
                        proyecto_id: this.state.propiedad?._id ? {
                            value: this.state.propiedad?.proyecto_id?._id,
                            label: this.state.propiedad?.proyecto_id?.nombre,
                        } : null,
                        cliente_id: this.state.cliente?._id ? {
                            value: this.state.cliente?._id,
                            label: this.state.cliente?.nombre,
                        } : null,
                        propiedad_id: this.state.propiedad?._id ? {
                            value: this.state.propiedad?._id,
                            label: this.state.propiedad?.inmueble_id?.nombre,
                        } : null,
                    }}
                />
                <ModalTransaccionProgramada
                    visible={this.state.modalTransaccionProgramadaVisible}
                    onClose={() => {
                        this.setState({ modalTransaccionProgramadaVisible: false })
                        this.updateAll()
                    }}
                    propiedad_id={this.state.propiedad_id}
                    area={true}
                    rubro={true}
                />
                <ModalTransaccionDetalle
                    visible={this.state.modalTransaccionDetalleVisible}
                    onClose={() => {
                        this.setState({ modalTransaccionDetalleVisible: false })
                    }}
                    transaccion_id={this.state.transaccion_id}
                />
            </>
        )
    }
}

export default function () {

    const location = useLocation()
    const user = useContext(Logged);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        crearPropiedades: ["clientes", "propiedades", "create"],

        verTransaccionesPropiedad: ["clientes", "propiedades", "transacciones", "access"],
        crearTransaccionesPropiedad: ["clientes", "propiedades", "transacciones", "create"],

        verTransaccionesProgramadasPropiedad: ["clientes", "propiedades", "transacciones-programadas", "access"],
        crearTransaccionesProgramadasPropiedad: ["clientes", "propiedades", "transacciones-programadas", "create"],
    });

    return <DetalleClientes
        params={useParams()}
        navigate={useNavigate()}
        location={location}
        {...permisos}
    />
}