import React, { Component } from 'react';
import { Spin, Statistic, Typography, Col, Row, Card } from 'antd'

//import echarts from 'echarts';
import ReactEcharts from "echarts-for-react";

import axios from 'axios';



const moment = require('moment')


var colorPalette = [
    '#2ec7c9', '#b6a2de', '#5ab1ef', '#ffb980', '#d87a80',
    '#8d98b3', '#e5cf0d', '#97b552', '#95706d', '#dc69aa',
    '#07a2a4', '#9a7fd1', '#588dd5', '#f5994e', '#c05050',
    '#59678c', '#c9ab00', '#7eb00a', '#6f5553', '#c14089'
];

var theme = {
    color: colorPalette,

    title: {
        textStyle: {
            fontWeight: 'normal',
            color: '#008acd'
        }
    },

    visualMap: {
        itemWidth: 15,
        color: ['#5ab1ef', '#e0ffff']
    },

    toolbox: {
        iconStyle: {
            normal: {
                borderColor: colorPalette[0]
            }
        }
    },

    tooltip: {
        backgroundColor: 'rgba(50,50,50,0.5)',
        axisPointer: {
            type: 'line',
            lineStyle: {
                color: '#008acd'
            },
            crossStyle: {
                color: '#008acd'
            },
            shadowStyle: {
                color: 'rgba(200,200,200,0.2)'
            }
        }
    },

    dataZoom: {
        dataBackgroundColor: '#efefff',
        fillerColor: 'rgba(182,162,222,0.2)',
        handleColor: '#008acd'
    },

    grid: {
        borderColor: '#eee'
    },

    categoryAxis: {
        axisLine: {
            lineStyle: {
                color: '#008acd'
            }
        },
        splitLine: {
            lineStyle: {
                color: ['#eee']
            }
        }
    },

    valueAxis: {
        axisLine: {
            lineStyle: {
                color: '#008acd'
            }
        },
        splitArea: {
            show: true,
            areaStyle: {
                color: ['rgba(250,250,250,0.1)', 'rgba(200,200,200,0.1)']
            }
        },
        splitLine: {
            lineStyle: {
                color: ['#eee']
            }
        }
    },

    timeline: {
        lineStyle: {
            color: '#008acd'
        },
        controlStyle: {
            normal: { color: '#008acd' },
            emphasis: { color: '#008acd' }
        },
        symbol: 'emptyCircle',
        symbolSize: 3
    },

    line: {
        smooth: true,
        symbol: 'emptyCircle',
        symbolSize: 3
    },

    candlestick: {
        itemStyle: {
            normal: {
                color: '#d87a80',
                color0: '#2ec7c9',
                lineStyle: {
                    color: '#d87a80',
                    color0: '#2ec7c9'
                }
            }
        }
    },

    scatter: {
        symbol: 'circle',
        symbolSize: 4
    },

    map: {
        label: {
            normal: {
                textStyle: {
                    color: '#d87a80'
                }
            }
        },
        itemStyle: {
            normal: {
                borderColor: '#eee',
                areaColor: '#ddd'
            },
            emphasis: {
                areaColor: '#fe994e'
            }
        }
    },

    graph: {
        color: colorPalette
    },

    gauge: {
        axisLine: {
            lineStyle: {
                color: [[0.2, '#2ec7c9'], [0.8, '#5ab1ef'], [1, '#d87a80']],
                width: 10
            }
        },
        axisTick: {
            splitNumber: 10,
            length: 15,
            lineStyle: {
                color: 'auto'
            }
        },
        splitLine: {
            length: 22,
            lineStyle: {
                color: 'auto'
            }
        },
        pointer: {
            width: 5
        }
    }
};

// echarts.registerTheme('macarons', theme);

const { Title } = Typography;
const dataIngresos = ['A', 'B', 'C', 'D', 'E'];
const dataIngresosValues = [130, 100, 170, 190, 90, 200];

const toolDescargar = {
    show: true,
    feature: {
        saveAsImage: {
            show: true,
            title: 'Descargar',
            iconStyle: {
                borderColor: '#555',
                fontSize: '18pt'
            },
        },
    }
};

// ReactEcharts.registerTheme('light', {
//     backgroundColor: '#f4cccc'
// });

/**
 * @Class GraficaIngresos
 *
 * @description Card que muestra los ingresos en el Dashboard
 * */
class GraficaIngresos extends Component {
    state = {
        total: 0,
        projects: [],
        start: null,
        end: null,
        area: null,
        rubro: null

    }

    componentDidMount(): void {
        this.getLatestValues();
    }



    /**
     * @methodOf GraficaIngresos
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se actualizan los valores
     * */
    componentDidUpdate(prevProps, prevState, snapshot): void {
        //Verifica que las propieades sean diferentes a los valores en los estados, y si son diferentes actualiza el total.
        //No se puede ejecutar si esta cargando.
        if (
            (
                this.state.projects.length !== this.props.projects.length ||
                (this.state.start !== this.props.start) ||
                (this.state.end !== this.props.end) ||
                (this.state.area !== this.props.area) ||
                (this.state.rubro !== this.props.rubro)

                // area: null,
                //     rubro: null
            )
            && !this.state.loading
        ) {
            this.getLatestValues();
            this.state.projects = [...this.props.projects]
        }
    }


    /**
     * @methodOf GraficaIngresos
     *
     * @function getLatestValues
     * @description Metodo que trae la nueva informacion, cada vez que se actualiza algun proyecto
     * */
    getLatestValues = () => {
        this.setState({ loading: true })
        const { start, end, projects, area, rubro } = this.props;
        this.state.start = start;
        this.state.end = end;

        this.state.area = area;
        this.state.rubro = rubro;
        axios.get('/dashboard/quantities', {
            params: {
                tipo: 1,
                start,
                end,
                projects
            }
        })
            .then(({ data }) => {
                this.setState({
                    loading: false,
                    total: data.data
                })
            })
            .catch(({ response }) => {
                this.setState({
                    loading: false
                })

            })
    }

    render() {

        const { total, loading } = this.state;

        return (
            <Spin spinning={loading}>
                <div className="card-ingresos" style={{ width: '100%', position: 'relative', color: '#FFFFFF', fontWeight: 'bold' }}>
                    <h1 style={{ textAlign: 'center', color: '#FFF', fontSize: '25px', fontWeight: '500' }}> Ingresos </h1>
                    <Row>
                        <Col xs={24}>
                            <Row>
                                <Col span={24}>
                                    <h2 style={{ textAlign: 'center', color: '#FFF', fontSize: '16px', fontWeight: 'normal', marginBottom: '15px' }}>
                                        2020
                                    </h2>
                                    <h1 style={{ textAlign: 'center', color: '#FFF', fontSize: '25px', fontWeight: 'bolder' }}>
                                        $ {(new Intl.NumberFormat().format(total))} MXN
                                    </h1>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Spin>
        );
    }
}


/**
 * @Class GraficaEgresos
 *
 * @description Card que muestra los egresos en el Dashboard
 * */
class GraficaEgresos extends Component {

    state = {
        total: 0,
        projects: [],
        start: null,
        end: null
    }

    componentDidMount(): void {
        this.getLatestValues();
    }

    /**
     * @methodOf GraficaEgresos
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se actualizan los valores
     * */
    componentDidUpdate(prevProps, prevState, snapshot): void {
        //Verifica que las propieades sean diferentes a los valores en los estados, y si son diferentes actualiza el total.
        //No se puede ejecutar si esta cargando.
        if (
            (
                this.state.projects.length !== this.props.projects.length ||
                (this.state.start !== this.props.start) ||
                (this.state.end !== this.props.end) ||
                (this.state.area !== this.props.area) ||
                (this.state.rubro !== this.props.rubro)
            )
            && !this.state.loading
        ) {
            this.getLatestValues();
            this.state.projects = [...this.props.projects]
        }
    }

     /**
     * @methodOf GraficaEgresos
     *
     * @function getLatestValues
     * @description Metodo que trae la nueva informacion, cada vez que se actualiza algun proyecto
     * */
    getLatestValues = () => {
        this.setState({ loading: true })
        const { start, end, projects, area, rubro } = this.props;
        this.state.start = start;
        this.state.end = end;
        this.state.area = area;
        this.state.rubro = rubro;
        axios.get('/dashboard/quantities', {
            params: {
                tipo: 2,
                start,
                end,
                projects
            }
        })
            .then(({ data }) => {
                this.setState({
                    loading: false,
                    total: data.data
                })
            })
            .catch(({ response }) => {
                this.setState({
                    loading: false
                })

            })
    }


    render() {
        const { total, loading } = this.state;

        return (
            <Spin spinning={loading}>
                <div className="card-egresos" style={{ width: '100%', position: 'relative', color: '#FFFFFF', fontWeight: 'bold' }}>
                    <h1 style={{ textAlign: 'center', color: '#FFF', fontSize: '25px', fontWeight: '500' }}> Egresos </h1>
                    <Row>
                        <Col xs={24}>
                            <Row>
                                <Col span={24}  >
                                    <h2 style={{ textAlign: 'center', color: '#FFF', fontSize: '16px', fontWeight: 'normal', marginBottom: '15px' }}>
                                        2020
                                    </h2>
                                    <h1 style={{ textAlign: 'center', color: '#FFF', fontSize: '25px', fontWeight: 'bolder' }}>
                                        $ {(new Intl.NumberFormat().format(total))} MXN
                                    </h1>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Spin>

        );
    }
}



/**
 * @Class GraficaSaldos
 *
 * @description Card que muestra el saldo de las diferentes cuentas en el sistema
 * */
class GraficaSaldos extends Component {


    state = {
        dataSaldos: [],
        dataRegSaldos: [],
        totalSaldo: 0,
        loading: false,
        projects: [],
        start: null,
        end: null
    }

    componentDidMount(): void {
        this.actualizarREg();
    }

    /**
     * @methodOf GraficaSaldos
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se actualizan los valores
     * */
    componentDidUpdate(prevProps, prevState, snapshot): void {

        //Verifica que las propieades sean diferentes a los valores en los estados, y si son diferentes actualiza el total.
        //No se puede ejecutar si esta cargando.
        if (
            (
                this.state.projects.length !== this.props.projects.length ||
                (this.state.start !== this.props.start) ||
                (this.state.end !== this.props.end) ||
                (this.state.area !== this.props.area) ||
                (this.state.rubro !== this.props.rubro)
            )
            && !this.state.loading
        ) {
            this.actualizarREg();
            this.state.projects = [...this.props.projects]
        }
    }

    /**
     * @methodOf GraficaSaldos
     *
     * @function actualizarREg
     * @description trae la informacion de los saldos y formatea los datos para que echarts puede laeerlo
     * */
    actualizarREg() {
        this.setState({ loading: true })
        const { start, end, projects, area, rubro } = this.props;
        this.state.start = start;
        this.state.end = end;
        this.state.area = area;
        this.state.rubro = rubro;

        axios.get('/dashboard/total', {
            params: {
                start,
                end
            }
        })
            .then(({ data }) => {
                let dataSaldos = [];
                let dataRegSaldos = [];


                for (let x = 0; x < data.cuentas?.length; x++) {
                    const item = data.cuentas[x];

                    dataSaldos.push({
                        value: item.saldo,
                        name: item.nombre
                    })
                    dataRegSaldos.push({
                        name: item.nombre,
                        type: 'bar',
                        stack: '1',
                        label: {
                            show: false,
                            position: 'top',
                            formatter: '{a} \n Saldo: ${c} ',
                        },
                        data: [item.saldo],
                    });
                }

                this.setState({
                    loading: false,
                    dataSaldos,
                    dataRegSaldos,
                    totalSaldo: data.total
                })

            })
            .catch(({ response }) => console.log(response))
    }

    render() {
        const { dataSaldos, dataRegSaldos, totalSaldo, loading } = this.state;
        return (
            <Spin spinning={loading}>
                <div className="card-saldos">
                    <ReactEcharts theme="light" option={{
                        title: {
                            text: 'Saldos',
                            textStyle: {
                                color: '#858997',
                                fontWeight: 500,
                            },
                            subtext: 'Total $' + (new Intl.NumberFormat().format(totalSaldo)),
                            subtextStyle: {
                                align: 'center',
                                fontSize: 26,
                                color: '#000',
                                fontWeight: 'bold'
                            }
                        },
                        toolbox: toolDescargar,
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow',
                            }
                        },
                        legend: {
                            data: dataSaldos,
                            left: 'left',
                            bottom: 0,
                            orient: 'vertical',
                            textStyle: {
                                align: 'right',
                                fontSize: 12,
                                fontWeight: "bold"
                            },
                            formatter: function (name) {
                                let info = ""
                                for (let index = 0; index < dataSaldos.length; index++) {
                                    const element = dataSaldos[index]
                                    if (element.name === name) {
                                        const val = new Intl.NumberFormat().format(element.value);
                                        info = name + ' $ ' + val
                                        break
                                    }
                                }
                                return info
                            }
                        },

                        grid: {
                            left: '2%',
                            right: '2%',
                            bottom: '0px',
                            top: '100px',
                            height: '25px',
                            containLabel: false,
                            itemStyle: {
                                borderRadius: 20
                            }
                        },
                        xAxis: {
                            type: 'value',
                            show: false,
                            splitLine: {
                                show: false
                            }
                        },
                        yAxis: {
                            type: 'category',
                            data: ['Saldos'],
                            show: false,
                            splitLine: {
                                show: false
                            },
                        },

                        series: dataRegSaldos,

                    }} style={{ height: 300, width: '100%' }} />
                </div>
            </Spin>
        );
    }
}


/**
 * @Class GraficaCategorias
 *
 * @description Card que muestra cuelas son los rubros con mas trasacciones en el Dashboard
 * */
class GraficaCategorias extends Component {


    state = {
        dataCategorias: [],
        loading: false,


        projects: [],
        start: null,
        end: null,
        area: null,
        rubro: null
    }

    componentDidMount(): void {
        this.getRubros();
    }

    /**
     * @methodOf GraficaCategorias
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se actualizan los valores
     * */
    componentDidUpdate(prevProps, prevState, snapshot): void {
        //Verifica que las propieades sean diferentes a los valores en los estados, y si son diferentes actualiza el total.
        //No se puede ejecutar si esta cargando.
        if (
            (
                this.state.projects.length !== this.props.projects.length ||
                (this.state.start !== this.props.start) ||
                (this.state.end !== this.props.end) ||
                (this.state.area !== this.props.area) ||
                (this.state.rubro !== this.props.rubro)
            )
            && !this.state.loading
        ) {
            this.getRubros();
            this.state.projects = [...this.props.projects]
        }
    }



    /**
     * @methodOf GraficaCategorias
     *
     * @function getRubros
     * @description trae la informacion de los rubros y formatea los datos para echarts
     * */
    getRubros = () => {
        this.setState({ loading: true })
        const { start, end, projects, area, rubro } = this.props;
        this.state.start = start;
        this.state.end = end;

        this.state.area = area;
        this.state.rubro = rubro;

        axios.get('/dashboard/rubros', {
            params: {
                start,
                end,
                projects
            }
        })
            .then(({ data }) => {

                let dataCategorias = [];
                for (const [index, item] of data.data.entries()) {
                    dataCategorias.push({
                        value: item.total,
                        name: item.rubro
                    })
                }
                console.log(dataCategorias);

                this.setState({
                    dataCategorias,
                    loading: false
                })

            })
            .catch(({ response }) => console.log(response))
    };

    render() {
        const { dataCategorias, dataShadow, loading } = this.state;
        return (
            <Spin spinning={loading}>
                <div className="card-transacciones-d">
                    <ReactEcharts option={{
                        title: {
                            text: 'Rubros con \nmás Transacciones',
                            textStyle: {
                                color: '#858997',
                                fontWeight: 500,
                                border: 'solid 1px red'
                            }
                        },
                        toolbox: toolDescargar,
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow',
                            }
                        },
                        padding: 5,
                        legend: {
                            data: dataCategorias,
                            textStyle: {
                                fontSize: 10,
                                fontWeight: "bold"
                            },
                        },
                        yAxis: {
                            data: dataCategorias,
                            show: false,
                            inverse: true,
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false
                            },
                            axisLabel: {
                                color: '#BBB',
                                inside: false,
                                position: 'absolute',
                                rotate: 90,
                                distance: '5',
                                align: 'right',
                                verticalAlign: 'middle',
                            },
                            z: 0
                        },

                        xAxis: {
                            show: false,
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                show: false,
                                color: 'red',
                                textStyle: {
                                    //color: '#CCC'
                                }
                            }
                        },

                        toolbox: toolDescargar,

                        series: [
                            { // For shadow
                                type: 'bar',
                                show: false,
                                label: { show: false },
                                itemStyle: {
                                    color: 'rgba(0,0,0,0.1)',
                                    borderColor: 'rgba(0,0,0,0.09)',
                                    barBorderRadius: 10,
                                    width: 5
                                },

                                tooltip: {
                                    show: false
                                },

                                barGap: '-100%',
                                barCategoryGap: '85%',
                                data: dataShadow,
                                animation: false,
                                barBorderRadius: 10
                            },

                            {
                                type: 'bar',
                                label: {
                                    color: '#555',
                                    formatter: '{b}  Monto: ${c} ',
                                    //rotate:270 ,
                                    show: true,
                                    position: [3, -18], // position: 'inside',
                                    top: '0',
                                    align: 'left',
                                    fontSize: 13
                                },
                                itemStyle: {
                                    barBorderRadius: 10,
                                    color: '#2662f0',
                                    margin: [15]
                                },

                                emphasis: {
                                    itemStyle: {
                                        color: '#2662f0ba'
                                    }
                                },
                                data: dataCategorias
                            }
                        ]
                    }} style={{ height: 300 }} />
                </div>
            </Spin>

        );
    }
}

/**
 * @Class GraficaReve
 *
 * @description Card que muestra la tabla con los ingresos y egresos de los proyectos por mes
 ***/
class GraficaReve extends Component {


    state = {
        colors: [],
        legends: [],
        xAxisData: [],
        series: [],
        loading: false

    }

    componentDidMount(): void {
        this.getDataRevenue()
    }

    /**
     * @methodOf GraficaReve
     *
     * @function getDataRevenue
     * @description trae la informacion de los de los proyectos, son sus ingresos y egresos del mes actual 
     * */
    getDataRevenue = () => {
        axios.get('/dashboard/graphic-line', {})
            .then(({ data }) => {

                const
                    colors = [],
                    legends = [],
                    xAxisData = [],
                    series = [];

                const projectData = data.data;
                let lat = new Date();
                let m = new Date(lat.getMonth(), lat.getFullYear(), 0)
                for (let x = 0; x < m.getDate(); x++) {
                    xAxisData.push(x)
                }

                for (const [index, item] of projectData.entries()) {
                    let data = [];
                    colors[index] = (item.color) ? item.color : ('#' + Math.floor(Math.random() * 16777215).toString(16))

                    if (Array.isArray(item.transacciones))
                        for (let x = 0; x < xAxisData.length; x++) {
                            let dia = item.transacciones.findIndex(item => item._id.toString() === x.toString())
                            if (dia === -1) {
                                data.push(0)
                            } else {
                                data.push(item.transacciones[dia].ingreso - item.transacciones[dia].egreso)
                            }
                        }

                    series[index] = {
                        name: item.nombre,
                        type: 'line',
                        stack: 'x',
                        areaStyle: {},
                        data: data
                    };
                }
                this.setState({ colors, legends, xAxisData, series })

            })
            .catch(({ response }) => console.log(response))
    };


    render() {

        const { colors, loading, legend, xAxisData, series } = this.state;

        return (
            <Spin spinning={loading}>
                <div className="mt-2">
                    <ReactEcharts option={{
                        color: colors,
                        title: {
                            // text: 'Titulo'
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#6a7985'
                                }
                            }
                        },
                        legend: {
                            data: legend
                        },
                        toolbox: toolDescargar,
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                boundaryGap: false,
                                data: xAxisData
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value'
                            }
                        ],
                        series: series
                    }} style={{
                        height: 380
                    }} />
                </div>
            </Spin>

        );
    }
}

/**
 * @Class GraficaMovimientosPie
 *
 * @description Card que muestra una grafica circular con el numero de movimientos realizados por cada cuenta regsitrada en el sistema
 * recibe los datos desde props
 ***/
class GraficaMovimientosPie extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cuentas: [],
            series_format: [],
            loading: false
        }
    }

    componentDidUpdate() {
        let temp_cuentas = []
        let temp_series = []
        this.props.datos.map((value) => {
            temp_cuentas.push(value.cuenta_nombre)
            temp_series.push({
                value: value.values,
                name: value.cuenta_nombre
            })
        })

        if ((this.state.cuentas.length === 0 && this.props.datos.length > 0) || JSON.stringify(temp_series) !== JSON.stringify(this.state.series_format)) {
            this.setState({
                cuentas: temp_cuentas,
                series_format: temp_series,
                loading: false
            })
        }
    }


    render() {
        let { series_format, cuentas, loading } = this.state

        return (
            <Spin spinning={loading}>
                <div style={{ paddingTop: '30px' }}>
                    <ReactEcharts theme="light" style={{ height: 350, marginTop: '10px' }} option={{
                        tooltip: {
                            trigger: 'item',
                            formatter: '{a} <br/>{b}: {c} ({d}%)'
                        },
                        legend: {
                            orient: 'vertical',
                            left: 10,
                            data: cuentas
                        },
                        series: [
                            {
                                name: 'Movimientos por cuenta',
                                type: 'pie',
                                radius: ['40%', '60%'],
                                avoidLabelOverlap: true,
                                label: {
                                    show: false,
                                    position: 'center'
                                },
                                emphasis: {
                                    label: {
                                        show: true,
                                        fontSize: '20',
                                        fontWeight: 'bold'
                                    }
                                },
                                labelLine: {
                                    show: false
                                },
                                data: series_format
                            }
                        ]
                    }} />
                </div>
            </Spin>
        );
    }
}

/**
 * @Class GraficaVentasPorUsuario
 *
 * @description Card que  muestra una grafica circular con el numero de movimientos realizados por usuario
 * recibe los datos por medio de props
 ***/
class GraficaVentasPorUsuario extends Component {
    constructor(props) {
        super(props)

    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {

    }

    option = () => ({
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            orient: 'horizontal',
            left: 5,
            top:20,
            data: this.props.VentasUsuario.map(d => {
                return d.nombre
            })
        },
        grid: {
            top: 20,
            height: 40
        },

        series: [
            {
                name: 'Val',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '30',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: this.props.VentasUsuario.map(item => {
                    return {
                        name: item.nombre,
                        value: item.clientes
                    }
                })
            }
        ]

    });

    render() {
        return (
            <ReactEcharts option={this.option()} style={{ height: 350, paddingTop:"25px"}} />
        );
    }
}

/**
 * @Class GraficaClientesPorProyecto
 *
 * @description Card que muestra la tabla con el numero de clientes por proyecto
 * recibe los datos por props
 ***/
class GraficaClientesPorProyecto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datos: [],
        }
    }
    datos = []
    componentDidUpdate(prevProps) {
        if (this.props.clientesProjectos !== prevProps.clientesProjectos) {
            let arreglo_datos = []

            this.props.clientesProjectos.forEach(element => {
                arreglo_datos.push([element.proyecto.nombre, element.clientes]);
            })

            this.setState({ datos: arreglo_datos }, () => {
            })

        }

    }

    option = () => ({
        legend: {},
        tooltip: {},
        dataset: {
            source: this.state.datos
        },

        xAxis: { type: 'category' },
        yAxis: {},

        series: [
            { type: 'bar' },
        ]

    });

    render() {
        return (
            <div style={{ paddingTop: '30px' }}>
                <ReactEcharts
                    option={this.option()}
                    style={{ height: 450, marginTop: '10px' }}
                    theme="macarons"
                />
            </div>
        );
    }
}




/**
 * @Class GraficaProveedores 
 *
 * @description Card que muestra la tabla con los principales prveedores
 ***/
 class GraficaProveedores extends Component {

        state = {
            dataProveedores: [],
            loading: false,
    
    
            projects: [],
            start: null,
            end: null,
            area: null,
            rubro: null
        }
    
        componentDidMount(): void {
            this.setState({
                dataProveedores: 
                    [
                        {
                            "value": 10,
                            "name": "Prov 01",
                            "prs": 1
                        },
                        {
                            "value": 100,
                            "name": "Prov 02",
                            "prs": 10
                        },
                        {
                            "value": 1000,
                            "name": "Prov 03",
                            "prs": 100
                        }
                ]
            })
          
        }
    
        /**
         * @methodOf GraficaProveedores
         *
         * @function componentDidUpdate
         * @description Se ejecuta cuando se actualizan los valores
         * */
        componentDidUpdate(prevProps, prevState, snapshot): void {
            //Verifica que las propieades sean diferentes a los valores en los estados, y si son diferentes actualiza el total.
            //No se puede ejecutar si esta cargando.
            if (
                (
                    this.state.projects.length !== this.props.projects.length ||
                    (this.state.start !== this.props.start) ||
                    (this.state.end !== this.props.end) ||
                    (this.state.area !== this.props.area) ||
                    (this.state.rubro !== this.props.rubro)
                )
                && !this.state.loading
            ) {
              
                this.state.projects = [...this.props.projects]
            }
        }
    
        
        render() {
            const { dataProveedores, dataShadow, loading } = this.state;
            return (
                <Spin spinning={loading}>
                    <div className="card-transacciones-d">
                        <ReactEcharts option={{
                            title: {
                                text: 'Proveedores Principales',
                                textStyle: {
                                    color: '#858997',
                                    fontWeight: 500,
                                    border: 'solid 1px red'
                                }
                            },
                            toolbox: toolDescargar,
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow',
                                }
                            },
                            padding: 5,
                            legend: {
                                data: dataProveedores,
                                textStyle: {
                                    fontSize: 10,
                                    fontWeight: "bold"
                                },
                            },
                            yAxis: {
                                data: dataProveedores,
                                show: false,
                                inverse: true,
                                axisTick: {
                                    show: false
                                },
                                axisLine: {
                                    show: false
                                },
                                axisLabel: {
                                    color: '#BBB',
                                    inside: false,
                                    position: 'absolute',
                                    rotate: 90,
                                    distance: '5',
                                    align: 'right',
                                    verticalAlign: 'middle',
                                },
                                z: 0
                            },
    
                            xAxis: {
                                show: false,
                                axisLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLabel: {
                                    show: false,
                                    color: 'red',
                                    textStyle: {
                                        //color: '#CCC'
                                    }
                                }
                            },
    
                            toolbox: toolDescargar,
    
                            series: [
                                { // For shadow
                                    type: 'bar',
                                    show: false,
                                    label: { show: false },
                                    itemStyle: {
                                        color: 'rgba(0,0,0,0.1)',
                                        borderColor: 'rgba(0,0,0,0.09)',
                                        barBorderRadius: 10,
                                        width: 5
                                    },
    
                                    tooltip: {
                                        show: false
                                    },
    
                                    barGap: '-100%',
                                    barCategoryGap: '85%',
                                    data: dataShadow,
                                    animation: false,
                                    barBorderRadius: 10
                                },
    
                                {
                                    type: 'bar',
                                    label: {
                                        color: '#555',
                                        formatter: function(value) {
                                            console.log(value)
                                            return " "+value.data.name + "    Linea de Credito $" + value.data.value + "\n " + value.data.prs +"%"
                                        },
                                        // '{b} Linea de credito ${c}',
                                        //rotate:270 ,
                                        show: true,
                                        position: [0, -27], // position: 'inside',
                                        top: '-15',
                                        align: 'left',
                                        fontSize: 13,
                                        fontWeight:"500"
                                    },

                                    itemStyle: {
                                        barBorderRadius: 10,
                                        color: '#2662f0',
                                        margin: [15]
                                    },
                                    emphasis: {
                                        itemStyle: {
                                            color: '#2662f0ba'
                                        }

                                    },
                                    data: dataProveedores
                                }
                            ]
                        }} style={{ height: 300 }} />
                    </div>
                </Spin>
    
            );
        }
    }




export {
    GraficaIngresos,
    GraficaEgresos,
    GraficaSaldos,
    GraficaCategorias,
    GraficaReve,
    GraficaMovimientosPie,
    GraficaVentasPorUsuario,
    GraficaClientesPorProyecto,

    GraficaProveedores
}
