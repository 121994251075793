import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select 
const axios = require("axios");

/**
 * @const SelectProducto
 * @description Select para los productos registrados en el sistema
 */
const SelectProducto = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el producto", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {} 
    } = props

    const [ productos, setProductos ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getProductos
     * @description Obitiene los productos
     */
    const getProductos = ({page, limit, search} = productos) => {

        axios.get('/productos', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setProductos(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message + " (Productos)"?? 'Error al obtener los productos')
        })
    }

    //componentDidMount
    useEffect(() => {
        getProductos()
    }, [params?.almacen_id])

    useEffect(() => {
        if(value)
            onChange(value?.value ?? value)
    }, [value])

    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getProductos({search})}
            onSelect={(producto, ...args)=> {
                onChange(producto.value)
                onSelect(producto.value, args)
                
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {
                productos?.data?.map(({ _id, nombre, ...args }) => <Option value={_id} producto={{...args, _id, nombre}}>{nombre ?? ""} {}</Option>)
            }
        </Select>
    );
}



export default SelectProducto;