import React, { Component } from "react";
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Layout, Space, message, List, Card, Tooltip } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined, BuildOutlined, BulbOutlined, RightSquareFilled } from "@ant-design/icons";
import Logged from '../../../Hooks/Logged'
import usePermissions from "../../../Hooks/usePermissions";

//componentes
import Avatar from "../../Widgets/Avatar/Avatar";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

//modales
import ModalProyectos from "./ModalProyectos";

const { Content } = Layout;
const { Text, Title } = Typography;

/**
 *
 *
 * @export
 * @class Proyectos
 * @extends {Component}
 * @description Vista donde se listan todos los proyectos
 */
class Proyectos extends Component {

    /**
     *Creates an instance of proyectos.
     * @param {*} props
     * @memberof Proyectos
     */
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible_modal: false,
            proyectos: {
                data: [],
                limit: 24,
                page: 1,

                total: 0,
                pages: 0,
            },
            proyecto_id: null,
        };
    }

    componentDidMount() {
        this.getProyectos();
    }

    /**
     *
     * @memberof Proyectos
     * @method getProyectos
     * @description Obtiene el listado de proyectos.
     */
    getProyectos = async (
        {
            page = this.state.proyectos.page,
            limit = this.state.proyectos.limit,
            search = this.props.search,
        } = this.state.proyectos
    ) => {
        this.setState({
            loading: true,
            proyectos: { ...this.state.proyectos, page, limit },
        })

        axios.get("/proyectos", { params: { limit, page, search } })
            .then(({ data }) => {
                this.setState({
                    proyectos: { ...this.state.proyectos, ...data },
                })
            })
            .catch((error) => {
                message.error("Error al obtener los proyectos")
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     *
     * @memberof Proyectos
     * @method deleteProyecto
     * @description Elimina un proyecto por su id y vuelve a obtener el listado.
     */
    deleteProyecto = (id) => {
        axios.delete("/proyectos/", { params: { id } })
            .then((response) => {
                message.success('Proyecto eliminado')
            })
            .catch((error) => {
                message.error(error?.response?.data ?? "No fue posible eliminar")
            })
            .finally(() => {
                this.getProyectos();
            })
    }

    renderUrl = (item) => {
        const urlParams = new URL(axios.defaults.baseURL + "/proyectos/logo/" + item._id)
        urlParams.searchParams.set("Authorization", sessionStorage.getItem("token"))
        urlParams.searchParams.set("name", item.logo?.name)
        return urlParams.href
    }

    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={"Proyectos"}
                        extra={
                            <span className="page-header-extra">
                                {" "}
                                {this.state.proyectos.total} proyectos
                            </span>
                        }
                    ></PageHeader>
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            grid={{ gutter: 16, column: 3 }}
                            className="component-list "
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin proyectos" }}
                            dataSource={this.state.proyectos.data}
                            pagination={{
                                current: this.state.proyectos.page,
                                pageSize: this.state.proyectos.limit,
                                total: this.state.proyectos.total,
                                hideOnSinglePage: false,
                                position: "bottom",
                                showSizeChanger: true,
                                pageSizeOptions: [12,24,64,128,512],
                                onChange: (page, limit) => this.getProyectos({ page, limit }),
                            }}
                            renderItem={(item) => (
                                <List.Item className="component-list-item" key={item._id}>
                                    <Card className="card-list">
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24}>
                                                <Row wrap={false} gutter={[16, 16]}>
                                                    <Col flex="none">
                                                        <Avatar
                                                            size={50}
                                                            color={item.color}
                                                            {...(item.logo ? {
                                                                image: this.renderUrl(item)
                                                            } : {
                                                                name: item.nombre
                                                            })}
                                                            // image={}
                                                        />
                                                    </Col>
                                                    <Col flex="auto"
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Title level={3} style={{ marginBottom: 0 }} ellipsis>{item.nombre}</Title>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={24} style={{ height: 40 }}>
                                                <Text ellipsis={{ rows: 2, expandable: false }}>
                                                    {item.descripcion}
                                                </Text>
                                            </Col>
                                            <Col xs={24}>
                                                <Space size={8} direction="horizontal" className="center">
                                                    <Tooltip title="Conceptos">
                                                        <Link to={`/admin/proyectos/conceptos/` + item._id}>
                                                            <Button
                                                                disabled={!this.props.accessConceptops}
                                                                className="ant-btn-primary-yellow"
                                                                type="primary"
                                                                icon={<BulbOutlined style={{ color: "currentcolor" }} />}
                                                            />
                                                        </Link>
                                                    </Tooltip>

                                                    <Tooltip title="Propiedades e Inmuebles">
                                                        <Link to={`/admin/proyectos/propiedades/` + item._id}>
                                                            <Button
                                                                disabled={!this.props.accessPropiedades}
                                                                className="ant-btn-primary-green"
                                                                type="primary"
                                                                icon={<BuildOutlined style={{ color: "currentcolor" }} />}
                                                            />
                                                        </Link>
                                                    </Tooltip>

                                                    <Tooltip title="Editar Proyecto">
                                                        <Button
                                                            type="primary"
                                                            disabled={!this.props.editProyectos}
                                                            icon={<EditOutlined style={{ color: "currentcolor" }} />}
                                                            onClick={() => this.setState({
                                                                visible_modal: true,
                                                                proyecto_id: item._id,
                                                            })}
                                                        />
                                                    </Tooltip>


                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este proyecto?"
                                                        disabled={!this.props.deleteProyectos}
                                                        onConfirm={() => this.deleteProyecto(item._id)}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            danger
                                                            disabled={!this.props.deleteProyectos}
                                                            title="Eliminar"
                                                            icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    {this.props.createProyectos ? <FloatingButton
                        title="Nuevo registro"
                        onClick={() => this.setState({ visible_modal: true })}
                    /> : null}

                    <ModalProyectos
                        visible={this.state.visible_modal}
                        proyecto_id={this.state.proyecto_id}
                        onClose={() => {
                            this.getProyectos();
                            this.setState({
                                visible_modal: false,
                                proyecto_id: undefined,
                            });
                        }}
                    />
                </Spin>
            </>
        );
    }
}

export default function (props) {


    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {
        createProyectos: ["proyectos", "create"],
        editProyectos: ["proyectos", "edit"],
        readProyectos: ["proyectos", "read"],
        deleteProyectos: ["proyectos", "delete"],
        accessPropiedades: ["proyectos", "propiedades", "access"],
        accessConceptops: ["proyectos", "conceptos", "access"]
    })

    console.log(permissions)

    return <Proyectos {...props} {...permissions} />
}