import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography } from 'antd'

import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

import { IconDelete, IconEdit, IconDetails } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalAreas from './ModalAreas'

import Logged from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class Areas
 * @extends {Component}
 * @description Vista de Areas
 */
export class Areas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,

            area_id: undefined,
            areas: {
                data: [],
                limit: 20,
                page: 1,

                total: 0,
                pages: 0,
            },
            searching: true,
            filtroSearch: '',

        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        // this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getAreas()
    }


    /**
     *
     *
     * @memberof Areas
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;
        if (filtro !== propFiltro && this.state.searching == true) {
            this.getAreas();
            this.state.searching = false;
        }
    }

    /**
     * @methodOf  Areas
     * @method getAreas
     *
     * @description Obtiene las areas
     * */
    getAreas = ({
        page = this.state.areas.page,
        limit = this.state.areas.limit,
        search = this.props.search

    } = this.state.areas) => {
        this.setState({ loading: true, areas: { ...this.state.areas, page, limit } })
        axios.get('/areas', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            this.setState({
                areas: data,
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }


    render() {

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Áreas"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.areas.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Áreas " }}
                            dataSource={this.state.areas.data}
                            pagination={{
                                current: this.state.areas.page,
                                pageSize: this.state.areas.limit,
                                total: this.state.areas.total,
                                position: 'bottom',
                                className: "flex-left",
                                showSizeChanger: true,
                                onChange: (page, limit) => this.getAreas({ page, limit })
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" gutter={[16, 16]}>

                                <Col xs={24} lg={10} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col xs={24} lg={10} className="center">
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col xs={24} lg={4} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 " gutter={[16, 16]}>

                                            <Col xs={24} lg={10} className="center">
                                                <Text>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={24} lg={10} className="center">
                                                <Text>{item.descripcion}</Text>
                                            </Col>
                                            <Col xs={24} lg={4} className="center">
                                                <Space size="small" direction="horizontal">
                                                    <Link title="Editar" to={`/admin/areas/rubros/${item._id}`}>
                                                        <Button type="secondary" name={item.nombre} id={item._id}
                                                            title="Ver rubros" icon={<IconDetails />}>
                                                        </Button>
                                                    </Link>


                                                    <Button
                                                        type="primary"
                                                        disabled = {!this.props.editAreas}
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modal_visible: true, area_id: item._id }) }}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        disabled = {!this.props.deleteAreas}
                                                        title="¿Deseas eliminar esta área?"
                                                        onConfirm={() => axios.delete('/areas',
                                                            { params: { id: item._id } }).then(() => {
                                                                this.getAreas()
                                                                message.success('Área eliminada')
                                                            }).catch(error => {
                                                                message.error('Error al eliminar el Área')
                                                                console.log(error)
                                                            })}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            disabled = {!this.props.deleteAreas}
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>

                    {this.props.createAreas ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} /> : null}


                    <ModalAreas
                        visible={this.state.modal_visible}
                        onClose={() => {
                            this.setState({ modal_visible: false, area_id: undefined })
                            this.getAreas()
                        }}
                        area_id={this.state.area_id}
                    />
                </Spin>
            </>
        )
    }
}

export default function (props) {

    let user = React.useContext(Logged)

    
	let permissions = usePermissions(user?.rol_id?.permisos, {
		editAreas: ["areas-rubros", "edit"],
		createAreas: ["areas-rubros", "create"],
		deleteAreas: ["areas-rubros", "delete"],

	})

    return <Areas {...props} {...permissions} navigate={useNavigate()} />

}