import React, { useState, useEffect } from 'react';
import { Input, Form, Space, Select, message, InputNumber, Row } from 'antd'

import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import SelectAlmacen from './SelectAlmacen';

import '../../../Styles/Modules/Widgets/almacen-ingreso.scss'

const { Option } = Select
const axios = require('axios')


/**
 * @const AlmacenIngresoInputs
 * @description Input compuesto para seleccionar un almacen y el monto a ingresar en ese monto
 */
const AlmacenIngresoInputs = (props) => {

    const { value, onChange, key, name, restField, remove, max, total, almacenes = {} } = props
    
    return (
        <div className="almacen-ingreso">
            <Form.Item
                {...restField}
                className="almacen-ingreso-almacenes"
                name={[name, 'almacen_id']}
                rules={[
                    {
                        required: true,
                        message: 'Seleccione el proyecto',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (almacenes[value] && almacenes[value] > 1) {
                            return Promise.reject(new Error("Seleccionaste este almacen más de 1 vez"));
                          }
                          return Promise.resolve();
                        },
                    })
                ]}
            >
                <SelectAlmacen proyecto_id={props.proyecto_id}/>
            </Form.Item>

            <Form.Item
                {...restField}
                className="almacen-ingreso-cantidad"
                name={[name, 'cantidad']}
                rules={[
                    {
                        required: true,
                        message: 'Ingrese el monto',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (total !== undefined && max !== undefined && total > max) {
                            return Promise.reject(new Error("Excede el volumen requerido"));
                          }
                          return Promise.resolve();
                        },
                    })
                ]}
            >
                <InputNumber min={1} className="w-100" defaultValue={0} precision={2}  placeholder="Cantidad"/>
            </Form.Item>
            <MinusCircleOutlined className="almacen-ingreso-button" onClick={() => remove(name)} />
        </div>
    );
}



export default AlmacenIngresoInputs;