import React, { Component } from "react";
import { Modal, Form, Input, message, Spin, InputNumber, DatePicker, Select } from 'antd';
//componentes

import { SelectAlmacen } from "../../Widgets/Inputs/Selects";

const axios = require('axios').default;

const { Option } = Select;

/**
 *
 *
 * @class ModalCotizacion
 * @extends {Component}
 */
class ModalCotizacion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proyecto_id: null
        }
    }

    ModalCotizacion = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    onFinish = (values) => {
        this.setState({loading: true})
        this.aprobarCotizacion(values)
       
    }


    /**
     *
     * @methodOf ModalCotizacion
     * @function aprobarCotizacion
     * @description Añade una nueva requisicion
     */
    aprobarCotizacion = (values) => {
        axios.post("/requisicion-opcion/cotizacion",{
            ...values,
            requisicion_opcion_id: this.props.requisicion_opcion_id
        }).then((response) => {
            message.success("Cotización aprobada")
            this.props.onClose(true)
        }).catch((error)=>{
            message.error(error?.response?.data?.message ?? "No se pudo aprobar la cotización")
        }).finally(()=>this.setState({loading: false}))
    }


    render() {
        return (
            <Form
                layout="vertical"
                name="form-requisiciones"
                ref={this.ModalCotizacion}
                onFinish={this.onFinish}
                className="pd-1"
                initialValues={{
                    cantidad: 1,
                    prioridad: 0
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        label="Fecha Entrega"
                        name="fecha_entrega"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese la feha de entrega"
                        }]}
                    >
                        <DatePicker className="width-100"/>
                    </Form.Item>

                    <Form.Item
                        label="Tipo de Pago"
                        name="tipo_pago"
                        rules={[{
                            required: true,
                            message: "Por favor, seleccione el tipo de pago"
                        }]}
                    >
                        <Select onSelect={(tipo) => {
                            this.ModalCotizacion?.current?.setFieldsValue({
                                estatus: 5
                            })
                            this.setState({tipo})
                        }}>
                            <Option value={1}>Contado</Option>
                            <Option value={2}>Crédito</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Estatus"
                        name="estatus"
                        rules={[{
                            required: true,
                            message: "Por favor, seleccione el estatus"
                        }]}
                    >
                        <Select disabled={this.state.tipo === 1}>
                            <Option value={4}>Entrega</Option>
                            <Option value={5}>Recolección</Option>
                        </Select>
                    </Form.Item>

                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, area_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={"Aceptar Cotización"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-requisiciones', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCotizacion {...props} />
    </Modal>

}