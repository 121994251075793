import React, { Component } from 'react';
import { Button, Col, Row, message, Typography, List, Card, Popconfirm, Pagination, PageHeader, Space } from 'antd'
import { Link } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';

//componentes
import Logged from '../../../Hooks/Logged'
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import usePermissions from '../../../Hooks/usePermissions';
import FloatingButton from '../../Widgets/Floating Button/FloatingButton';
import { IconEdit, IconDelete, IconPlus, IconDetails } from '../../Widgets/Iconos';

//Modales
import ModalNuevaInversion from './Modales/ModalNuevaInversion'

const axios = require('axios').default;
const { Text } = Typography;

class Inversiones extends Component {

    constructor(props) {
        super(props)
        this.state = {

            inversiones: {

                data: [],

                page: 1,
                limit: 100,

                total: 0,
                pages: 0,

                loading: false,
            },

            modalInversion: false,
            modalTitle: 'Nueva',
            inversion_id: undefined,
        }
    }

    //Referencia


    /**
    * @memberof Inversiones
    * @method   componentDidMount
    * @description 
    * */
    componentDidMount() {
        this.getInversiones()
    }

    /**
     * @memberof Inversiones
     * @method componentDidUpdate
     * @description actualiza la vista si se ha realizado una busqueda
     */
    componentDidUpdate(prevProps) {
        if(prevProps.search !== this.props.search){
            this.getInversiones()
        }
    }


    getInversiones = ({
        page = this.state.inversiones.page,
        limit = this.state.inversiones.limit,
        search = this.props.search
    } = this.state.inversiones) => {

        this.setState({ inversiones: { ...this.state.inversiones, page, limit }, loading: true })

        axios.get('/inversiones/list', {
            params: {
                page, limit, search
            }
        }).then(response => {
                    console.log("response.data", response.data);
            this.setState({
                inversiones: {
                    ...this.state.inversiones,
                    ...response.data
                }
            })
        }).catch(error => {
            console.log(error)
        }).finally(()=>this.setState({loading: false}))
    }

    deleteInversion = (_id) => {
        axios.post('/inversiones/delete', {
            _id
        }).then(response => {
            message.success('Inversion Eliminada')
            this.getInversiones();
        }).catch(error => {
            console.log(error)
            message.success('Error')
        })
    }






    render() {
        return (
            <div>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title="Inversiones"
                />

                <Content className="admin-content content-bg pd-1">
                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        dataSource={this.state.inversiones.data}
                        header={<Row className="header-list" style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }}>
                            <Col span={1} className="gutter-row center">
                                <Text strong>Proyecto</Text>
                            </Col>
                            <Col span={5} className="gutter-row center">
                                <Text strong>Nombre</Text>
                            </Col>
                            <Col xs={5} className="gutter-row center">
                                <Text strong>MONTO TOTAL</Text>
                            </Col>
                            <Col xs={5} className="gutter-row  center">
                                <Text strong>MONTO PAGADO</Text>
                            </Col>
                            <Col xs={4} className="gutter-row  center">
                                <Text strong>MONTO POR PAGAR</Text>
                            </Col>
                            <Col xs={4} className="gutter-row center">
                                <Text strong>ACCIONES</Text>
                            </Col>
                        </Row>}
                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row style={{ width: '100%' }} className="">
                                        <Col span={1} className="center">
                                            <Text strong>
                                                {/* logo:null
                                                nombre:"Softima"
                                                descripcion:"Softima SA de CV"
                                                color:"#73d8ff" */}
                                                <CustomAvatar
                                                    url={{
                                                        url:`${axios.defaults.baseURL}/proyectos/logo/${item.proyecto?._id}`,
                                                        name: item.proyecto?.logo?.name
                                                    }}
                                                    name={item.proyecto?.nombre}
                                                    color={item.proyecto?.color}
                                                />
                                            </Text>
                                        </Col>

                                        <Col span={5} className="center">
                                            <Text strong>{item.nombre}</Text>
                                        </Col>

                                        <Col xs={5} className="center">
                                            <Text strong>$ {item.monto_venta?.toMoney(true)} MXN</Text>
                                        </Col>

                                        <Col xs={5} className="center">
                                            <Text strong>$ {item.monto_pagado?.toMoney(true)} MXN</Text>
                                        </Col>
                                        <Col xs={4} className="center">
                                            <Text strong>$ {item.monto_restante?.toMoney(true)} MXN</Text>
                                        </Col>

                                        <Col xs={4} className="center">
                                            <Space>
                                                <Link title="Ver" to={`/admin/financial-health/inversion/detalle/${item._id}`} >
                                                    
                                                    <Button type="secondary" name={item.nombre} id={item._id}
                                                        title="Ver detalles" icon={<IconDetails />}>
                                                    </Button>
                                                    
                                                </Link>
                                                <Button
                                                    type="primary"
                                                    disabled={!this.props.editInversiones}
                                                    icon={<IconEdit />}
                                                    title="Editar"
                                                    onClick={() => { this.setState({ inversion_id: item._id, modalInversion: true, modalTitle: 'Editar' }) }}
                                                />
                                                <Popconfirm
                                                    placement="topRight"
                                                    disabled={!this.props.deleteInversiones}
                                                    title="¿Deseas eliminar esta inversion?"
                                                    onConfirm={() => this.deleteInversion(item._id)}
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        type="primary"
                                                        disabled={!this.props.deleteInversiones}
                                                        icon={<IconDelete />}
                                                        title="Eliminar"
                                                        danger
                                                    />
                                                </Popconfirm>
                                                
                                            </Space>

                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                        pagination={{
                            current:this.state.inversiones.page,
                            pageSize:this.state.inversiones.limit,
                            total: this.state.inversiones.total,
                            showSizeChanger: true,
                            onChange: (page, limit) => {
                                this.getInversiones({ page, limit })
                            }
                        }}
                    />
                </Content>

                <FloatingButton className="btnAddPlus" title="Nuevo registro" onClick={() => this.setState({ modalInversion: true, modalTitle: 'Nueva', inversion_id: undefined })}>
                    <IconPlus />
                </FloatingButton>

                <ModalNuevaInversion
                    visible={this.state.modalInversion}
                    onClose={() => {
                        this.setState({ modalInversion: false, inversion_id: undefined })
                        this.getInversiones()
                    }}
                    modalTitle={this.state.modalTitle}
                    inversion_id={this.state.inversion_id}
                />
            </div>
        )
    }
}

export default function (props) {
    let user = React.useContext(Logged);

    

    let permissions = usePermissions(user?.rol_id?.permisos, {
        
        createInversiones: ["inversiones", "create"],
		editInversiones: ["inversiones", "edit"],
		readInversiones: ["inversiones", "read"],
		deleteInversiones: ["inversiones", "delete"],

    })

    return <Inversiones {...props} {...permissions}/>
}