import React, { Component } from 'react';
import { Row, Col, Card, Statistic, Typography, PageHeader, Space, Empty, Tabs, Tooltip, message, Tag, Switch, Carousel, Image, Breadcrumb } from 'antd'
import { Link, useParams } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { FloatingMenu, MainButton, ChildButton } from 'react-floating-button-menu';
import { ClockCircleOutlined, CloseOutlined, DollarCircleFilled, PlusOutlined } from '@ant-design/icons';

//componentes
import Logged from '../../../Hooks/Logged';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import { IconArrowBack, IconFlag, IconNewTarget } from '../../Widgets/Iconos';

//modales
import usePermissions from '../../../Hooks/usePermissions';
import moment from 'moment';

const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

const { Text, Paragraph, Title } = Typography


class RequisicionDetalle extends Component {
    volver = () => {
        window.history.back()
    }

    constructor(props) {
        super(props)
        this.state = {
            requisicion_id: this.props.params.requisicion_id,
            requisicion: {}
        }
    }


    componentDidMount() {
        //Mostrar input superior de busqueda
        //Para acceder al valor del input = this.props.search
        this.get()
    }

    get = () => {
        this.setState({loading: true})
        console.log("PROPS", this.props.params)
        axios.get(`/requisiciones/${this.props.params.requisicion_id}`,{
            params:{
                id: this.props.params.requisicion_id
            }
        }).then(({data}) => {

            this.setState({
                requisicion: data
            })

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener la requisición")

        }).finally(()=>this.setState({loading: false}))
    }

    render() {
        let colors_flag = {
            0: '#5151ec',
            1: '#ecea51',
            2: '#ec5151',
        }        

        const { requisicion } = this.state
        const { requisicion_opcion_id: opciones } = requisicion
        return (
            <div>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={
                        <Breadcrumb className="breadcrumb-header">
                                <Breadcrumb.Item>
                                    <Link to="/admin/requisiciones">Requisiciones</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {requisicion?.folio}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                    }
                    backIcon={<IconArrowBack />}
                    onBack={this.volver}
                />
                <Content className="admin-content content-bg pd-1">
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Card bordered loading={this.state.loading}>
                                <Row gutter={[24, 24]}>
                                <Col xs={24} style={{marginBottom: 10}}>
                                    <Space align="center" size={12}>
                                        <Title level={4} style={{margin: 0}}>Información de la Requisición</Title>
                                        <IconFlag fill={colors_flag[requisicion.prioridad] ? colors_flag[requisicion.prioridad] : '#A4A4A4'} />
                                    </Space>
                                </Col>
                                <Col xs={24}>
                                    <Row gutter={[16, 24]}>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Folio</Paragraph>
                                            <Text style={{ fontSize: 16 }}>{requisicion?.folio}</Text>
                                        </Col>
                                        {
                                            requisicion?.categoria_id ? (
                                                <Col xs={24} lg={12} xl={6}>
                                                    <Paragraph>Categoria</Paragraph>
                                                    <Text style={{ fontSize: 16 }}>{requisicion?.categoria_id?.nombre}</Text>
                                                </Col>
                                            ) : null
                                        }
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Volumen</Paragraph>
                                            <Text style={{ fontSize: 16 }}>{requisicion?.volumen} {requisicion?.unidad_id?.nombre}</Text>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Proyecto</Paragraph>
                                            <Space size={5}>
                                                <CustomAvatar
                                                    name={requisicion.proyecto_id?.nombre}
                                                    color={requisicion.proyecto_id?.color}
                                                    url={requisicion.proyecto_id?._id ? {
                                                        url:`${axios.defaults.baseURL}/proyectos/logo/${requisicion.proyecto_id?._id}`,
                                                        name: requisicion.proyecto_id?.logo?.name
                                                    } : null}
                                                />
                                                <Text style={{ fontSize: 16 }}>{requisicion.proyecto_id?.nombre}</Text>
                                            </Space>
                                        </Col>
                                        {
                                            requisicion?.inmueble_id ? (
                                                <Col xs={24} lg={12} xl={6}>
                                                    <Paragraph>Inmueble</Paragraph>
                                                    {requisicion.inmuebles?.length > 0 ? <Tag color="green" style={{ marginRight: '4px', marginLeft: '0px' }}>{requisicion.inmuebles[0].nombre}</Tag> : ''}
                                                    {requisicion.inmuebles?.length > 1 ? <Tag color="green" style={{ marginRight: '4px', marginLeft: '0px' }}>...</Tag> : ''}
                                                </Col> 
                                            ) : null
                                        }
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Fecha de Creación</Paragraph>
                                            <Text style={{ fontSize: 16 }}>{moment(requisicion.createdAt).format('YYYY-MM-DD')}</Text>
                                        </Col>
                                    </Row>
                                </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card bordered loading={this.state.loading}>
                                <Row gutter={[24, 24]}>
                                <Col xs={24} style={{marginBottom: 10}}>
                                    <Title level={4} style={{margin: 0}}>Opciones de la Requesición</Title>
                                </Col>
                                <Col xs={24}>
                                    <Row gutter={[16, 24]}>
                                       <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Estatus</Paragraph>
                                            <Space align='center'>
                                                <Switch
                                                    checked={opciones?.aceptada}
                                                    disabled={true}
                                                />
                                                <Text style={{ fontSize: 16 }}>{opciones?.aceptada ? "Aceptada" : "No aceptada"}</Text>
                                            </Space>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Proveedor</Paragraph>
                                            <Space size={5}>
                                                <CustomAvatar
                                                    name={opciones?.proveedor_id?.razon_social || opciones?.proveedor_id?.alias}
                                                    url={requisicion.proyecto_id?._id ? {
                                                        url:`${axios.defaults.baseURL}/proyectos/logo/${requisicion.proyecto_id?._id}`,
                                                        name: requisicion.proyecto_id?.logo?.name
                                                    } : null}
                                                />
                                                <Text style={{ fontSize: 16 }}>{opciones?.proveedor_id?.razon_social || opciones?.proveedor_id?.alias}</Text>
                                            </Space>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Días para entregar</Paragraph>
                                            <Text style={{ fontSize: 16 }}>{opciones?.dias_entrega}</Text>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Costo de Envio</Paragraph>
                                            <Statistic
                                                valueStyle={{ fontSize: 16 }}
                                                value={opciones?.costo_envio}
                                                precision={2}
                                                prefix={'$'} suffix={"MXN"} />
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Precio Unitario</Paragraph>
                                            <Statistic
                                                valueStyle={{ fontSize: 16 }}
                                                value={opciones?.precio_unitario}
                                                precision={2}
                                                prefix={'$'} suffix={"MXN"} />
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Monto Total</Paragraph>
                                            <Statistic
                                                valueStyle={{ fontSize: 16 }}
                                                value={opciones?.monto_total}
                                                precision={2}
                                                prefix={'$'} suffix={"MXN"} />
                                        </Col>
                                    </Row>
                                </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card bordered loading={this.state.loading}>
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} >
                                        <Title level={4} >Información del Producto</Title>
                                    </Col>
                                    <Col xs={24} md={12} lg={6}>
                                        <Image
                                            placeholder
                                            width={'100%'}
                                            height={300}
                                            //style={{objectFit: 'cover'}}
                                            src={requisicion?.producto_id ? axios.defaults.baseURL + "/productos/images/" + requisicion?.producto_id?._id + "?Authorization=" + sessionStorage.getItem("token") : null}
                                        />
                                    </Col>
                                    <Col xs={24} md={12} lg={18}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} lg={12} xl={6}>
                                                <Paragraph>Nombre</Paragraph>
                                                <Text style={{ fontSize: 16 }}>{requisicion?.producto_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={24} lg={12} xl={6}>
                                                <Paragraph>SKU</Paragraph>
                                                <Text style={{ fontSize: 16 }}>{requisicion?.producto_id?.sku}</Text>
                                            </Col>
                                            <Col xs={24} lg={12} xl={6}>
                                                <Paragraph>Categoría/Subcategoría</Paragraph>
                                                <Text style={{ fontSize: 16 }}>{requisicion?.producto_id?.categoria_id?.nombre || "--"}/{requisicion?.producto_id?.sub_categoria_id?.nombre || "--"}</Text>
                                            </Col>
                                            <Col xs={24} lg={12} xl={6}>
                                                <Paragraph>Fabricante/Marca</Paragraph>
                                                <Text style={{ fontSize: 16 }}>{requisicion?.producto_id?.fabricante}</Text>
                                            </Col>
                                            <Col xs={24} lg={24} xl={24}>
                                                <Paragraph>Descripción</Paragraph>
                                                <Text style={{ fontSize: 16 }}>{requisicion?.producto_id?.descripcion || "Sin descripción"}</Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                </Content>
                
            </div>
        )
    }
}
RequisicionDetalle.contextType = Logged;

export default function (props) {
    let user = React.useContext(Logged);

    const params = useParams();

    let permissions = usePermissions(user?.rol_id?.permisos, {})

    return <RequisicionDetalle {...props} {...permissions} params={params} />
}
